/* eslint-disable operator-linebreak */
export const COCKPIT_TITLE = 'Cockpit';
export const COCKPIT_SUBTITLE_FIRST = 'Experience';
export const COCKPIT_SUBTITLE_SECOND = 'Focus areas';
export const COCKPIT_SUBTITLE_THIRD = 'Reference cases';
export const COCKPIT_SUBTITLE_FORTH = 'Get in contact with one of our experts';

export const COCKPIT_PARAGRAPH_FIRST =
  'Since more than 30 years, over 50.000.000 cars are produced with Neusoft infotainment technology. Neusoft’s self-developed intelligent cockpit system, designed with the concepts of soft power, high integration and intelligent connection, enables dual operating systems on one host and raises resource utilization through the independently developed virtualization technology and application. With more than 110 patents in this area, Neusoft has served a large number of satisfied OEMs with the intelligent cockpit system for all needs from entry-level to high-end.';

export const COCKPIT_FOCUS_FIRST_TITLE = 'HW + SW eCockpit Platform';
export const COCKPIT_FOCUS_SECOND_TITLE = 'SW for Infotainment System';
export const COCKPIT_FOCUS_THIRD_TITLE = 'HW for Infotainment System';

export const COCKPIT_FOCUS_FIRST_TEXT = [
  {
    sign: '•',
    text: 'IVI & cluster platform  <br/>(chipset: Qualcomm, NXP, MTK, Intel)',
  },
  {
    sign: '•',
    text: ' Neusoft hypervisor technology',
  },
  {
    sign: '•',
    text: 'OS: Android Automotive / Linux / QNX',
  },
];
export const COCKPIT_FOCUS_SECOND_TEXT = [
  {
    sign: '•',
    text: '  Intelligent cockpit <br/> (navigation, multimedia, radio, BT & WiFi, AVM/RVC/DVR, steering wheel control, app store, IC, 4G/5G connectivity via TCU, RSE, ADAS-/V2X interface, multiscreen interaction)',
  },
  {
    sign: '•',
    text: 'Application-/SW Development & integration for infotainment systems',
  },
];
export const COCKPIT_FOCUS_THIRD_TEXT = [
  {
    sign: '•',
    text: 'Infotainment Head Unit <br/> (chipset: Qualcomm, NXP, MTK, Intel)',
  },
  {
    sign: '•',
    text: 'OS: Android Automotive / Linux',
  },
];

export const COCKPIT_CASE_TITLE_FIRST = 'eCockpit System';
export const COCKPIT_CASE_TITLE_SECOND = 'Cockpit System';
export const COCKPIT_CASE_TITLE_THIRD = 'IVI: Android Framework';
export const COCKPIT_CASE_TITLE_FOURTH = 'PoC: Health on Board Platform';

export const COCKPIT_CASE_PATH_FIRST = 'eCockpit-System';
export const COCKPIT_CASE_PATH_SECOND = 'Cockpit-System';
export const COCKPIT_CASE_PATH_THIRD = 'IVI:Android-Framework';
export const COCKPIT_CASE_PATH_FOURTH = 'PoC:Health-Board-platform';

export const COCKPIT_CASE_SUBTITLE_FIRST = 'Chinese OEM';
export const COCKPIT_CASE_SUBTITLE_SECOND = 'British OEM';
export const COCKPIT_CASE_SUBTITLE_THIRD = 'German premium OEM JV in China';
export const COCKPIT_CASE_SUBTITLE_FOURTH = 'Japanese OEM';

export const COCKPIT_CASE_TEXT_FIRST =
  'High-end solution: state of the art Chinese cockpit system based on Qualcomm 8155';
export const COCKPIT_CASE_TEXT_SECOND =
  'Mid-end solution: state of the art Chinese cockpit system based on MTK 2712';
export const COCKPIT_CASE_TEXT_THIRD =
  'Complete Android framework (Android P) on top of the hypervisor for diverse 3rd party Android applications';
export const COCKPIT_CASE_TEXT_FOURTH =
  'Health on board service to provide early screening for diseases, emergency-, fatigue warnings, health data collection and more.';

export const COCKPIT_POPUP_FIRST_CASE_TEXT = '<p>80+ Engineers located in China build a state of the art Chinese cockpit system based on Qualcomm 8155.</p><span>Supporting:</span><ul><li>4-screen output and 8-camera input</li><li>Integration of AVM, AR navigation, DMS, OMS and other high computing power algorithms</li><li>ADSP, CDSP and BT protocol stack </li><li>Powerful processor and platform scalability</li><li>Rich cloud services and integration of BAT ecology</li></ul>';
export const COCKPIT_POPUP_FIRST_CASE_FIRST_BOX_TEXT = 'China';
export const COCKPIT_POPUP_FIRST_CASE_SECOND_BOX_TEXT = '';
export const COCKPIT_POPUP_FIRST_CASE_THIRD_BOX_TEXT = '2022.Q4';
export const COCKPIT_POPUP_FIRST_CASE_FOURTH_BOX_TEXT = 'Platform: Qualcomm 8155<br/>Ability to integrate applications with high DMIPS, such as AVM, AR navigation, DMS, OMS, etc. able to power multiple screens in different sizes, dual system and deep aggregation with mainstream ecology vendors';
export const COCKPIT_POPUP_FIRST_CASE_FIFTH_BOX_TEXT = 'SW and HW development costs were significantly reduced by incorporating Qualcomm’s ADSP, CDSP and BT protocol stack';

export const COCKPIT_POPUP_SECOND_CASE_TEXT = '<p>40+ Engineers located in China build a state of the art Chinese cockpit system based on MTK and NXP SoCs.</p><ul><li>Large screen unit with low configuration and full functions</li><li>Include instrument cluster functions</li></ul>';
export const COCKPIT_POPUP_SECOND_CASE_FIRST_BOX_TEXT = 'Global';
export const COCKPIT_POPUP_SECOND_CASE_SECOND_BOX_TEXT = '';
export const COCKPIT_POPUP_SECOND_CASE_THIRD_BOX_TEXT = '2022.Q4';
export const COCKPIT_POPUP_SECOND_CASE_FOURTH_BOX_TEXT = 'SOC: MTK 2712 + i.MX8<br/>OS: Android 9.0 + QNX<br/>Vector MICROSAR<br/>';
export const COCKPIT_POPUP_SECOND_CASE_FIFTH_BOX_TEXT = 'A compact solution with one large display for both IVI and cluster. A separate telltale controller fulfils ASIL B level';

export const COCKPIT_POPUP_THIRD_CASE_TEXT = '<p>Development of complete Android framework (Android P) on top of the hypervisor for diverse 3rd party Android applications.</p>';
export const COCKPIT_POPUP_THIRD_CASE_FIRST_BOX_TEXT = 'China';
export const COCKPIT_POPUP_THIRD_CASE_SECOND_BOX_TEXT = '';
export const COCKPIT_POPUP_THIRD_CASE_THIRD_BOX_TEXT = '2019 – 2021';
export const COCKPIT_POPUP_THIRD_CASE_FOURTH_BOX_TEXT = 'SOC: QCT S820A / Samsung S7<br/>OS: Android for Automotive<br/>';
export const COCKPIT_POPUP_THIRD_CASE_FIFTH_BOX_TEXT = 'Enable the German Premium OEM JV in China to use 3rd Party Android applications without changing the hardware';

export const COCKPIT_POPUP_FOURTH_CASE_TEXT = '<p>Engineers in China developed a PoC for a Japanese OEM to monitor the health on board of the driver. The platform solution manages the data, collected via electrode sensor (steering wheel) and or in combination with the photoelectric sensors (smart watch) to provide early screening for diseases, emergency-, fatigue warnings, health data collection and more.</p>';
export const COCKPIT_POPUP_FOURTH_CASE_FIRST_BOX_TEXT = 'China';
export const COCKPIT_POPUP_FOURTH_CASE_SECOND_BOX_TEXT = '';
export const COCKPIT_POPUP_FOURTH_CASE_THIRD_BOX_TEXT = '2022';
export const COCKPIT_POPUP_FOURTH_CASE_FOURTH_BOX_TEXT = 'OS: Android for Automotive <br/>Supported wearable device OS: Apple Watch OS, Android Wear OS<br/>Camera based driver monitoring<br/>';
export const COCKPIT_POPUP_FOURTH_CASE_FIFTH_BOX_TEXT = 'Provide the right assistance in emergency cases, when it matters and get instant support by a doctor, pharmacy, hospital or other sources. Direct feedback and interaction of the driver via IVI, phone or wearable';

export const COCKPIT_EMPLOYEE_NAME = 'Mingzhe Sun';
export const COCKPIT_EMPLOYEE_TITLE =
  'Senior Business Developer / Expert for Connectivity & Cockpit';
export const COCKPIT_EMPLOYEE_JOB_DESCRIPTION =
  '15 years experience in automotive infotainment and connectivity areas in a global environment';
export const NAVIGATION_EMPLOYEE_LINKEDIN_LINK = 'https://www.linkedin.com/in/mingzhe-sun-299102232/';
