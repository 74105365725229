import React from 'react';
import { makeStyles } from '@material-ui/core';
import HomeService from './HomeService';

const useStyles = makeStyles(() => ({
  sliderWebContainer: {
    height: 'auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const SliderWeb = ({ content }) => {
  const classes = useStyles();

  return (
    <div className={classes.sliderWebContainer}>
      {content.map((homeService) => (
        <HomeService
          key={homeService.title}
          title={homeService.title}
          subtitle={homeService.subtitle}
          titleDescription={homeService.titleDescription}
          items={homeService.items}
          URL={homeService.URL}
          image={homeService.image}
          displayWrapper={homeService.displayWrapper}
          gridTemplateRows={homeService.gridTemplateRows}
          gridTemplateColumns={homeService.gridTemplateColumns}
          gridRowStartTitle={homeService.gridRowStartTitle}
          gridColumnStartTitle={homeService.gridColumnStartTitle}
          gridColumnEndTitle={homeService.gridColumnEndTitle}
          gridRowStartSubtitle={homeService.gridRowStartSubtitle}
          gridColumnStartSubtitle={homeService.gridColumnStartSubtitle}
          gridColumnEndSubtitl={homeService.gridColumnEndSubtitle}
          alignItemsWrapper={homeService.alignItemsWrapper}
          paddingRight={homeService.paddingRight}
          order={homeService.order}
          height={homeService.height}
          justifyContent={homeService.justifyContent}
          color={homeService.color}
          hoverBackgroundColor={homeService.hoverBackgroundColor}
          titleColor={homeService.titleColor}
          hoverTitleColor={homeService.hoverTitleColor}
          alignItems={homeService.alignItems}
          alignSubtitle={homeService.alignSubtitle}
          margin={homeService.margin}
          marignLeft={homeService.marignLeft}
          marginRight={homeService.marginRight}
          marginRightTitle={homeService.marginRightTitle}
          marginLeftTitle={homeService.marginLeftTitle}
          background={homeService.background}
          paddingLeft={homeService.paddingLeft}
          width={homeService.width}
          justifyContentWrapper={homeService.justifyContentWrapper}
        />
      ))}
    </div>
  );
};

export default SliderWeb;
