import * as navigationItem from './Navigation/navigationItems';
import * as connectivityItem from './Connectivity/connectivityItems';
import * as cockpitItem from './Cockpit/cockpitItems';
import * as testingItem from './Testing/testingItems';
import augmentedReality from '../../assets/images/offerings/navigation/AR-card.jpg';
import certificationChina from '../../assets/images/offerings/navigation/chinese-market.jpg';
import certificationJapan from '../../assets/images/offerings/navigation/japanese-market.jpg';
import mapImage from '../../assets/images/offerings/navigation/Map-Compilation.jpg';
import wwnImage from '../../assets/images/offerings/navigation/WWN.jpg';
import jochenImage from '../../assets/images/offerings/navigation/jochen.jpg';
import benchTests from '../../assets/images/offerings/testing/rs-testing.jpg';
import CANSimulation from '../../assets/images/offerings/testing/can-testing.jpg';
import testingEmployeeDesktop from '../../assets/images/offerings/testing/soren.jpg';
import roadTests from '../../assets/images/offerings/testing/rb-testing.jpg';
import unitTests from '../../assets/images/offerings/testing/unit-testing.jpg';
import testDrives from '../../assets/images/offerings/testing/test-drives.jpg';
import KPITests from '../../assets/images/offerings/testing/kpi-testing.jpg';
import g4 from '../../assets/images/offerings/connectivity/4g-tbox.jpg';
import g5 from '../../assets/images/offerings/connectivity/5g-tbox.jpg';
import cloud from '../../assets/images/offerings/connectivity/cloud-platform.jpg';
import rtm4G from '../../assets/images/offerings/connectivity/RTM-4g.jpg';
import evMobile from '../../assets/images/offerings/connectivity/ev-mobile.jpg';
import cockpitSystem from '../../assets/images/offerings/cockpit/cockpit-system.jpg';
import eCockpit from '../../assets/images/offerings/cockpit/e-cockpit.jpg';
import IVI from '../../assets/images/offerings/cockpit/IVI-android.jpg';
import PoC from '../../assets/images/offerings/cockpit/poc-health.jpg';
import ming from '../../assets/images/offerings/cockpit/ming.jpg';
import iconMaintenance from '../../assets/images/icons/offerings/testing/icons8-maintenance-100.svg';
import iconCloudShareTesting from '../../assets/images/icons/offerings/testing/icons8-cloud-share-symbol-100.svg';
import iconNavigationTesting from '../../assets/images/icons/offerings/testing/icons8-navigation-100.svg';
import iconTouchPad from '../../assets/images/icons/offerings/testing/icons8-touchpad-100.svg';
import iconDatabase from '../../assets/images/icons/offerings/navigation/icons8-cloud-database-100.svg';
import AIIcon from '../../assets/images/icons/offerings/navigation/icons8-artificial-intelligence-100.svg';
import cloudShareIcon from '../../assets/images/icons/offerings/navigation/icons8-cloud-share-symbol-100.svg';
import offlineIcon from '../../assets/images/icons/offerings/navigation/icons8-offline-100.svg';
import passFailIcon from '../../assets/images/icons/offerings/navigation/icons8-pass-fail-100.svg';
import staticViewsIcon from '../../assets/images/icons/offerings/navigation/icons8-static-views-100.svg';
import virtualRealityIcon from '../../assets/images/icons/offerings/navigation/icons8-virtual-reality-100.svg';
import iconTouchpadCockpit from '../../assets/images/icons/offerings/cockpit/icons8-touchpad-100.svg';
import iconSSDCockpit from '../../assets/images/icons/offerings/cockpit/icons8-ssd-100.svg';
import iconProgramCockpit from '../../assets/images/icons/offerings/cockpit/icons8-program-100.svg';
import iconDatabaseConnectivity from '../../assets/images/icons/offerings/connectivity/icons8-cloud-database-100.svg';
import iconUnitConnectivity from '../../assets/images/icons/offerings/connectivity/icons8-unit-100.svg';
import iconIoTConnectivity from '../../assets/images/icons/offerings/connectivity/icons8-iot-sensor-100.svg';
import iconSmartPhoneConnectivity from '../../assets/images/icons/offerings/connectivity/icons8-hand-with-smartphone-100.svg';
import { white, bannerColor } from '../../colors';

export const navigation = [
  {
    sectionTitle: navigationItem.NAVIGATION_SUBTITLE_FIRST,
    backgroundColor: white,
    backgroundColorTablet: white,
    offeringSectionWidthDesktop: '87%',
    offeringSectionWidthTablet: '90%',
    justifyContentSection: 'center',
    marginBottom: '7rem',
    marginBottomMobile: '4rem',
    firstSection: true,
    paragraph: navigationItem.NAVIGATION_PARAGRAPH_FIRST,
  },
  {
    sectionTitle: navigationItem.NAVIGATION_SUBTITLE_SECOND,
    backgroundColor: bannerColor,
    backgroundColorTablet: bannerColor,
    offeringSectionWidthDesktop: '100%',
    offeringSectionWidthTablet: '100%',
    offeringSectionWidthDesktopXL: '100%',
    focusAreaContainerWidthDesktop: '100%',
    focusAreaContainerWidthTablet: '100%',
    justifyContentSection: 'space-evenly',
    marginBottom: '4rem',
    marginBottomMobile: '1rem',
    secondSection: true,
    paragraph: '',
    focusAreas: [
      {
        focusAreaImage: staticViewsIcon,
        focusAreaTitle: navigationItem.NAVIGATION_FOCUS_FIRST_TITLE,
        focusAreaContent: navigationItem.NAVIGATION_FOCUS_FIRST_TEXT,
      },
      {
        focusAreaImage: offlineIcon,
        focusAreaTitle: navigationItem.NAVIGATION_FOCUS_SECOND_TITLE,
        focusAreaContent: navigationItem.NAVIGATION_FOCUS_SECOND_TEXT,
      },
      {
        focusAreaImage: iconDatabase,
        focusAreaTitle: navigationItem.NAVIGATION_FOCUS_THIRD_TITLE,
        focusAreaContent: navigationItem.NAVIGATION_FOCUS_THIRD_TEXT,
      },
      {
        focusAreaImage: cloudShareIcon,
        focusAreaTitle: navigationItem.NAVIGATION_FOCUS_FOURTH_TITLE,
        focusAreaContent: navigationItem.NAVIGATION_FOCUS_FOURTH_TEXT,
      },
      {
        focusAreaImage: virtualRealityIcon,
        focusAreaTitle: navigationItem.NAVIGATION_FOCUS_FIFTH_TITLE,
        focusAreaContent: navigationItem.NAVIGATION_FOCUS_FIFTH_TEXT,
      },
      {
        focusAreaImage: AIIcon,
        focusAreaTitle: navigationItem.NAVIGATION_FOCUS_SIXTH_TITLE,
        focusAreaContent: navigationItem.NAVIGATION_FOCUS_SIXTH_TEXT,
      },
      {
        focusAreaImage: passFailIcon,
        focusAreaTitle: navigationItem.NAVIGATION_FOCUS_SEVENTH_TITLE,
        focusAreaContent: navigationItem.NAVIGATION_FOCUS_SEVENTH_TEXT,
      },
    ],
  },
  {
    sectionTitle: navigationItem.NAVIGATION_SUBTITLE_THIRD,
    backgroundColor: white,
    backgroundColorTablet: bannerColor,
    offeringSectionWidthDesktop: '87%',
    offeringSectionWidthTablet: '100%',
    justifyContentSection: 'space-evenly',
    marginBottom: '6rem',
    marginBottomMobile: '0',
    gridTemplateRows: 'repeat(3,33%)',
    thirdSection: true,
    paragraph: '',
    referenceCases: [
      {
        case: navigationItem.NAVIGATION_CASE_PATH_FIRST,
        referenceCaseImage: wwnImage,
        referenceCaseTitle: navigationItem.NAVIGATION_CASE_TITLE_FIRST,
        referenceCaseSubitle: navigationItem.NAVIGATION_CASE_SUBTITLE_FIRST,
        referenceCaseText: navigationItem.NAVIGATION_CASE_TEXT_FIRST,
      },
      {
        case: navigationItem.NAVIGATION_CASE_PATH_SECOND,
        referenceCaseImage: augmentedReality,
        referenceCaseTitle: navigationItem.NAVIGATION_CASE_TITLE_SECOND,
        referenceCaseSubitle: navigationItem.NAVIGATION_CASE_SUBTITLE_SECOND,
        referenceCaseText: navigationItem.NAVIGATION_CASE_TEXT_SECOND,
      },
      {
        case: navigationItem.NAVIGATION_CASE_PATH_THIRD,
        referenceCaseImage: mapImage,
        referenceCaseTitle: navigationItem.NAVIGATION_CASE_TITLE_THIRD,
        referenceCaseSubitle: navigationItem.NAVIGATION_CASE_SUBTITLE_THIRD,
        referenceCaseText: navigationItem.NAVIGATION_CASE_TEXT_THIRD,
      },
      {
        case: navigationItem.NAVIGATION_CASE_PATH_FOURTH,
        referenceCaseImage: certificationChina,
        referenceCaseTitle: navigationItem.NAVIGATION_CASE_TITLE_FOURTH,
        referenceCaseSubitle: navigationItem.NAVIGATION_CASE_SUBTITLE_FOURTH,
        referenceCaseText: navigationItem.NAVIGATION_CASE_TEXT_FOURTH,
      },
      {
        case: navigationItem.NAVIGATION_CASE_PATH_FIFTH,
        referenceCaseImage: certificationJapan,
        referenceCaseTitle: navigationItem.NAVIGATION_CASE_TITLE_FIFTH,
        referenceCaseSubitle: navigationItem.NAVIGATION_CASE_SUBTITLE_FIFTH,
        referenceCaseText: navigationItem.NAVIGATION_CASE_TEXT_FIFTH,
      },
    ],
  },
  {
    sectionTitle: navigationItem.NAVIGATION_SUBTITLE_FORTH,
    backgroundColor: bannerColor,
    backgroundColorTablet: bannerColor,
    offeringSectionWidthDesktop: '87%',
    offeringSectionWidthTablet: '90%',
    justifyContentSection: 'space-evenly',
    marginBottom: '7rem',
    marginBottomMobile: '7rem',
    fourthSection: true,
    paragraph: '',
    contactImage: jochenImage,
    contactTitle: navigationItem.NAVIGATION_EMPLOYEE_NAME,
    contactSubtitle: navigationItem.NAVIGATION_EMPLOYEE_TITLE,
    contactText: navigationItem.NAVIGATION_EMPLOYEE_JOB_DESCRIPTION,
    linkedInLink: navigationItem.NAVIGATION_EMPLOYEE_LINKEDIN_LINK,
  },
];

export const testing = [
  {
    sectionTitle: testingItem.TESTING_SUBTITLE_FIRST,
    backgroundColor: white,
    backgroundColorTablet: white,
    offeringSectionWidthDesktop: '87%',
    offeringSectionWidthTablet: '90%',
    justifyContentSection: 'center',
    marginBottom: '7rem',
    marginBottomMobile: '4rem',
    firstSection: true,
    paragraph: testingItem.TESTING_PARAGRAPH_FIRST,
  },
  {
    sectionTitle: testingItem.TESTING_SUBTITLE_SECOND,
    backgroundColor: bannerColor,
    backgroundColorTablet: bannerColor,
    offeringSectionWidthDesktop: '87%',
    offeringSectionWidthDesktopXL: '70%',
    offeringSectionWidthTablet: '100%',
    focusAreaContainerWidthDesktop: '24.2%',
    focusAreaContainerWidthDesktopXL: '23.3%',
    focusAreaContainerWidthTablet: '100%',
    offeringSectionWidthSecond: '100%',
    justifyContentSection: 'space-evenly',
    marginBottom: '4rem',
    marginBottomMobile: '1rem',
    secondSection: true,
    paragraph: '',
    focusAreas: [
      {
        focusAreaImage: iconNavigationTesting,
        focusAreaTitle: testingItem.TESTING_FOCUS_FIRST_TITLE,
        focusAreaContent: testingItem.TESTING_FOCUS_FIRST_TEXT,
      },
      {
        focusAreaImage: iconTouchPad,
        focusAreaTitle: testingItem.TESTING_FOCUS_SECOND_TITLE,
        focusAreaContent: testingItem.TESTING_FOCUS_SECOND_TEXT,
      },
      {
        focusAreaImage: iconCloudShareTesting,
        focusAreaTitle: testingItem.TESTING_FOCUS_THIRD_TITLE,
        focusAreaContent: testingItem.TESTING_FOCUS_THIRD_TEXT,
      },
      {
        focusAreaImage: iconMaintenance,
        focusAreaTitle: testingItem.TESTING_FOCUS_FOURTH_TITLE,
        focusAreaContent: testingItem.TESTING_FOCUS_FOURTH_TEXT,
      },
    ],
  },
  {
    sectionTitle: testingItem.TESTING_SUBTITLE_THIRD,
    backgroundColor: white,
    backgroundColorTablet: bannerColor,
    offeringSectionWidthDesktop: '87%',
    offeringSectionWidthTablet: '100%',
    justifyContentSection: 'space-evenly',
    marginBottom: '6rem',
    marginBottomMobile: '0',
    gridTemplateRows: 'repeat(3,33%)',
    thirdSection: true,
    paragraph: '',
    referenceCases: [
      {
        case: testingItem.TESTING_CASE_PATH_FIRST,
        referenceCaseImage: benchTests,
        referenceCaseTitle: testingItem.TESTING_CASE_TITLE_FIRST,
        referenceCaseSubitle: testingItem.TESTING_CASE_SUBTITLE_FIRST,
        referenceCaseText: testingItem.TESTING_CASE_TEXT_FIRST,
      },
      {
        case: testingItem.TESTING_CASE_PATH_SECOND,
        referenceCaseImage: testDrives,
        referenceCaseTitle: testingItem.TESTING_CASE_TITLE_SECOND,
        referenceCaseSubitle: testingItem.TESTING_CASE_SUBTITLE_SECOND,
        referenceCaseText: testingItem.TESTING_CASE_TEXT_SECOND,
      },
      {
        case: testingItem.TESTING_CASE_PATH_THIRD,
        referenceCaseImage: roadTests,
        referenceCaseTitle: testingItem.TESTING_CASE_TITLE_THIRD,
        referenceCaseSubitle: testingItem.TESTING_CASE_SUBTITLE_THIRD,
        referenceCaseText: testingItem.TESTING_CASE_TEXT_THIRD,
      },
      {
        case: testingItem.TESTING_CASE_PATH_FOURTH,
        referenceCaseImage: unitTests,
        referenceCaseTitle: testingItem.TESTING_CASE_TITLE_FOURTH,
        referenceCaseSubitle: testingItem.TESTING_CASE_SUBTITLE_FOURTH,
        referenceCaseText: testingItem.TESTING_CASE_TEXT_FOURTH,
      },
      {
        case: testingItem.TESTING_CASE_PATH_FIFTH,
        referenceCaseImage: CANSimulation,
        referenceCaseTitle: testingItem.TESTING_CASE_TITLE_FIFTH,
        referenceCaseSubitle: testingItem.TESTING_CASE_SUBTITLE_FIFTH,
        referenceCaseText: testingItem.TESTING_CASE_TEXT_FIFTH,
      },
      {
        case: testingItem.TESTING_CASE_PATH_SIXTH,
        referenceCaseImage: KPITests,
        referenceCaseTitle: testingItem.TESTING_CASE_TITLE_SIXTH,
        referenceCaseSubitle: testingItem.TESTING_CASE_SUBTITLE_SIXTH,
        referenceCaseText: testingItem.TESTING_CASE_TEXT_SIXTH,
      },
    ],
  },
  {
    sectionTitle: testingItem.TESTING_SUBTITLE_FORTH,
    backgroundColor: bannerColor,
    backgroundColorTablet: bannerColor,
    offeringSectionWidthDesktop: '87%',
    offeringSectionWidthTablet: '90%',
    justifyContentSection: 'space-evenly',
    marginBottom: '7rem',
    marginBottomMobile: '7rem',
    fourthSection: true,
    paragraph: '',
    contactImage: testingEmployeeDesktop,
    contactTitle: testingItem.TESTING_EMPLOYEE_NAME,
    contactSubtitle: testingItem.TESTING_EMPLOYEE_TITLE,
    contactText: testingItem.TESTING_EMPLOYEE_JOB_DESCRIPTION,
    linkedInLink: testingItem.NAVIGATION_EMPLOYEE_LINKEDIN_LINK,
  },
];

export const cockpit = [
  {
    sectionTitle: cockpitItem.COCKPIT_SUBTITLE_FIRST,
    backgroundColor: white,
    backgroundColorTablet: white,
    offeringSectionWidthDesktop: '87%',
    offeringSectionWidthTablet: '90%',
    justifyContentSection: 'center',
    marginBottom: '7rem',
    marginBottomMobile: '4rem',
    firstSection: true,
    paragraph: cockpitItem.COCKPIT_PARAGRAPH_FIRST,
  },
  {
    sectionTitle: cockpitItem.COCKPIT_SUBTITLE_SECOND,
    backgroundColor: bannerColor,
    backgroundColorTablet: bannerColor,
    offeringSectionWidthDesktop: '87%',
    offeringSectionWidthDesktopXL: '70%',
    offeringSectionWidthTablet: '100%',
    offeringSectionWidthSecond: '100%',
    justifyContentSection: 'space-evenly',
    marginBottom: '4rem',
    marginBottomMobile: '1rem',
    focusAreaContainerWidthDesktop: '30.9%',
    focusAreaContainerWidthDesktopXL: '29.5%',
    focusAreaContainerWidthTablet: '100%',
    secondSection: true,
    paragraph: '',
    focusAreas: [
      {
        focusAreaImage: iconTouchpadCockpit,
        focusAreaTitle: cockpitItem.COCKPIT_FOCUS_FIRST_TITLE,
        focusAreaContent: cockpitItem.COCKPIT_FOCUS_FIRST_TEXT,
      },
      {
        focusAreaImage: iconSSDCockpit,
        focusAreaTitle: cockpitItem.COCKPIT_FOCUS_SECOND_TITLE,
        focusAreaContent: cockpitItem.COCKPIT_FOCUS_SECOND_TEXT,
      },
      {
        focusAreaImage: iconProgramCockpit,
        focusAreaTitle: cockpitItem.COCKPIT_FOCUS_THIRD_TITLE,
        focusAreaContent: cockpitItem.COCKPIT_FOCUS_THIRD_TEXT,
      },
    ],
  },
  {
    sectionTitle: cockpitItem.COCKPIT_SUBTITLE_THIRD,
    backgroundColor: white,
    backgroundColorTablet: bannerColor,
    offeringSectionWidthDesktop: '87%',
    offeringSectionWidthTablet: '100%',
    justifyContentSection: 'space-evenly',
    marginBottom: '6rem',
    marginBottomMobile: '0',
    gridTemplateRows: 'repeat(2,48%)',
    thirdSection: true,
    paragraph: '',
    referenceCases: [
      {
        case: cockpitItem.COCKPIT_CASE_PATH_FIRST,
        referenceCaseImage: eCockpit,
        referenceCaseTitle: cockpitItem.COCKPIT_CASE_TITLE_FIRST,
        referenceCaseSubitle: cockpitItem.COCKPIT_CASE_SUBTITLE_FIRST,
        referenceCaseText: cockpitItem.COCKPIT_CASE_TEXT_FIRST,
      },
      {
        case: cockpitItem.COCKPIT_CASE_PATH_SECOND,
        referenceCaseImage: cockpitSystem,
        referenceCaseTitle: cockpitItem.COCKPIT_CASE_TITLE_SECOND,
        referenceCaseSubitle: cockpitItem.COCKPIT_CASE_SUBTITLE_SECOND,
        referenceCaseText: cockpitItem.COCKPIT_CASE_TEXT_SECOND,
      },
      {
        case: cockpitItem.COCKPIT_CASE_PATH_THIRD,
        referenceCaseImage: IVI,
        referenceCaseTitle: cockpitItem.COCKPIT_CASE_TITLE_THIRD,
        referenceCaseSubitle: cockpitItem.COCKPIT_CASE_SUBTITLE_THIRD,
        referenceCaseText: cockpitItem.COCKPIT_CASE_TEXT_THIRD,
      },
      {
        case: cockpitItem.COCKPIT_CASE_PATH_FOURTH,
        referenceCaseImage: PoC,
        referenceCaseTitle: cockpitItem.COCKPIT_CASE_TITLE_FOURTH,
        referenceCaseSubitle: cockpitItem.COCKPIT_CASE_SUBTITLE_FOURTH,
        referenceCaseText: cockpitItem.COCKPIT_CASE_TEXT_FOURTH,
      },
    ],
  },
  {
    sectionTitle: cockpitItem.COCKPIT_SUBTITLE_FORTH,
    backgroundColor: bannerColor,
    backgroundColorTablet: bannerColor,
    offeringSectionWidthDesktop: '87%',
    offeringSectionWidthTablet: '90%',
    justifyContentSection: 'space-evenly',
    marginBottom: '7rem',
    marginBottomMobile: '7rem',
    fourthSection: true,
    paragraph: '',
    contactImage: ming,
    contactTitle: cockpitItem.COCKPIT_EMPLOYEE_NAME,
    contactSubtitle: cockpitItem.COCKPIT_EMPLOYEE_TITLE,
    contactText: cockpitItem.COCKPIT_EMPLOYEE_JOB_DESCRIPTION,
    linkedInLink: cockpitItem.NAVIGATION_EMPLOYEE_LINKEDIN_LINK,
  },
];

export const connectivity = [
  {
    sectionTitle: connectivityItem.CONNECTIVITY_SUBTITLE_FIRST,
    backgroundColor: white,
    backgroundColorTablet: white,
    offeringSectionWidthDesktop: '87%',
    offeringSectionWidthTablet: '90%',
    justifyContentSection: 'center',
    marginBottom: '7rem',
    marginBottomMobile: '4rem',
    firstSection: true,
    paragraph: connectivityItem.CONNECTIVITY_PARAGRAPH_FIRST,
  },
  {
    sectionTitle: connectivityItem.CONNECTIVITY_SUBTITLE_SECOND,
    backgroundColor: bannerColor,
    backgroundColorTablet: bannerColor,
    offeringSectionWidthDesktop: '87%',
    offeringSectionWidthDesktopXL: '70%',
    offeringSectionWidthTablet: '100%',
    offeringSectionWidthSecond: '100%',
    focusAreaContainerWidthDesktop: '24.2%',
    focusAreaContainerWidthDesktopXL: '23.3%',
    focusAreaContainerWidthTablet: '100%',
    justifyContentSection: 'space-evenly',
    marginBottom: '4rem',
    marginBottomMobile: '1rem',
    secondSection: true,
    paragraph: '',
    focusAreas: [
      {
        focusAreaImage: iconUnitConnectivity,
        focusAreaTitle: connectivityItem.CONNECTIVITY_FOCUS_FIRST_TITLE,
        focusAreaContent: connectivityItem.CONNECTIVITY_FOCUS_FIRST_TEXT,
      },
      {
        focusAreaImage: iconDatabaseConnectivity,
        focusAreaTitle: connectivityItem.CONNECTIVITY_FOCUS_SECOND_TITLE,
        focusAreaContent: connectivityItem.CONNECTIVITY_FOCUS_SECOND_TEXT,
      },
      {
        focusAreaImage: iconSmartPhoneConnectivity,
        focusAreaTitle: connectivityItem.CONNECTIVITY_FOCUS_THIRD_TITLE,
        focusAreaContent: connectivityItem.CONNECTIVITY_FOCUS_THIRD_TEXT,
      },
      {
        focusAreaImage: iconIoTConnectivity,
        focusAreaTitle: connectivityItem.CONNECTIVITY_FOCUS_FOURTH_TITLE,
        focusAreaContent: connectivityItem.CONNECTIVITY_FOCUS_FOURTH_TEXT,
      },
    ],
  },
  {
    sectionTitle: connectivityItem.CONNECTIVITY_SUBTITLE_THIRD,
    backgroundColor: white,
    backgroundColorTablet: bannerColor,
    offeringSectionWidthDesktop: '87%',
    offeringSectionWidthTablet: '100%',
    justifyContentSection: 'space-evenly',
    marginBottom: '6rem',
    marginBottomMobile: '0',
    gridTemplateRows: 'repeat(2,48%)',
    thirdSection: true,
    paragraph: '',
    referenceCases: [
      {
        case: connectivityItem.CONNECTIVITY_CASE_PATH_FIRST,
        referenceCaseImage: g5,
        referenceCaseTitle: connectivityItem.CONNECTIVITY_CASE_TITLE_FIRST,
        referenceCaseSubitle: connectivityItem.CONNECTIVITY_CASE_SUBTITLE_FIRST,
        referenceCaseText: connectivityItem.CONNECTIVITY_CASE_TEXT_FIRST,
      },
      {
        case: connectivityItem.CONNECTIVITY_CASE_PATH_SECOND,
        referenceCaseImage: rtm4G,
        referenceCaseTitle: connectivityItem.CONNECTIVITY_CASE_TITLE_SECOND,
        referenceCaseSubitle:
          connectivityItem.CONNECTIVITY_CASE_SUBTITLE_SECOND,
        referenceCaseText: connectivityItem.CONNECTIVITY_CASE_TEXT_SECOND,
      },
      {
        case: connectivityItem.CONNECTIVITY_CASE_PATH_THIRD,
        referenceCaseImage: g4,
        referenceCaseTitle: connectivityItem.CONNECTIVITY_CASE_TITLE_THIRD,
        referenceCaseSubitle: connectivityItem.CONNECTIVITY_CASE_SUBTITLE_THIRD,
        referenceCaseText: connectivityItem.CONNECTIVITY_CASE_TEXT_THIRD,
      },
      {
        case: connectivityItem.CONNECTIVITY_CASE_PATH_FOURTH,
        referenceCaseImage: cloud,
        referenceCaseTitle: connectivityItem.CONNECTIVITY_CASE_TITLE_FOURTH,
        referenceCaseSubitle:
          connectivityItem.CONNECTIVITY_CASE_SUBTITLE_FOURTH,
        referenceCaseText: connectivityItem.CONNECTIVITY_CASE_TEXT_FOURTH,
      },
      {
        case: connectivityItem.CONNECTIVITY_CASE_PATH_FIFTH,
        referenceCaseImage: evMobile,
        referenceCaseTitle: connectivityItem.CONNECTIVITY_CASE_TITLE_FIFTH,
        referenceCaseSubitle:
          connectivityItem.CONNECTIVITY_CASE_SUBTITLE_FIFTH,
        referenceCaseText: connectivityItem.CONNECTIVITY_CASE_TEXT_FIFTH,
      },
    ],
  },
  {
    sectionTitle: connectivityItem.CONNECTIVITY_SUBTITLE_FORTH,
    backgroundColor: bannerColor,
    backgroundColorTablet: bannerColor,
    offeringSectionWidthDesktop: '87%',
    offeringSectionWidthTablet: '90%',
    justifyContentSection: 'space-evenly',
    marginBottom: '7rem',
    marginBottomMobile: '7rem',
    fourthSection: true,
    paragraph: '',
    contactImage: ming,
    contactTitle: connectivityItem.CONNECTIVITY_EMPLOYEE_NAME,
    contactSubtitle: connectivityItem.CONNECTIVITY_EMPLOYEE_TITLE,
    contactText: connectivityItem.CONNECTIVITY_EMPLOYEE_JOB_DESCRIPTION,
    linkedInLink: connectivityItem.NAVIGATION_EMPLOYEE_LINKEDIN_LINK,
  },
];

export const allReferenceCases = [{
  image: wwnImage,
  pathName: navigationItem.NAVIGATION_CASE_PATH_FIRST,
  title: navigationItem.NAVIGATION_CASE_TITLE_FIRST,
  subtitle: navigationItem.NAVIGATION_CASE_SUBTITLE_FIRST,
  popUpText: navigationItem.NAVIGATION_POPUP_FIRST_CASE_TEXT,
  popUpsubcaseFirst: navigationItem.NAVIGATION_POPUP_FIRST_CASE_FIRST_BOX_TEXT,
  popUpsubcaseModelFirst: true,
  popUpsubcaseThird: navigationItem.NAVIGATION_POPUP_FIRST_CASE_THIRD_BOX_TEXT,
  popUpsubcaseFourth: navigationItem.NAVIGATION_POPUP_FIRST_CASE_FOURTH_BOX_TEXT,
  popUpsubcaseFifth: navigationItem.NAVIGATION_POPUP_FIRST_CASE_FIFTH_BOX_TEXT,
}, {
  image: augmentedReality,
  pathName: navigationItem.NAVIGATION_CASE_PATH_SECOND,
  title: navigationItem.NAVIGATION_CASE_TITLE_SECOND,
  subtitle: navigationItem.NAVIGATION_CASE_SUBTITLE_SECOND,
  popUpText: navigationItem.NAVIGATION_POPUP_SECOND_CASE_TEXT,
  popUpsubcaseFirst: navigationItem.NAVIGATION_POPUP_SECOND_CASE_FIRST_BOX_TEXT,
  popUpsubcaseModelSecond: true,
  popUpsubcaseThird: navigationItem.NAVIGATION_POPUP_SECOND_CASE_THIRD_BOX_TEXT,
  popUpsubcaseFourth: navigationItem.NAVIGATION_POPUP_SECOND_CASE_FOURTH_BOX_TEXT,
  popUpsubcaseFifth: navigationItem.NAVIGATION_POPUP_SECOND_CASE_FIFTH_BOX_TEXT,
}, {
  image: mapImage,
  pathName: navigationItem.NAVIGATION_CASE_PATH_THIRD,
  title: navigationItem.NAVIGATION_CASE_TITLE_THIRD,
  subtitle: navigationItem.NAVIGATION_CASE_SUBTITLE_THIRD,
  popUpText: navigationItem.NAVIGATION_POPUP_THIRD_CASE_TEXT,
  popUpsubcaseFirst: navigationItem.NAVIGATION_POPUP_THIRD_CASE_FIRST_BOX_TEXT,
  popUpsubcaseModelFirst: true,
  popUpsubcaseThird: navigationItem.NAVIGATION_POPUP_THIRD_CASE_THIRD_BOX_TEXT,
  popUpsubcaseFourth: navigationItem.NAVIGATION_POPUP_THIRD_CASE_FOURTH_BOX_TEXT,
  popUpsubcaseFifth: navigationItem.NAVIGATION_POPUP_THIRD_CASE_FIFTH_BOX_TEXT,
}, {
  image: certificationChina,
  pathName: navigationItem.NAVIGATION_CASE_PATH_FOURTH,
  title: navigationItem.NAVIGATION_CASE_TITLE_FOURTH,
  subtitle: navigationItem.NAVIGATION_CASE_SUBTITLE_FOURTH,
  popUpText: navigationItem.NAVIGATION_POPUP_FOURTH_CASE_TEXT,
  popUpsubcaseFirst: navigationItem.NAVIGATION_POPUP_FOURTH_CASE_FIRST_BOX_TEXT,
  popUpsubcaseModelFirst: true,
  popUpsubcaseThird: navigationItem.NAVIGATION_POPUP_FOURTH_CASE_THIRD_BOX_TEXT,
  popUpsubcaseFourth: navigationItem.NAVIGATION_POPUP_FOURTH_CASE_FOURTH_BOX_TEXT,
  popUpsubcaseFifth: navigationItem.NAVIGATION_POPUP_FOURTH_CASE_FIFTH_BOX_TEXT,
}, {
  image: certificationJapan,
  pathName: navigationItem.NAVIGATION_CASE_PATH_FIFTH,
  title: navigationItem.NAVIGATION_CASE_TITLE_FIFTH,
  subtitle: navigationItem.NAVIGATION_CASE_SUBTITLE_FIFTH,
  popUpText: navigationItem.NAVIGATION_POPUP_FIFTH_CASE_TEXT,
  popUpsubcaseFirst: navigationItem.NAVIGATION_POPUP_FIFTH_CASE_FIRST_BOX_TEXT,
  popUpsubcaseModelFirst: true,
  popUpsubcaseThird: navigationItem.NAVIGATION_POPUP_FIFTH_CASE_THIRD_BOX_TEXT,
  popUpsubcaseFourth: navigationItem.NAVIGATION_POPUP_FIFTH_CASE_FOURTH_BOX_TEXT,
  popUpsubcaseFifth: navigationItem.NAVIGATION_POPUP_FIFTH_CASE_FIFTH_BOX_TEXT,
},
{
  image: g5,
  pathName: connectivityItem.CONNECTIVITY_CASE_PATH_FIRST,
  title: connectivityItem.CONNECTIVITY_CASE_TITLE_FIRST,
  subtitle: connectivityItem.CONNECTIVITY_CASE_SUBTITLE_FIRST,
  popUpText: connectivityItem.CONNECTIVITY_POPUP_FIRST_CASE_TEXT,
  popUpsubcaseFirst: connectivityItem.CONNECTIVITY_POPUP_FIRST_CASE_FIRST_BOX_TEXT,
  popUpsubcaseModelSecond: true,
  popUpsubcaseThird: connectivityItem.CONNECTIVITY_POPUP_FIRST_CASE_THIRD_BOX_TEXT,
  popUpsubcaseFourth: connectivityItem.CONNECTIVITY_POPUP_FIRST_CASE_FOURTH_BOX_TEXT,
  popUpsubcaseFifth: connectivityItem.CONNECTIVITY_POPUP_FIRST_CASE_FIFTH_BOX_TEXT,
}, {
  image: rtm4G,
  pathName: connectivityItem.CONNECTIVITY_CASE_PATH_SECOND,
  title: connectivityItem.CONNECTIVITY_CASE_TITLE_SECOND,
  subtitle: connectivityItem.CONNECTIVITY_CASE_SUBTITLE_SECOND,
  popUpText: connectivityItem.CONNECTIVITY_POPUP_SECOND_CASE_TEXT,
  popUpsubcaseFirst: connectivityItem.CONNECTIVITY_POPUP_SECOND_CASE_FIRST_BOX_TEXT,
  popUpsubcaseModelSecond: true,
  popUpsubcaseThird: connectivityItem.CONNECTIVITY_POPUP_SECOND_CASE_THIRD_BOX_TEXT,
  popUpsubcaseFourth: connectivityItem.CONNECTIVITY_POPUP_SECOND_CASE_FOURTH_BOX_TEXT,
  popUpsubcaseFifth: connectivityItem.CONNECTIVITY_POPUP_SECOND_CASE_FIFTH_BOX_TEXT,
}, {
  image: g4,
  pathName: connectivityItem.CONNECTIVITY_CASE_PATH_THIRD,
  title: connectivityItem.CONNECTIVITY_CASE_TITLE_THIRD,
  subtitle: connectivityItem.CONNECTIVITY_CASE_SUBTITLE_THIRD,
  popUpText: connectivityItem.CONNECTIVITY_POPUP_THIRD_CASE_TEXT,
  popUpsubcaseFirst: connectivityItem.CONNECTIVITY_POPUP_THIRD_CASE_FIRST_BOX_TEXT,
  popUpsubcaseModelSecond: true,
  popUpsubcaseThird: connectivityItem.CONNECTIVITY_POPUP_THIRD_CASE_THIRD_BOX_TEXT,
  popUpsubcaseFourth: connectivityItem.CONNECTIVITY_POPUP_THIRD_CASE_FOURTH_BOX_TEXT,
  popUpsubcaseFifth: connectivityItem.CONNECTIVITY_POPUP_THIRD_CASE_FIFTH_BOX_TEXT,
}, {
  image: cloud,
  pathName: connectivityItem.CONNECTIVITY_CASE_PATH_FOURTH,
  title: connectivityItem.CONNECTIVITY_CASE_TITLE_FOURTH,
  subtitle: connectivityItem.CONNECTIVITY_CASE_SUBTITLE_FOURTH,
  popUpText: connectivityItem.CONNECTIVITY_POPUP_FOURTH_CASE_TEXT,
  popUpsubcaseFirst: connectivityItem.CONNECTIVITY_POPUP_FOURTH_CASE_FIRST_BOX_TEXT,
  popUpsubcaseModelSecond: true,
  popUpsubcaseThird: connectivityItem.CONNECTIVITY_POPUP_FOURTH_CASE_THIRD_BOX_TEXT,
  popUpsubcaseFourth: connectivityItem.CONNECTIVITY_POPUP_FOURTH_CASE_FOURTH_BOX_TEXT,
  popUpsubcaseFifth: connectivityItem.CONNECTIVITY_POPUP_FOURTH_CASE_FIFTH_BOX_TEXT,
},
{
  image: evMobile,
  pathName: connectivityItem.CONNECTIVITY_CASE_PATH_FIFTH,
  title: connectivityItem.CONNECTIVITY_CASE_TITLE_FIFTH,
  subtitle: connectivityItem.CONNECTIVITY_CASE_SUBTITLE_FIFTH,
  popUpText: connectivityItem.CONNECTIVITY_POPUP_FIFTH_CASE_TEXT,
  popUpsubcaseFirst: connectivityItem.CONNECTIVITY_POPUP_FIFTH_CASE_FIRST_BOX_TEXT,
  popUpsubcaseModelSecond: true,
  popUpsubcaseThird: connectivityItem.CONNECTIVITY_POPUP_FIFTH_CASE_THIRD_BOX_TEXT,
  popUpsubcaseFourth: connectivityItem.CONNECTIVITY_POPUP_FIFTH_CASE_FOURTH_BOX_TEXT,
  popUpsubcaseFifth: connectivityItem.CONNECTIVITY_POPUP_FIFTH_CASE_FIFTH_BOX_TEXT,
},
{
  image: roadTests,
  pathName: testingItem.TESTING_CASE_PATH_FIRST,
  title: testingItem.TESTING_CASE_TITLE_FIRST,
  subtitle: testingItem.TESTING_CASE_SUBTITLE_FIRST,
  popUpText: testingItem.TESTING_POPUP_FIRST_CASE_TEXT,
  popUpsubcaseFirst: testingItem.TESTING_POPUP_FIRST_CASE_FIRST_BOX_TEXT,
  popUpsubcaseModelSecond: true,
  popUpsubcaseThird: testingItem.TESTING_POPUP_FIRST_CASE_THIRD_BOX_TEXT,
  popUpsubcaseFourth: testingItem.TESTING_POPUP_FIRST_CASE_FOURTH_BOX_TEXT,
  popUpsubcaseFifth: testingItem.TESTING_POPUP_FIRST_CASE_FIFTH_BOX_TEXT,
}, {
  image: testDrives,
  pathName: testingItem.TESTING_CASE_PATH_SECOND,
  title: testingItem.TESTING_CASE_TITLE_SECOND,
  subtitle: testingItem.TESTING_CASE_SUBTITLE_SECOND,
  popUpText: testingItem.TESTING_POPUP_SECOND_CASE_TEXT,
  popUpsubcaseFirst: testingItem.TESTING_POPUP_SECOND_CASE_FIRST_BOX_TEXT,
  popUpsubcaseModelSecond: true,
  popUpsubcaseThird: testingItem.TESTING_POPUP_SECOND_CASE_THIRD_BOX_TEXT,
  popUpsubcaseFourth: testingItem.TESTING_POPUP_SECOND_CASE_FOURTH_BOX_TEXT,
  popUpsubcaseFifth: testingItem.TESTING_POPUP_SECOND_CASE_FIFTH_BOX_TEXT,
}, {
  image: benchTests,
  pathName: testingItem.TESTING_CASE_PATH_THIRD,
  title: testingItem.TESTING_CASE_TITLE_THIRD,
  subtitle: testingItem.TESTING_CASE_SUBTITLE_THIRD,
  popUpText: testingItem.TESTING_POPUP_THIRD_CASE_TEXT,
  popUpsubcaseFirst: testingItem.TESTING_POPUP_THIRD_CASE_FIRST_BOX_TEXT,
  popUpsubcaseModelThird: true,
  popUpsubcaseThird: testingItem.TESTING_POPUP_THIRD_CASE_THIRD_BOX_TEXT,
  popUpsubcaseFourth: testingItem.TESTING_POPUP_THIRD_CASE_FOURTH_BOX_TEXT,
  popUpsubcaseFifth: testingItem.TESTING_POPUP_THIRD_CASE_FIFTH_BOX_TEXT,
}, {
  image: unitTests,
  pathName: testingItem.TESTING_CASE_PATH_FOURTH,
  title: testingItem.TESTING_CASE_TITLE_FOURTH,
  subtitle: testingItem.TESTING_CASE_SUBTITLE_FOURTH,
  popUpText: testingItem.TESTING_POPUP_FOURTH_CASE_TEXT,
  popUpsubcaseFirst: testingItem.TESTING_POPUP_FOURTH_CASE_FIRST_BOX_TEXT,
  popUpsubcaseModelThird: true,
  popUpsubcaseThird: testingItem.TESTING_POPUP_FOURTH_CASE_THIRD_BOX_TEXT,
  popUpsubcaseFourth: testingItem.TESTING_POPUP_FOURTH_CASE_FOURTH_BOX_TEXT,
  popUpsubcaseFifth: testingItem.TESTING_POPUP_FOURTH_CASE_FIFTH_BOX_TEXT,
},
{
  image: CANSimulation,
  pathName: testingItem.TESTING_CASE_PATH_FIFTH,
  title: testingItem.TESTING_CASE_TITLE_FIFTH,
  subtitle: testingItem.TESTING_CASE_SUBTITLE_FIFTH,
  popUpText: testingItem.TESTING_POPUP_FIFTH_CASE_TEXT,
  popUpsubcaseFirst: testingItem.TESTING_POPUP_FIFTH_CASE_FIRST_BOX_TEXT,
  popUpsubcaseModelThird: true,
  popUpsubcaseThird: testingItem.TESTING_POPUP_FIFTH_CASE_THIRD_BOX_TEXT,
  popUpsubcaseFourth: testingItem.TESTING_POPUP_FIFTH_CASE_FOURTH_BOX_TEXT,
  popUpsubcaseFifth: testingItem.TESTING_POPUP_FIFTH_CASE_FIFTH_BOX_TEXT,
},
{
  image: KPITests,
  pathName: testingItem.TESTING_CASE_PATH_SIXTH,
  title: testingItem.TESTING_CASE_TITLE_SIXTH,
  subtitle: testingItem.TESTING_CASE_SUBTITLE_SIXTH,
  popUpText: testingItem.TESTING_POPUP_SIXTH_CASE_TEXT,
  popUpsubcaseFirst: testingItem.TESTING_POPUP_SIXTH_CASE_FIRST_BOX_TEXT,
  popUpsubcaseModelThird: true,
  popUpsubcaseThird: testingItem.TESTING_POPUP_SIXTH_CASE_THIRD_BOX_TEXT,
  popUpsubcaseFourth: testingItem.TESTING_POPUP_SIXTH_CASE_FOURTH_BOX_TEXT,
  popUpsubcaseFifth: testingItem.TESTING_POPUP_SIXTH_CASE_FIFTH_BOX_TEXT,
},
{
  image: eCockpit,
  pathName: cockpitItem.COCKPIT_CASE_PATH_FIRST,
  title: cockpitItem.COCKPIT_CASE_TITLE_FIRST,
  subtitle: cockpitItem.COCKPIT_CASE_SUBTITLE_FIRST,
  popUpText: cockpitItem.COCKPIT_POPUP_FIRST_CASE_TEXT,
  popUpsubcaseFirst: cockpitItem.COCKPIT_POPUP_FIRST_CASE_FIRST_BOX_TEXT,
  popUpsubcaseModelSecond: true,
  popUpsubcaseThird: cockpitItem.COCKPIT_POPUP_FIRST_CASE_THIRD_BOX_TEXT,
  popUpsubcaseFourth: cockpitItem.COCKPIT_POPUP_FIRST_CASE_FOURTH_BOX_TEXT,
  popUpsubcaseFifth: cockpitItem.COCKPIT_POPUP_FIRST_CASE_FIFTH_BOX_TEXT,
}, {
  image: cockpitSystem,
  pathName: cockpitItem.COCKPIT_CASE_PATH_SECOND,
  title: cockpitItem.COCKPIT_CASE_TITLE_SECOND,
  subtitle: cockpitItem.COCKPIT_CASE_SUBTITLE_SECOND,
  popUpText: cockpitItem.COCKPIT_POPUP_SECOND_CASE_TEXT,
  popUpsubcaseFirst: cockpitItem.COCKPIT_POPUP_SECOND_CASE_FIRST_BOX_TEXT,
  popUpsubcaseModelSecond: true,
  popUpsubcaseThird: cockpitItem.COCKPIT_POPUP_SECOND_CASE_THIRD_BOX_TEXT,
  popUpsubcaseFourth: cockpitItem.COCKPIT_POPUP_SECOND_CASE_FOURTH_BOX_TEXT,
  popUpsubcaseFifth: cockpitItem.COCKPIT_POPUP_SECOND_CASE_FIFTH_BOX_TEXT,
}, {
  image: IVI,
  pathName: cockpitItem.COCKPIT_CASE_PATH_THIRD,
  title: cockpitItem.COCKPIT_CASE_TITLE_THIRD,
  subtitle: cockpitItem.COCKPIT_CASE_SUBTITLE_THIRD,
  popUpText: cockpitItem.COCKPIT_POPUP_THIRD_CASE_TEXT,
  popUpsubcaseFirst: cockpitItem.COCKPIT_POPUP_THIRD_CASE_FIRST_BOX_TEXT,
  popUpsubcaseModelFourth: true,
  popUpsubcaseThird: cockpitItem.COCKPIT_POPUP_THIRD_CASE_THIRD_BOX_TEXT,
  popUpsubcaseFourth: cockpitItem.COCKPIT_POPUP_THIRD_CASE_FOURTH_BOX_TEXT,
  popUpsubcaseFifth: cockpitItem.COCKPIT_POPUP_THIRD_CASE_FIFTH_BOX_TEXT,
}, {
  image: PoC,
  pathName: cockpitItem.COCKPIT_CASE_PATH_FOURTH,
  title: cockpitItem.COCKPIT_CASE_TITLE_FOURTH,
  subtitle: cockpitItem.COCKPIT_CASE_SUBTITLE_FOURTH,
  popUpText: cockpitItem.COCKPIT_POPUP_FOURTH_CASE_TEXT,
  popUpsubcaseFirst: cockpitItem.COCKPIT_POPUP_FOURTH_CASE_FIRST_BOX_TEXT,
  popUpsubcaseModelSecond: true,
  popUpsubcaseThird: cockpitItem.COCKPIT_POPUP_FOURTH_CASE_THIRD_BOX_TEXT,
  popUpsubcaseFourth: cockpitItem.COCKPIT_POPUP_FOURTH_CASE_FOURTH_BOX_TEXT,
  popUpsubcaseFifth: cockpitItem.COCKPIT_POPUP_FOURTH_CASE_FIFTH_BOX_TEXT,
},
];
