import { useState, useEffect } from 'react';

export default function useDeviceSize() {
  const [deviceSize, setDeviceSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    const getDeviceDimensions = () => {
      setDeviceSize({
        width: window.screen.width,
        height: window.screen.height,
      });
    };
    window.addEventListener('load', getDeviceDimensions);
    return () => window.removeEventListener('load', getDeviceDimensions);
  }, [deviceSize]);
  useEffect(() => {
    const getDeviceDimensions = () => {
      setDeviceSize({
        width: window.screen.width,
        height: window.screen.height,
      });
    };
    window.addEventListener('resize', getDeviceDimensions);
    return () => window.removeEventListener('resize', getDeviceDimensions);
  }, [deviceSize]);
  return deviceSize;
}
