import React from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import UseScrollToTop from '../../hooks/UseScrollToTop';
import TitleSection from '../../components/Careers/TitleSection';
import WorkingSection from '../../components/Careers/WorkingSection';
import HeroSection from '../../components/HomeSection/HeroSection';
import RedirectToTop from '../../components/Footer/RedirectToTop';
import OpenPosition from '../../components/Careers/OpenPosition';

const useStyles = makeStyles(() => ({
  aboutUsContainer: {
    overflow: 'hidden',
    minHeight: '100vh',
    width: '100%',
    position: 'relative',
  },
}));

const Careers = ({
  deviceSize,
  windowSize,
  title,
  subtitle,
  video,
  content4k,
  content2k,
  HDcontent,
  mobileContent,
  tabletContent,
  items,
  titleSection,
  firstParagraphText,
  secondParagraphText,
  firstLabel,
  titleParagraph,
  secondLabel,
  button,
  image,
  marginTop,
  marginBottom,
  positions,
  positionId,
  setPositionId,
  ...props
}) => {
  const classes = useStyles(props);
  const location = useLocation();
  const positionPath = location.pathname.split('/')[2];

  return (
    <div className={classes.aboutUsContainer}>
      {!positionPath && (
        <>
          <HeroSection
            deviceSize={deviceSize}
            positionSection="relative"
            subtitle={subtitle}
            video={video}
            content4k={content4k}
            content2k={content2k}
            HDcontent={HDcontent}
            mobileContent={mobileContent}
            tabletContent={tabletContent}
            justifyContent="flex-start"
            margin="2.5rem"
          />
          <TitleSection
            windowSize={windowSize}
            items={items}
            label={firstLabel}
            marginTop={marginTop}
            marginBottom={marginBottom}
            titleParagraph={titleParagraph}
          />
          <WorkingSection
            deviceSize={deviceSize}
            firstParagraphText={firstParagraphText}
            secondParagraphText={secondParagraphText}
            label={secondLabel}
            button={button}
            image={image}
            positions={positions}
            setPositionId={setPositionId}
          />
        </>
      )}
      {positionPath && (
        <OpenPosition
          deviceSize={deviceSize}
          positions={positions}
          positionId={positionId}
        />
      )}
      <RedirectToTop />
      <UseScrollToTop />
    </div>
  );
};

export default Careers;
