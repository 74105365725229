import React from 'react';
import { makeStyles } from '@material-ui/core';
import UseScrollToTop from '../../hooks/UseScrollToTop';
import HeroSection from '../../components/HomeSection/HeroSection';
import Banner from '../../components/HomeSection/Banner';
import DescriptionComponent from '../../components/AboutUs/DescriptionComponent';
import RedirectToTop from '../../components/Footer/RedirectToTop';

const useStyles = makeStyles(() => ({
  aboutUsContainer: {
    overflow: 'hidden',
    minHeight: '100vh',
    width: '100%',
    position: 'relative',
  },
}));

const AboutUs = ({
  deviceSize,
  title,
  subtitle,
  video,
  content4k,
  content2k,
  HDcontent,
  mobileContent,
  tabletContent,
  bannerImageHD,
  bannerImageMobile,
  bannerImageTablet,
  descriptionTitle,
  secondDescriptionTitle,
  secondDescriptionTitleMobile1,
  secondDescriptionTitleMobile2,
  paragraph,
  descriptionImageWeb,
  descriptionImageMobile,
  descriptionImageTablet,
  secondImageWeb,
  secondImageMobile,
  secondImageTablet,
  imageHeight,
  heightTablet,
  firstBoxAboutSecondDisplay,
  secondBoxAboutSecondDisplay,
  imageDescriptionHeight,
  subparagraphMarginTop,
  secondParagraph,
  newParagraph,
  thirdParagraph,
  fourthParagraph,
  firstListTitle,
  secondListTitle,
  thirdListTitle,
  minHeightBanner,
  heightBannerLarge,
  justifyContentFirst,
  justifyContentSecond,
  marginTopBoxFirst,
  marginTopBoxSecond,
  marginTopFirstSection,
  marginBottomFirstSection,
  marginTopSecondSection,
  marginBottomSecondSection,
  marginTopFirstSectionTablet,
  marginBottomFirstSectionTablet,
  marginTopSecondSectionTablet,
  marginBottomSecondSectionTablet,
  bannerColor,
  marginTop2k,
  marginBottom2k,
  gridFirst,
  gridSecond,
  gridInitial,
  ...props
}) => {
  const classes = useStyles(props);

  return (
    <div className={classes.aboutUsContainer}>
      <HeroSection
        deviceSize={deviceSize}
        positionSection="relative"
        title={title}
        subtitle={subtitle}
        video={video}
        content4k={content4k}
        content2k={content2k}
        HDcontent={HDcontent}
        mobileContent={mobileContent}
        tabletContent={tabletContent}
        justifyContent="flex-start"
        margin="2.5rem"
      />
      <DescriptionComponent
        deviceSize={deviceSize}
        title={descriptionTitle}
        paragraph={paragraph}
        newParagraph={newParagraph}
        secondParagraph={secondParagraph}
        descriptionImageWeb={descriptionImageWeb}
        descriptionImageMobile={descriptionImageMobile}
        descriptionImageTablet={descriptionImageTablet}
        imageHeight={imageHeight}
        boxAboutSecondDisplay={firstBoxAboutSecondDisplay}
        imageDescriptionHeight={imageDescriptionHeight}
        subparagraphMarginTop={subparagraphMarginTop}
        justifyContent={justifyContentFirst}
        marginTopBox={marginTopBoxFirst}
        marginTop={marginTopFirstSection}
        marginBottom={marginBottomFirstSection}
        marginTopTablet={marginTopFirstSectionTablet}
        marginBottomTablet={marginBottomFirstSectionTablet}
        marginTop2k={marginTop2k}
        marginBottom2k={marginBottom2k}
        grid={gridFirst}
        gridInitial={gridInitial}

      />
      <Banner
        deviceSize={deviceSize}
        minHeight={minHeightBanner}
        heightTablet={heightTablet}
        bannerImageWeb={bannerImageHD}
        bannerImageMobile={bannerImageMobile}
        bannerImageTablet={bannerImageTablet}
        bannerColor={bannerColor}
        heightBannerLarge={heightBannerLarge}
      />
      <DescriptionComponent
        deviceSize={deviceSize}
        title={secondDescriptionTitle}
        secondDescriptionTitleMobile1={secondDescriptionTitleMobile1}
        secondDescriptionTitleMobile2={secondDescriptionTitleMobile2}
        paragraph={thirdParagraph}
        secondParagraph={fourthParagraph}
        secondImageWeb={secondImageWeb}
        secondImageMobile={secondImageMobile}
        secondImageTablet={secondImageTablet}
        imageHeight={imageHeight}
        boxAboutSecondDisplay={secondBoxAboutSecondDisplay}
        firstListTitle={firstListTitle}
        secondListTitle={secondListTitle}
        imageDescriptionHeight={imageDescriptionHeight}
        subparagraphMarginTop={subparagraphMarginTop}
        thirdListTitle={thirdListTitle}
        newParagraph={newParagraph}
        justifyContent={justifyContentSecond}
        marginTopBox={marginTopBoxSecond}
        marginTop={marginTopSecondSection}
        marginBottom={marginBottomSecondSection}
        marginTopTablet={marginTopSecondSectionTablet}
        marginBottomTablet={marginBottomSecondSectionTablet}
        marginTop2k={marginTop2k}
        marginBottom2k={marginBottom2k}
        grid={gridSecond}
        gridInitial={gridInitial}
      />
      <RedirectToTop />
      <UseScrollToTop />
    </div>
  );
};

export default AboutUs;
