import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { REFERENCE_CASES_PATHS } from '../components/Offering/Navigation/navigationItems';

const UseScrollToTop = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [previousPathCase, setPreviousPathCase] = useState(currentPath);
  const [pathCase, setPathCase] = useState();

  useEffect(() => {
    if (location.pathname !== currentPath) {
      const current = currentPath;
      setCurrentPath(location.pathname);
      setPreviousPathCase(current.split('/')[2]);
      const pathCaseCrop = location.pathname.split('/')[2];
      setPathCase(pathCaseCrop);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (pathCase) {
      if (!REFERENCE_CASES_PATHS.includes(pathCase)) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    } else if (previousPathCase) {
      if (!REFERENCE_CASES_PATHS.includes(previousPathCase)) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    } else {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [pathCase, currentPath]);

  return null;
};

export default UseScrollToTop;
