/* eslint-disable max-len */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import TextBox from '../../components/Common/TextBox';
import UseScrollToTop from '../../hooks/UseScrollToTop';
import RedirectToTop from '../../components/Footer/RedirectToTop';
import imageSources from '../../components/Common/imageSources';
import {
  bannerColor,
  neusoftBlue,
  textColor,
  white,
} from '../../colors';

const useStyles = makeStyles(() => ({
  aboutUsContainer: {
    overflow: 'hidden',
    minHeight: '100vh',
    width: '100%',
    position: 'relative',
  },
  container: {
    marginTop: '50px',
    whiteSpace: 'pre-wrap',
  },
  imageText: {
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: bannerColor,
    '& h3': {
      textAlign: 'center',
      color: neusoftBlue,
      marginTop: '2rem',
      '@media only screen and (max-device-width : 67.5em)': {
        fontSize: '2.2rem',
      },
      '@media only screen and (max-width : 40.625em)': {
        fontSize: '1.98rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.8rem',
      },
    },
    '& h4': {
      fontWeight: 'normal',
      width: '60%',
      margin: '1.5rem auto',
      marginBottom: '3.5rem',
      textAlign: 'center',
      '@media(max-width:47em)': {
        fontSize: '1.35rem',
        lineHeight: '1.5',
        textAlign: 'center',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.35rem',
        lineHeight: '1.5',
        marginTop: '1rem',
      },
    },
    '& ul': {
      '& li': {
        paddingLeft: '1rem',
        '& ul': {
          paddingInlineStart: '2rem',
        },
      },
    },
  },
  button: {
    cursor: 'pointer',
    width: '10.5rem',
    border: '2px solid',
    borderColor: textColor,
    padding: '1rem 0.2rem',
    background: 'transparent',
    color: textColor,
    fontWeight: 'bold',
    fontSize: '1.125rem',
    textTransform: 'uppercase',
    marginTop: '1rem',
    marginBottom: '3rem',
    '@media(max-device-width:40.625em)': {
      width: '9.2rem',
      padding: '0.6rem 0.2rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      width: '8.5rem',
    },
    '@media only screen and (min-device-width : 48em) and (max-device-width : 67.5em) and (orientation: portrait)':
    {
      width: '12.45rem',
      fontSize: '1.35rem',
      padding: '1.3rem 0.2rem',
    },
    '@media only screen and (max-device-width : 20em)': {
      padding: '0.5rem 0.2rem',
      width: '10.5rem',
    },
    '&:hover': {
      background: textColor,
      color: white,
    },
  },
  imagesContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '70%',
    margin: '0 auto',
    '@media(max-width:67.5em)': {
      width: '90%',
    },
    '@media(max-device-width:67.5em)': {
      width: '90%',
    },
    '@media(min-width:150em)': {
      width: '30%',
    },
  },
  imageBoxClose: {
    opacity: '0',
    visibility: 'hidden',
    transition: 'all 1.5s ease',
    height: 'auto',
    width: '100%',
    backgroundColor: white,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageBoxOpen: {
    opacity: '1',
    visibility: 'visible',
    transition: 'all 1.5s ease',
    height: 'auto',
    width: '100%',
    backgroundColor: white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '6rem 0',
  },
  imageSource: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'space-between',
  },
  itemTitle: {
    width: 'auto',
    fontWeight: 'bold',
    '& span': {
      marginRight: '0.5rem',
    },
  },
  subtitle: {
    textAlign: 'left',
    width: '60%',
    wordBreak: 'break-word',
    '@media(max-width:67.5em)': {
      width: 'auto',
      alignItems: 'flex-end',
      textAlign: 'right',
    },
    '@media(max-device-width:67.5em)': {
      width: 'auto',
    },
    '@media(min-width:150em)': {
      width: '50%',
    },
  },
  containerReference: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& p': {
      wordBreak: 'break-word',
      textAlign: 'left',
      '@media(max-width:67.5em)': {
        textAlign: 'right',
      },
    },
    '@media(max-width:67.5em)': {
      width: 'auto',
      alignItems: 'flex-end',
    },
    '@media(max-device-width:67.5em)': {
      width: 'auto',
    },
    '@media(min-width:150em)': {
      width: '50%',
    },
  },
}));

const imprintTitle = 'Imprint';

const Imprint = ({ deviceSize }) => {
  const classes = useStyles();
  const [showMore, setShowMore] = useState(false);

  return (
    <div className={classes.aboutUsContainer}>
      <TextBox
        deviceSize={deviceSize}
        title={imprintTitle}
        sections={
          [
            {
              content: (
                <div className={classes.container}>
                  <h4>Information pursuant to Sect. 5 German Telemedia Act (TMG)</h4>
                  <p>
                    Neusoft Technology Solutions GmbH
                    <br />
                    Hugh-Greene-Weg 2-4
                    <br />
                    22529 Hamburg
                  </p>
                  <p>
                    Commercial Register: HRB 112243
                    <br />
                    Registration court: Hamburg
                  </p>
                  <p>
                    <strong>Represented by:</strong>
                    <br />
                    Han Shu
                  </p>
                  <h4>Contact</h4>
                  <p>
                    Phone: +49 40 30067 700
                    <br />
                    Telefax: ---
                    <br />
                    E-mail:
                    {' '}
                    admin@de.neusoft.com
                  </p>
                  <h4>VAT ID</h4>
                  <p>
                    Sales tax identification number according to Sect. 27 a of the Sales Tax Law:
                    <br />
                    DE271733571
                  </p>
                  <h4>Dispute resolution proceedings in front of a consumer arbitration board</h4>
                  <p>
                    We are not willing or obliged to participate in dispute resolution proceedings in front of a consumer arbitration board.
                  </p>
                </div>
              ),
            },
          ]
        }
      />
      <div className={classes.imageText}>
        <h3>Credits</h3>
        <h4>
          All pictures, photos, illustrations and videos on this website are protected by copyright.
          The rights are held by the following companies and photographers:
        </h4>
        <ul className={classes.resourceList}>
          <li>Adobe Stock</li>
          <li>Unsplash</li>
          <li>Photographers:</li>
          <ul>
            <li>Thorsten Hartmann</li>
          </ul>
        </ul>
        <h4>For detailed information please click the more button.</h4>
        <button
          className={classes.button}
          type="button"
          onClick={() => setShowMore(!showMore)}
        >
          {!showMore ? 'LOAD MORE' : 'CLOSE'}
        </button>
      </div>
      <div className={showMore ? classes.imageBoxOpen : classes.imageBoxClose}>
        {showMore && (
          <div className={classes.imagesContainer}>
            {imageSources.data.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className={classes.imageSource} key={`image-${index + 1}`}>
                <p className={classes.itemTitle}>
                  <span>
                    {index + 1}
                    .
                  </span>
                  {item.mediaItem}
                </p>
                {item.reference && <p className={classes.subtitle}>{item.reference}</p>}
                {item.referenceLong && <div className={classes.containerReference}>{item.referenceLong.map((reference) => (<p>{reference}</p>))}</div>}
              </div>
            ))}
          </div>
        )}
      </div>
      <RedirectToTop />
      <UseScrollToTop scrollInstant />
    </div>
  );
};

export default Imprint;
