import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { makeStyles } from '@material-ui/core';
import { white, neusoftBlue, textColor } from '../../colors';
import { allReferenceCases } from './offeringContent';
import {
  POPUP_CASE_TITLE,
  POPUP_CASE_FIRST_BOX_SUBTITLE,
  POPUP_CASE_SECOND_BOX_SUBTITLE,
  POPUP_CASE_THIRD_BOX_SUBTITLE,
  POPUP_CASE_FOURTH_BOX_SUBTITLE,
  POPUP_CASE_FIFTH_BOX_SUBTITLE,
} from './Navigation/navigationItems';
import closePopUpIcon from '../../assets/images/icons/referenceCases/close-icon.svg';
import mapIcon from '../../assets/images/icons/referenceCases/icons8-map-marker.svg';
import settingsIcon from '../../assets/images/icons/referenceCases/icons8-open-end-wrench.svg';
import neusoftLogo from '../../assets/images/icons/referenceCases/neusoft-logo.svg';
import carIcon from '../../assets/images/icons/referenceCases/icons8-tesla-model-s.svg';

const useStyles = makeStyles(() => ({
  referenceCaseContainer: {
    width: '100%',
    position: 'fixed',
    zIndex: '2',
    top: '0',
    left: '0',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '15rem 1fr 10rem',
    overflow: 'hidden',
    overflowY: 'scroll',
    '@media (max-width:48em)': {
      gridTemplateRows: '8rem 1fr 1.5rem',
    },
    '@media (min-width:48em)': {
      gridTemplateRows: '15rem 1fr 10rem',
    },
    '@media (max-width:47.938em)': {
      gridTemplateRows: '8rem 1fr 1.5rem',
    },
    '@media (max-height:47em) and (orientation: landscape)': {
      gridTemplateRows: '6.5rem 1fr 1.5rem',
    },
  },
  referenceCaseShadow: {
    transform: 'scale(1.10)',
    position: 'fixed',
    width: '100%',
    height: '100%',
    gridColumnStart: '1',
    gridRowStart: '1',
    gridRowEnd: '4',
    filter: 'blur(15px) brightness(0.45)',
    background: 'rgba(255, 255, 255, 0.5) 0% 0% no-repeat',
  },
  referenceCaseBox: {
    width: '70%',
    height: 'auto',
    gridColumnStart: '1',
    gridRowStart: '2',
    gridRowEnd: '3',
    margin: '0 auto',
    '@media (max-width:48em)': {
      width: '100%',
    },
    '@media (min-width:48em) and (max-width:85em)': {
      width: '95%',
    },

    '@media (max-width:100em) and (orientation: landscape)': {
      width: '95%',
    },
    '@media (min-width:85em) and (max-width:100em)': {
      width: '85%',
    },
    '@media (min-width:150em)': {
      width: '60%',
    },
    '@media (max-height:47em) and (orientation: landscape)': {
      width: '96%',
    },
  },
  referenceCaseContent: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    margin: 'auto',
    background: white,
    boxShadow: '0px 10px 20px #00002ECC',
  },
  referenceCaseImageContainer: {
    position: 'relative',
    height: '28.563rem',
    width: '100%',
    '@media (max-width:67.5em)': {
      height: '22rem',
    },
    '@media (max-device-width:67.5em)': {
      height: '22rem',
    },
    '@media (max-width:47.938em)': {
      height: '10.95rem',
    },
    '@media (max-height:47em) and (orientation: landscape)': {
      height: '10.95rem',
    },
  },
  imageContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    '& img': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      filter: 'blur(10px)',
      transform: 'scale(1.10)',
    },
  },
  referenceCaseTopContainer: {
    height: '100%',
    width: '100%',
    background: '#00CCFF 0% 0% no-repeat',
    mixBlendMode: 'multiply',
  },
  referenceCaseTitleBox: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& h3': {
      color: white,
      '@media (min-width:48em) and (max-width:85em)': {
        fontSize: '1.6rem',
      },
    },
  },
  referenceCaseCloseIcon: {
    position: 'absolute',
    cursor: 'pointer',
    right: '2.875rem',
    top: '3.375rem',
    width: '1.9rem',
    height: '1.9rem',
    '& img': {
      width: '100%',
      height: '100%',
    },
    '@media (max-width:67.5em)': {
      width: '1.7rem',
      height: '1.7rem',
      top: '1.68rem',
      right: '1.68rem',
    },
    '@media (max-device-width:67.5em)': {
      width: '1.7rem',
      height: '1.7rem',
      top: '1.68rem',
      right: '1.68rem',
    },
    '@media (max-width:47.938em)': {
      top: '1.25rem',
      right: '1.25rem',
      width: '1.7rem',
      height: '1.7rem',
    },
    '@media (max-height:47em) and (orientation: landscape)': {
      top: '1.25rem',
      right: '1.25rem',
      width: '1.5rem',
      height: '1.5rem',
    },
  },
  subtitle: {
    fontStyle: 'italic',
    fontWeight: 'normal',
    marginTop: '1.15rem',
    '@media (max-width:67.5em)': {
      marginTop: '1.35rem',
    },
    '@media (max-width:47.938em)': {
      marginTop: '1.1rem',
      fontSize: '1.18rem',
    },
    '@media (max-height:475em) and (orientation: landscape)': {
      marginTop: '1.1rem',
      fontSize: '1.18rem',
    },
  },
  referenceCaseBottomContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    position: 'relative',
    '& p': {
      '@media (max-width:48em)': {
        textAlign: 'center',
        margin: '0 auto',
      },
      '@media (min-width:48em)': {
        textAlign: 'left',
      },
    },
  },
  referenceCaseBottomContainerDescription: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
    height: 'auto',
    '& p': {
      color: textColor,
      margin: '1rem 0',
      width: '100%',
      textAlign: 'center',
      '@media(max-width:85em) and (min-height:75em)': {
        width: '70%',
      },
      '@media (min-width:48em) and (max-width:85em)': {
        fontSize: '1.3rem',
        width: '90%',
      },
      '@media (max-width:47.938em)': {
        width: '95%',
        fontSize: '1.3rem',
      },
      '@media (max-height:47em) and (orientation: landscape)': {
        width: '95%',
      },
    },
  },
  referenceCaseTitle: {
    '& h3': {
      color: neusoftBlue,
      '@media (max-height:47em) and (orientation: landscape)': {
        fontSize: '1.35rem',
      },
      '@media (max-width:85em)': {
        fontSize: '1.6rem',
      },
      '@media (max-width:47.938em)': {
        fontSize: '1.35rem',
      },
    },
    marginTop: '2.25rem',
    marginBottom: '3.25rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width:67.5em)': {
      marginTop: '4rem',
      marginBottom: '1.8rem',
    },
    '@media (max-device-width:67.5em)': {
      marginTop: '4rem',
      marginBottom: '1.8rem',
    },
    '@media (max-width:47.938em)': {
      marginTop: '3.5rem',
      marginBottom: '2.2rem',
    },
  },
  sectionLine: {
    marginTop: '1.75rem',
    backgroundColor: neusoftBlue,
    width: '8rem',
    height: '0.25rem',
    zIndex: '1',
    '@media (max-width:85em)': {
      width: '100%',
    },
    '@media (max-width:67.5em)': {
      marginTop: '1.09rem',
    },
    '@media (max-device-width:67.5em)': {
      marginTop: '1.09rem',
    },
  },
  referenceCaseDescriptionText: {
    marginBottom: '2.5rem',
    width: '55%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      textAlign: 'center',
      fontWeight: 'bold',
      '@media (max-width:67.5em)': {
        fontSize: '1.3rem',
      },
      '@media (max-device-width:67.5em)': {
        fontSize: '1.3rem',
      },
      '@media (max-width:47.938em)': {
        fontSize: '1.18rem',
      },
      '@media (max-height:47em) and (orientation: landscape)': {
        fontSize: '1.18rem',
      },
    },
    '& ul': {
      paddingInlineStart: '0',
      textAlign: 'center',
      listStylePosition: 'inside',
      '& li': {
        '@media (max-width:67.5em)': {
          fontSize: '1.3rem',
        },
        '@media (max-device-width:67.5em)': {
          fontSize: '1.3rem',
        },
        '@media (max-width:47.938em)': {
          fontSize: '1.18rem',
        },
      },
    },
    '& p:nth-child(2)': {
      marginBottom: '0',
    },
    '@media (max-width:67.5em)': {
      width: '80%',
    },
    '@media (max-device-width:67.5em)': {
      width: '80%',
    },
    '@media (max-width:47.938em)': {
      width: '90%',
    },
  },
  referenceCaseBottomContainerContent: {
    height: '40.07rem',
    width: '100%',
    display: 'flex',
    '@media (max-width:67.5em)': {
      height: '43.25rem',
    },
    '@media (max-device-width:67.5em)': {
      height: '43.25rem',
    },
    '@media (max-width:48em)': {
      height: 'auto',
      flexDirection: 'column',
    },
    '@media (min-width:48em) and (max-width:67.5em)': {
      height: '43.25rem',
      flexDirection: 'row',
    },
    '@media (max-width:47.938em)': {
      height: 'auto',
      flexDirection: 'column',
    },
  },
  referenceCaseBottomContentBox: {
    flex: 1,
    order: '1',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    '& p': {
      color: textColor,
      width: '70%',
      marginLeft: '2.45rem',
      fontSize: '1.125rem',
      '@media(max-width:85em) and (min-height:75em)': {
        marginLeft: '2rem',
      },
      '@media (max-width:67.5em)': {
        fontSize: '1.15rem',
        marginLeft: '1.85rem',
        marginBottom: '1.6rem',
      },
      '@media (max-device-width:67.5em)': {
        fontSize: '1.3rem',
        marginLeft: '1.85rem',
        marginBottom: '1.6rem',
      },
      '@media (max-height:47em) and (orientation: landscape)': {
        fontSize: '1.15rem',

      },
    },
  },
  text: {
    color: neusoftBlue,
    marginTop: '2.5rem',
    marginLeft: '2.45rem',
    marginBottom: '2.45rem',
    '@media(max-width:85em) and (min-height:75em)': {
      marginLeft: '2rem',
    },
    '@media (max-height:47em) and (orientation: landscape)': {
      fontSize: '1.2rem',
      marginLeft: '1.2rem',
      marginTop: '1.2rem',
    },
    '@media (max-width:48em)': {
      fontSize: '1.5rem',
      marginBottom: '1.6rem',
      margin: '0',
      marginLeft: '0',
    },
    '@media (min-width:48em) and (max-width:67.5em)': {
      fontSize: '1.5rem',
      marginTop: '1.85rem',
      marginLeft: '1.85rem',
      marginBottom: '1.6rem',
    },
    '@media (min-device-width:80em) and (orientation: landscape)': {
      marginTop: '2.5rem',
      marginLeft: '2.45rem',
      marginBottom: '2.45rem',
      fontSize: '1.875rem',
    },
  },
  newText: {
    color: neusoftBlue,
    marginTop: '2.5rem',
    marginLeft: '2.45rem',
    marginBottom: '2.45rem',
    '@media(max-width:85em) and (min-height:75em)': {
      marginLeft: '2rem',
    },
    '@media (max-height:47em) and (orientation: landscape)': {
      fontSize: '1.2rem',
      marginLeft: '1.2rem',
      marginTop: '1.2rem',
    },
    '@media (max-width:48em)': {
      fontSize: '1.5rem',
      marginBottom: '1.6rem',
      margin: '0',
      marginLeft: '0',
    },
    '@media (max-width:67.49em)': {
      color: neusoftBlue,
      fontSize: '1.5rem',
      marginTop: '5.2rem',
      marginLeft: '1.85rem',
      marginBottom: '3rem',
    },
    '@media (max-device-width:67.49em)': {
      color: neusoftBlue,
      fontSize: '1.5rem',
      marginTop: '5.2rem',
      marginLeft: '1.85rem',
      marginBottom: '3rem',
    },
    '@media (min-width:48em) and (max-width:67.5em)': {
      fontSize: '1.5rem',
      marginTop: '1.85rem',
      marginLeft: '1.85rem',
      marginBottom: '1.6rem',
    },
    '@media (min-device-width:80em) and (orientation: landscape)': {
      marginLeft: '1.85rem',
      fontSize: '1.875rem',
      marginTop: '2.5rem',
    },
  },
  secondText: {
    color: neusoftBlue,
    marginTop: '2.5rem',
    marginLeft: '2.45rem',
    marginBottom: '2.45rem',
    '@media(max-width:85em) and (min-height:75em)': {
      marginLeft: '2rem',
    },
    '@media (max-height:47em) and (orientation: landscape)': {
      fontSize: '1.2rem',
      margin: '0',
      marginBottom: '1.6rem',
    },
    '@media (max-width:48em)': {
      fontSize: '1.5rem',
      margin: '0',
      marginBottom: '1.6rem',
    },
    '@media (min-width:48em) and (max-width:67.5em)': {
      fontSize: '1.5rem',
      marginTop: '1.85rem',
      marginLeft: '1.85rem',
      marginBottom: '1.6rem',
    },
  },
  referenceCaseBottomContentBoxMobile: {
    flex: 1,
    order: '0',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    '& h3': {
      color: neusoftBlue,
      margin: '2rem 0',
      marginLeft: '3rem',
      '@media (max-width:120em)': {
        fontSize: '1.5rem',
        marginLeft: '1.5rem',
        marginBottom: '1rem',
      },
      '@media(max-width:85em) and (min-height:75em)': {
        marginLeft: '2rem',
      },
    },
    '& p': {
      color: textColor,
      width: '70%',
      marginLeft: '3rem',
      '@media (max-width:120em)': {
        fontSize: '1.1rem',
        marginLeft: '1.5rem',
        width: '98%',
      },
      '@media(max-width:85em) and (min-height:75em)': {
        marginLeft: '2rem',
      },
      '@media (max-width:67.5em)': {
        fontSize: '1.3rem',
      },
      '@media (max-device-width:67.5em)': {
        fontSize: '1.3rem',
      },
    },
  },
  referenceBox: {
    width: '85%',
    marginLeft: '2.45rem',
    '@media(max-width:85em) and (min-height:75em)': {
      marginLeft: '2rem',
    },
    '@media (min-width:150em)': {
      width: '85%',
    },
    '@media (max-width:67.5em)': {
      marginLeft: '0',
      width: '95%',
    },
    '@media (max-device-width:67.5em)': {
      marginLeft: '0',
      width: '95%',
    },
    '& p': { margin: '0', width: '100%' },
  },

  referenceCaseContentBox: {
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(190, 223, 245, 0.39)',
    '& p': {
      width: '90%',
      '@media (max-width:48em)': {
        width: '85%',
        margin: '0 auto',
        marginBottom: '1rem',
      },
      '@media (min-width:48em)': {
        width: '90%',
        margin: '0 auto',
        marginLeft: '1.85rem',
      },
      '@media (min-width:67.5em)': {
        width: '90%',
        margin: '0 auto',
        marginLeft: '2.45rem',
      },
    },
    '@media (max-width:48em)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '16.9rem',
    },
    '@media (min-device-width:48em) and (max-device-width:67.5em)': {
      height: '43.25rem',
    },
    '@media (min-width:48em)': {
      flex: '1',
      height: '100%',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
  },
  referenceCaseContentBoxSecond: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(162,190,224,0.39)',
    '& p': {
      width: '90%',
      '@media (max-height:30em) and (orientation: landscape)': {
        width: '80%',
      },
    },
    '@media (max-width:48em)': {
      height: '16.9rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '@media (min-width:48em)': {
      flex: '1',
      height: '100%',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
  },
  referenceCaseContentBoxSecondDesign: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(162,190,224,0.39)',
    '@media (max-width:48em)': {
      height: '16.9rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& h3': {
        marginLeft: '0',
      },
    },
    '@media (min-width:48em)': {
      flex: '1',
      height: '100%',
      alignItems: 'flex-start',
      '& h3': {
        marginLeft: '2.45rem',
      },
    },
    '@media (max-width:67.5em)': {
      '& h3': {
        marginLeft: '0.42rem',
      },
      '& p': {
        marginLeft: '0.4rem',
        width: '95%',
      },
    },
    '@media (max-device-width:67.5em)': {
      '& h3': {
        marginLeft: '0.42rem',
      },
      '& p': {
        marginLeft: '0.4rem',
        width: '95%',
      },
    },
    '@media(max-width:85em) and (min-height:75em)': {
      '& h3': {
        marginLeft: '2rem',
      },
      '& p': {
        marginLeft: '2rem',
        width: '80%',
      },
    },
  },
  referenceCaseContentBoxThird: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
    backgroundColor: neusoftBlue,
    '& h3': {
      color: white,
      marginTop: '33%',
      marginBottom: '3rem',
      marginLeft: '2.875rem',
      fontSize: '1.9rem',
      '@media (max-width:85em)': {
        marginTop: '60%',
        marginLeft: '2rem',
      },
      '@media(max-width:85em) and (min-height:75em)': {
        marginTop: '40%',
        marginLeft: '2rem',
      },
      '@media (max-width:48em)': {
        marginTop: '4rem',
        fontSize: '1.5rem',
        marginLeft: '0',
        textAlign: 'center',
      },
      '@media (min-width:48em) and (max-width:67.5em)': {
        marginTop: '45%',
        fontSize: '1.5rem',
        marginLeft: '2.875rem',
        textAlign: 'left',
      },
      '@media (min-device-width:48em) and (max-device-width:67.5em)': {
        marginTop: '45%',
        fontSize: '1.5rem',
        marginLeft: '2.875rem',
        textAlign: 'left',
      },
      '@media (max-width:30em)': {
        textAlign: 'center',
        margin: '0',
        marginTop: '4.1rem',
        marginBottom: '2rem',
        fontSize: '1.35rem',
      },
    },
    '& h4': {
      color: white,
      fontStyle: 'italic',
      fontWeight: 'normal',
      width: '70%',
      marginLeft: '2.875rem',
      '@media (max-width:85em)': {
        marginLeft: '1.5rem',
      },
      '@media (max-width:48em)': {
        width: '80%',
        fontSize: '1.2rem',
        textAlign: 'center',
        margin: '0 auto',
        marginTop: '0',
        marginBottom: '5.5rem',
      },
      '@media (min-width:48em) and (max-width:67.5em)': {
        fontSize: '1.5rem',
        width: '70%',
        margin: '0 auto',
        textAlign: 'left',
        marginLeft: '1.5rem',
      },
      '@media (min-device-width:48em) and (max-device-width:67.5em)': {
        fontSize: '1.5rem',
        width: '70%',
        margin: '0 auto',
        textAlign: 'left',
        marginLeft: '1.5rem',
      },
    },
    '@media (max-width:48em)': {
      height: 'auto',
    },
    '@media (min-width:48em)': {
      height: '100%',
    },
  },
  referenceCaseFirstModel: {
    margin: '0 auto',
    paddingTop: '0.5rem',
    width: '96%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '@media (max-width:48em)': {
      width: 'auto',
      marginTop: '2rem',
      justifyContent: 'center',
    },
    '@media (min-width:48em) and (max-width:67.5em)': {
      width: '97%',
      margin: '0 auto',
      justifyContent: 'flex-start',
    },
    '@media (min-device-width:48em) and (max-device-width:67.5em)': {
      width: '97%',
      margin: '0 auto',
      justifyContent: 'flex-start',
    },
    '@media (max-width:67.5em) and (orientation:landscape)': {
      width: '93%',
    },
    '@media (max-device-width:67.5em) and (orientation:landscape)': {
      width: '93%',
    },
    '@media (max-device-height:47em) and (orientation: landscape)': {
      width: '99%',
    },
    '& p': {
      margin: '0',
      marginTop: '0.5rem',
      width: '5rem',
      textAlign: 'center',
      '@media (max-width:48em)': {
        width: '5.2rem',
      },
      '@media (min-width:48em) and (max-width:67.5em)': {
        fontSize: '1.2rem',
        width: '3.8rem',
      },
      '@media (min-device-width:48em) and (max-device-width:67.5em)': {
        fontSize: '1.2rem',
        width: '3.8rem',
      },
      '@media (max-device-height:47em) and (orientation: landscape)': {
        width: '2.8rem',
      },
    },
  },
  referenceCaseSecondModel: {
    margin: '0 auto',
    width: '93%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& p': {
      margin: '0',
      marginTop: '0.5rem',
      width: '5rem',
      textAlign: 'center',
      '@media (max-width:67.5em)': {
        fontSize: '1.2rem',
        width: '3.8rem',
      },
      '@media (max-device-width:67.5em)': {
        fontSize: '1.2rem',
        width: '3.8rem',
      },
    },
    '@media (max-width:48em)': {
      marginTop: '2rem',
      justifyContent: 'center',
    },
    '@media (min-width:48em) and (max-width:67.5em)': {
      justifyContent: 'flex-start',
      margin: '0 auto',
    },
    '@media (min-device-width:48em) and (max-device-width:67.5em)': {
      justifyContent: 'flex-start',
      margin: '0 auto',
    },
    '@media (max-width:30em)': {
      marginTop: '2rem',
      justifyContent: 'center',
    },
  },
  referenceCaseFirstModelContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& p': {
      height: '1.3rem',
      '@media (max-device-height:47em) and (orientation: landscape)': {
        height: '1rem',
        fontSize: '1rem',
      },
    },
  },
  referenceCaseLineBox: {
    height: '2rem',
  },
  referenceCaseLine: {
    width: '1rem',
    height: '0.10rem',
    backgroundColor: neusoftBlue,
    margin: '0 0.6rem',
    '@media (max-width:67.5em)': {
      margin: '0 0.3rem',
    },
    '@media (max-device-width:67.5em)': {
      margin: '0 0.3rem',
    },
  },
  referenceCaseLineSecond: {
    width: '1rem',
    height: '0.10rem',
    backgroundColor: neusoftBlue,
    margin: '0 1rem',
  },
  referenceCaseSecondModelContent: {
    height: '4rem',
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  referenceCaseThirdModel: {
    margin: '0 auto',
    marginTop: '1.5rem',
    width: '90%',
    '& img': {
      height: '100%',
      width: '100%',
    },
  },
  referenceCaseMapIcon: {
    height: 'auto',
    '@media (max-width:100em)': {
      height: '1.5rem',
    },
    '@media (max-width:30em)': {
      height: '2.1rem',
    },
  },
  referenceCaseLogoIcon: {
    height: 'auto',
    margin: '0 0.9rem',
    '@media (max-width:100em)': {
      height: '1rem',
    },
  },
  referenceCaseSettingsIcon: {
    height: 'auto',
    '@media (max-width:100em)': {
      height: '1.5rem',
    },
    '@media (max-width:30em)': {
      height: '2.1rem',
    },
  },
  referenceCaseCarIcon: {
    height: 'auto',
    '@media (max-width:100em)': {
      height: '1.5rem',
    },
    '@media (max-width:30em)': {
      height: '1.9rem',
    },
  },
  referenceCaseImage: {
    height: '2.5rem',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width:67.5em)': {
      height: '1.8rem',
    },
    '@media (max-device-width:67.5em)': {
      height: '1.8rem',
    },
  },
  referenceCasePopUpContainer: {
    display: 'none',
    '@media (min-width:48em)': {
      display: 'flex',
      width: '100%',
    },
  },
  referenceCasePopUpContainerSecond: {
    display: 'block',
    '@media (min-width:48em)': {
      display: 'none',
    },
  },
}));

const ReferenceCasePopUp = ({
  desktopLayout,
  pathCase,
  ...props
}) => {
  const classes = useStyles(props);
  const history = useHistory();
  const shownReferenceCase = allReferenceCases.find(({ pathName }) => pathName === pathCase);
  const exitReferenceCase = () => {
    document.body.style.overflow = 'auto';
    history.goBack();
  };

  useEffect(() => () => {
    document.body.style.overflow = 'auto';
  }, []);

  return (
    <div className={classes.referenceCaseContainer}>
      <div
        role="button"
        aria-label="button"
        tabIndex="0"
        className={classes.referenceCaseShadow}
        onKeyDown={exitReferenceCase}
        onClick={exitReferenceCase}
      />
      <div className={classes.referenceCaseBox}>
        <div className={classes.referenceCaseContent}>
          <div className={classes.referenceCaseImageContainer}>
            <div className={classes.imageContainer}>
              <>
                <img src={shownReferenceCase.image} alt="" />
              </>
              <div className={classes.referenceCaseTopContainer} />
            </div>
            <div className={classes.referenceCaseTitleBox}>
              <h3>{shownReferenceCase.title}</h3>
              <h3 className={classes.subtitle}>{shownReferenceCase.subtitle}</h3>
            </div>
            <div
              role="button"
              aria-label="button"
              tabIndex="0"
              onKeyDown={exitReferenceCase}
              onClick={exitReferenceCase}
              className={classes.referenceCaseCloseIcon}
            >
              <img src={closePopUpIcon} alt="" />
            </div>
          </div>
          <div className={classes.referenceCaseBottomContainer}>
            <div className={classes.referenceCaseBottomContainerDescription}>
              <div className={classes.referenceCaseTitle}>
                <h3>{POPUP_CASE_TITLE}</h3>
                <div className={classes.sectionLine} />
              </div>
              <div className={classes.referenceCaseDescriptionText}>
                {ReactHtmlParser(shownReferenceCase.popUpText)}
              </div>
            </div>
            <div className={classes.referenceCaseBottomContainerContent}>
              <div className={classes.referenceCasePopUpContainer}>
                <div className={classes.referenceCaseBottomContentBox}>
                  <div className={classes.referenceCaseContentBoxSecond}>
                    <h3 className={classes.text}>{POPUP_CASE_FIRST_BOX_SUBTITLE}</h3>
                    <p>
                      {ReactHtmlParser(shownReferenceCase.popUpsubcaseFirst)}
                    </p>
                  </div>
                  <div className={classes.referenceCaseContentBox}>
                    <h3 className={classes.newText}>{POPUP_CASE_SECOND_BOX_SUBTITLE}</h3>
                    <div className={classes.referenceCaseFirstModel}>
                      {shownReferenceCase.popUpsubcaseModelFirst && (
                        <>
                          <div className={classes.referenceCaseFirstModelContent}>
                            <div className={classes.referenceCaseImage}><img className={classes.referenceCaseMapIcon} src={mapIcon} alt="" /></div>
                            <p>Map Data Provider</p>
                          </div>
                          <div className={classes.referenceCaseLineBox}>
                            <div className={classes.referenceCaseLine} />
                          </div>
                          <div className={classes.referenceCaseFirstModelContent}>
                            <div className={classes.referenceCaseImage}>
                              <img className={classes.referenceCaseLogoIcon} src={neusoftLogo} alt="" />
                            </div>
                            <p>Tier 2</p>
                          </div>
                          <div className={classes.referenceCaseLineBox}>
                            <div className={classes.referenceCaseLine} />
                          </div>
                          <div className={classes.referenceCaseFirstModelContent}>
                            <div className={classes.referenceCaseImage}>
                              <img className={classes.referenceCaseSettingsIcon} src={settingsIcon} alt="" />
                            </div>
                            <p className={classes.referenceSubtitle}>Tier 1</p>
                          </div>
                          <div className={classes.referenceCaseLineBox}>
                            <div className={classes.referenceCaseLine} />
                          </div>
                          <div className={classes.referenceCaseFirstModelContent}>
                            <div className={classes.referenceCaseImage}>
                              <img className={classes.referenceCaseCarIcon} src={carIcon} alt="" />
                            </div>
                            <p>OEM</p>
                          </div>
                        </>
                      )}
                    </div>
                    <div className={classes.referenceCaseSecondModel}>
                      {shownReferenceCase.popUpsubcaseModelSecond && (
                        <>
                          <div className={classes.referenceCaseFirstModelContent}>
                            <div className={classes.referenceCaseImage}>
                              <img className={classes.referenceCaseLogoIcon} src={neusoftLogo} alt="" />
                            </div>
                            <p>Tier 1</p>
                          </div>
                          <div className={classes.referenceCaseLineBox}>
                            <div className={classes.referenceCaseLine} />
                          </div>
                          <div className={classes.referenceCaseFirstModelContent}>
                            <div className={classes.referenceCaseImage}>
                              <img className={classes.referenceCaseCarIcon} src={carIcon} alt="" />
                            </div>
                            <p>OEM</p>
                          </div>
                        </>
                      )}
                      {shownReferenceCase.popUpsubcaseModelThird && (
                        <>
                          <div className={classes.referenceCaseFirstModelContent}>
                            <div className={classes.referenceCaseImage}>
                              <img className={classes.referenceCaseLogoIcon} src={neusoftLogo} alt="" />
                            </div>
                            <p>Tier 2</p>
                          </div>
                          <div className={classes.referenceCaseLineBox}>
                            <div className={classes.referenceCaseLine} />
                          </div>
                          <div className={classes.referenceCaseFirstModelContent}>
                            <div className={classes.referenceCaseImage}>
                              <img className={classes.referenceCaseSettingsIcon} src={settingsIcon} alt="" />
                            </div>
                            <p className={classes.referenceSubtitle}>Tier 1</p>
                          </div>
                          <div className={classes.referenceCaseLineBox}>
                            <div className={classes.referenceCaseLine} />
                          </div>
                          <div className={classes.referenceCaseFirstModelContent}>
                            <div className={classes.referenceCaseImage}>
                              <img className={classes.referenceCaseCarIcon} src={carIcon} alt="" />
                            </div>
                            <p>OEM</p>
                          </div>
                        </>
                      )}
                    </div>
                    <div className={classes.referenceCaseFirstModel}>
                      {shownReferenceCase.popUpsubcaseModelFourth && (
                        <>
                          <div className={classes.referenceCaseFirstModelContent}>
                            <div className={classes.referenceCaseImage}>
                              <img className={classes.referenceCaseLogoIcon} src={neusoftLogo} alt="" />
                            </div>
                            <p>Tier 3</p>
                          </div>
                          <div className={classes.referenceCaseLineBox}>
                            <div className={classes.referenceCaseLine} />
                          </div>
                          <div className={classes.referenceCaseFirstModelContent}>
                            <div className={classes.referenceCaseImage}>
                              <img className={classes.referenceCaseSettingsIcon} src={settingsIcon} alt="" />
                            </div>
                            <p className={classes.referenceSubtitle}>Tier 2</p>
                          </div>
                          <div className={classes.referenceCaseLineBox}>
                            <div className={classes.referenceCaseLine} />
                          </div>
                          <div className={classes.referenceCaseFirstModelContent}>
                            <div className={classes.referenceCaseImage}>
                              <img className={classes.referenceCaseSettingsIcon} src={settingsIcon} alt="" />
                            </div>
                            <p className={classes.referenceSubtitle}>Tier 1</p>
                          </div>
                          <div className={classes.referenceCaseLineBox}>
                            <div className={classes.referenceCaseLine} />
                          </div>
                          <div className={classes.referenceCaseFirstModelContent}>
                            <div className={classes.referenceCaseImage}>
                              <img className={classes.referenceCaseCarIcon} src={carIcon} alt="" />
                            </div>
                            <p>OEM</p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.referenceCaseBottomContentBox}>
                  <div className={classes.referenceCaseContentBox}>
                    <h3 className={classes.text}>{POPUP_CASE_THIRD_BOX_SUBTITLE}</h3>
                    <p>
                      {ReactHtmlParser(shownReferenceCase.popUpsubcaseThird)}
                    </p>
                  </div>
                  <div className={classes.referenceCaseContentBoxSecondDesign}>
                    <h3 className={classes.text}>{POPUP_CASE_FOURTH_BOX_SUBTITLE}</h3>
                    <div className={classes.referenceBox}>
                      <p>{ReactHtmlParser(shownReferenceCase.popUpsubcaseFourth)}</p>
                    </div>
                  </div>
                </div>
                <div className={classes.referenceCaseBottomContentBox}>
                  <div className={classes.referenceCaseContentBoxThird}>
                    <h3 className={classes.text}>{POPUP_CASE_FIFTH_BOX_SUBTITLE}</h3>
                    <h4>
                      {shownReferenceCase.popUpsubcaseFifth}
                    </h4>
                  </div>
                </div>
              </div>

              <div className={classes.referenceCasePopUpContainerSecond}>
                <div className={classes.referenceCaseContentBoxThird}>
                  <h3 className={classes.text}>{POPUP_CASE_FIFTH_BOX_SUBTITLE}</h3>
                  <h4>
                    {shownReferenceCase.popUpsubcaseFifth}
                  </h4>
                </div>
                <div className={classes.referenceCaseContentBoxSecond}>
                  <h3 className={classes.text}>{POPUP_CASE_FIRST_BOX_SUBTITLE}</h3>
                  <p>
                    {ReactHtmlParser(shownReferenceCase.popUpsubcaseFirst)}
                  </p>
                </div>
                <div className={classes.referenceCaseContentBox}>
                  <h3 className={classes.secondText}>{POPUP_CASE_THIRD_BOX_SUBTITLE}</h3>
                  <p>
                    {ReactHtmlParser(shownReferenceCase.popUpsubcaseThird)}
                  </p>
                </div>
                <div className={classes.referenceCaseContentBoxSecondDesign}>
                  <h3 className={classes.text}>{POPUP_CASE_SECOND_BOX_SUBTITLE}</h3>
                  {shownReferenceCase.popUpsubcaseModelFirst && (
                    <div className={classes.referenceCaseFirstModel}>
                      <div className={classes.referenceCaseFirstModelContent}>
                        <div className={classes.referenceCaseImage}><img className={classes.referenceCaseMapIcon} src={mapIcon} alt="" /></div>
                        <p>Map Data Provider</p>
                      </div>
                      <div className={classes.referenceCaseLineBox}>
                        <div className={classes.referenceCaseLine} />
                      </div>
                      <div className={classes.referenceCaseFirstModelContent}>
                        <div className={classes.referenceCaseImage}>
                          <img className={classes.referenceCaseLogoIcon} src={neusoftLogo} alt="" />
                        </div>
                        <p>Tier 2</p>
                      </div>
                      <div className={classes.referenceCaseLineBox}>
                        <div className={classes.referenceCaseLine} />
                      </div>
                      <div className={classes.referenceCaseFirstModelContent}>
                        <div className={classes.referenceCaseImage}>
                          <img className={classes.referenceCaseSettingsIcon} src={settingsIcon} alt="" />
                        </div>
                        <p className={classes.referenceSubtitle}>Tier 1</p>
                      </div>
                      <div className={classes.referenceCaseLineBox}>
                        <div className={classes.referenceCaseLine} />
                      </div>
                      <div className={classes.referenceCaseFirstModelContent}>
                        <div className={classes.referenceCaseImage}>
                          <img className={classes.referenceCaseCarIcon} src={carIcon} alt="" />
                        </div>
                        <p>OEM</p>
                      </div>
                    </div>
                  )}
                  <div className={classes.referenceCaseSecondModel}>
                    {shownReferenceCase.popUpsubcaseModelSecond && (
                      <>
                        <div className={classes.referenceCaseFirstModelContent}>
                          <div className={classes.referenceCaseImage}>
                            <img className={classes.referenceCaseLogoIcon} src={neusoftLogo} alt="" />
                          </div>
                          <p>Tier 1</p>
                        </div>
                        <div className={classes.referenceCaseLineBox}>
                          <div className={classes.referenceCaseLine} />
                        </div>
                        <div className={classes.referenceCaseFirstModelContent}>
                          <div className={classes.referenceCaseImage}>
                            <img className={classes.referenceCaseCarIcon} src={carIcon} alt="" />
                          </div>
                          <p>OEM</p>
                        </div>
                      </>
                    )}
                    {shownReferenceCase.popUpsubcaseModelThird && (
                      <>
                        <div className={classes.referenceCaseFirstModelContent}>
                          <div className={classes.referenceCaseImage}>
                            <img className={classes.referenceCaseLogoIcon} src={neusoftLogo} alt="" />
                          </div>
                          <p>Tier 2</p>
                        </div>
                        <div className={classes.referenceCaseLineBox}>
                          <div className={classes.referenceCaseLine} />
                        </div>
                        <div className={classes.referenceCaseFirstModelContent}>
                          <div className={classes.referenceCaseImage}>
                            <img className={classes.referenceCaseSettingsIcon} src={settingsIcon} alt="" />
                          </div>
                          <p className={classes.referenceSubtitle}>Tier 1</p>
                        </div>
                        <div className={classes.referenceCaseLineBox}>
                          <div className={classes.referenceCaseLine} />
                        </div>
                        <div className={classes.referenceCaseFirstModelContent}>
                          <div className={classes.referenceCaseImage}>
                            <img className={classes.referenceCaseCarIcon} src={carIcon} alt="" />
                          </div>
                          <p>OEM</p>
                        </div>
                      </>
                    )}
                  </div>
                  {shownReferenceCase.popUpsubcaseModelFourth && (
                    <div className={classes.referenceCaseFirstModel}>
                      <div className={classes.referenceCaseFirstModelContent}>
                        <div className={classes.referenceCaseImage}>
                          <img className={classes.referenceCaseLogoIcon} src={neusoftLogo} alt="" />
                        </div>
                        <p>Tier 2</p>
                      </div>
                      <div className={classes.referenceCaseLineBox}>
                        <div className={classes.referenceCaseLine} />
                      </div>
                      <div className={classes.referenceCaseFirstModelContent}>
                        <div className={classes.referenceCaseImage}>
                          <img className={classes.referenceCaseSettingsIcon} src={settingsIcon} alt="" />
                        </div>
                        <p className={classes.referenceSubtitle}>Tier 1</p>
                      </div>
                      <div className={classes.referenceCaseLineBox}>
                        <div className={classes.referenceCaseLine} />
                      </div>
                      <div className={classes.referenceCaseFirstModelContent}>
                        <div className={classes.referenceCaseImage}>
                          <img className={classes.referenceCaseSettingsIcon} src={settingsIcon} alt="" />
                        </div>
                        <p className={classes.referenceSubtitle}>Tier 1</p>
                      </div>
                      <div className={classes.referenceCaseLineBox}>
                        <div className={classes.referenceCaseLine} />
                      </div>
                      <div className={classes.referenceCaseFirstModelContent}>
                        <div className={classes.referenceCaseImage}>
                          <img className={classes.referenceCaseCarIcon} src={carIcon} alt="" />
                        </div>
                        <p>OEM</p>
                      </div>
                    </div>
                  )}
                </div>
                <div className={classes.referenceCaseContentBox}>
                  <h3 className={classes.secondText}>{POPUP_CASE_FOURTH_BOX_SUBTITLE}</h3>
                  <div className={classes.referenceBox}>
                    <p>{ReactHtmlParser(shownReferenceCase.popUpsubcaseFourth)}</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default ReferenceCasePopUp;
