/* eslint-disable object-curly-newline */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { neusoftBlue, white, textColor } from '../../colors';

const useStyles = makeStyles(() => ({
  contactContainer: {
    backgroundColor: white,
    height: '45.438rem',
    width: '44.125rem',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0rem 5rem 0rem 0rem',
    boxShadow: '0px 10px 20px #00002ED1',
    '@media(max-device-width:50em)': {
      height: '45rem',
      width: '43.6rem',
    },
    '@media(max-width:47.938em)': {
      width: '30rem',
      height: '30.5rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      width: '32.3rem',
      height: '35.3rem',
    },
    '&:hover': {
      boxShadow: '0px 20px 30px #00002ECC',
    },
  },
  contactLink: {
    '& :hover': {
      '& path': {
        fill: neusoftBlue,
      },
      '& #contactImage': {
        border: '1px solid #005BAC',
      },

    },
  },
  contactTopContainer: {
    position: 'relative',
    height: '60.5%',
    '@media(max-width:67.5em)': {
      height: '62.3%',
    },
    '@media(max-device-width:67.5em)': {
      height: '62.3%',
    },
    '@media(max-width:47.938em)': {
      height: '60.5%',
    },
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  contactIconContainer: {
    height: '1.938rem',
    width: '1.938rem',
    position: 'absolute',
    left: '0',
    margin: '1.875rem',
    '@media(max-width:50em)': {
      height: '2.2rem',
      width: '2.2rem',
    },
    '@media(max-width:47.938em)': {
      height: '1.7rem',
      width: '1.7rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      height: '1.7rem',
      width: '1.7rem',
    },
  },
  contactIcon: { height: '100%', width: '100%' },
  contactImageContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '60%',
    margin: '0 auto',
    height: '93%',
    border: '1px solid #707070',
    '@media(max-width:67.5em)': {
      width: '65%',
      height: '100%',
    },
    '@media(max-device-width:67.5em)': {
      width: '65%',
      height: '100%',
    },
    '@media(max-width:47.938em)': {
      width: '60%',
      height: '93%',
    },
  },
  contactImage: {
    width: '87%',
    height: '87%',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: { height: '100%' },
  contactDescription: {
    height: 'auto',
    width: '60%',
    margin: '0 auto',
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& p': {
      textAlign: 'center',
      color: textColor,
      fontStyle: 'italic',
      '@media(max-width:67.5em)': {
        fontSize: '1.18rem',
        opacity: '0.61',
      },
      '@media(max-device-width:67.5em)': {
        fontSize: '1.18rem',
        opacity: '0.61',
      },
      '@media(max-width:47.938em)': {
        fontSize: '1rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1rem',
        opacity: '0.61',
      },
    },
    '@media(max-width:50em)': {
      width: '70%',
    },
    '@media(max-width:47.938em)': {
      width: '80%',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      width: '80%',
    },
  },
  contactDescriptionTop: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1rem',
    '& h3': {
      marginBottom: '0.6rem',
      color: neusoftBlue,
      textAlign: 'center',
      '@media(min-width:48em) and (max-width:67.5em)': {
        fontSize: '2rem',
        marginTop: '0.7rem',
      },
      '@media(min-device-width:48em) and (max-device-width:67.5em)': {
        fontSize: '1.8rem',
        marginTop: '0.7rem',
      },
      '@media(max-width:47.938em)': {
        marginBottom: '0.2rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.4rem',
      },
    },
    '& h4': {
      marginBottom: '0.6rem',
      color: textColor,
      fontWeight: 'normal',
      textAlign: 'center',
      '@media(max-width:67.5em)': {
        fontSize: '1.5rem',
      },
      '@media(max-device-width:67.5em)': {
        fontSize: '1.35rem',
      },
      '@media(max-width:47.938em)': {
        fontSize: '1.15rem',
        marginBottom: '0.3rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.2rem',
      },
    },
    '@media(max-width:47.938em)': {
      marginBottom: '0',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      marginBottom: '0',
    },
  },
}));

const ContactComponent = ({
  contactImage,
  contactTitle,
  contactSubtitle,
  contactText,
  linkedInLink,
  ...props
}) => {
  const classes = useStyles(props);

  return (
    <a
      className={classes.contactLink}
      href={linkedInLink}
      target="_blank"
      rel="noreferrer"
    >
      <div className={classes.contactContainer}>
        <div className={classes.contactTopContainer}>
          <div className={classes.contactIconContainer}>
            <svg
              className={classes.contactIcon}
              xmlns="http://www.w3.org/2000/svg"
              width="31.5"
              height="31.499"
              viewBox="0 0 31.5 31.499"
              id="contactIcon"
            >
              <path
                className={classes.contactIconPath}
                id="Icon_awesome-linkedin-in"
                data-name="Icon awesome-linkedin-in"
                d="M7.051,31.5H.52V10.47H7.051ZM3.782,7.6A3.8,3.8,0,1,1,7.564,3.783,3.814,3.814,0,0,1,3.782,7.6ZM31.493,31.5H24.976V21.263c0-2.44-.049-5.569-3.4-5.569-3.4,0-3.916,2.651-3.916,5.393V31.5H11.142V10.47h6.263v2.869H17.5a6.862,6.862,0,0,1,6.179-3.4c6.609,0,7.824,4.352,7.824,10.005V31.5Z"
                transform="translate(0 -0.001)"
                fill={textColor}
              />
            </svg>
          </div>
          <div className={classes.contactImageContainer} id="contactImage">
            <div className={classes.contactImage}>
              <img className={classes.image} src={contactImage} alt="" />
            </div>
          </div>
        </div>
        <div className={classes.contactDescription}>
          <div className={classes.contactDescriptionTop}>
            <h3>{contactTitle}</h3>
            <h4>{contactSubtitle}</h4>
          </div>
          <p>{contactText}</p>
        </div>
      </div>
    </a>
  );
};

export default ContactComponent;
