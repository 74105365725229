import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { textColor, white, neusoftBlue } from '../../colors';
import xingIcon from '../../assets/images/icons/footer/awesome-xing-square.svg';
import youtubeIcon from '../../assets/images/icons/footer/ionic-logo-youtube.svg';
import linkedinIcon from '../../assets/images/icons/footer/simple-linkedin.svg';
import globeIcon from '../../assets/images/icons/footer/feather-globe.svg';

const useStyles = makeStyles(() => ({
  footerContainer: {
    display: 'flex',
    width: '100%',
    height: 'auto',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  footerTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: neusoftBlue,
    height: 'auto',
    width: '100%',
  },
  footerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 'auto',
    width: '87%',
    margin: '1.25rem auto',
    '@media only screen and (min-device-width : 48em) and (max-device-width : 67.5em)':
      { width: '90%' },
    '@media(max-width:67.5em)': {
      width: '90%',
      margin: '2rem auto',
    },
    '@media(max-width:47.938em)': {
      margin: '1.35rem auto',
    },
    '@media only screen and (max-device-width : 67.5em) and (orientation: landscape)':
    {
      margin: '1.35rem auto',
    },
  },
  footerSocial: {
    height: '100%',
    width: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footerIconLink: {
    height: '100%',
  },
  footerIconBoxLink: {
    marginRight: '1.5rem',
    height: '2.25rem',
    '@media only screen and (min-device-width : 48em) and (max-device-width : 67.5em)':
      { height: '2.2rem' },
    '@media(max-width:48em)': {
      height: '2.5rem',
    },
    '@media(max-width:40em)': {
      height: '1.5rem',
    },
    '@media(min-width:150em)': {
      height: '2.3rem',
    },
    '@media only screen and (max-height : 43.75em) and (min-device-width : 70em) and (orientation: landscape)':
    {
      height: '1.9rem',
    },
    '@media only screen and (max-device-width : 67.5em) and (orientation: landscape)':
    {
      height: '1.9rem',
    },
    '@media(max-device-width:40.625em)': {
      height: '1.8rem',
    },
  },
  footerBottom: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: textColor,
    height: 'auto',
    width: '100%',
  },
  footerBox: {
    display: 'flex',
    width: '87%',
    margin: '1.4rem auto',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: textColor,
    height: '100%',
    '@media(max-width:67.5em)': {
      width: '90%',
      margin: '1.4rem auto',
    },
    '@media(max-device-width:67.5em)': {
      width: '90%',
      margin: '1.4rem auto',
    },
    '@media(max-width:50em)': {
      height: 'auto',
      margin: '1.6rem auto',
    },
    '@media(max-width:47.938em)': {
      margin: '1.28rem auto',
    },
    '@media only screen and (max-device-width : 67.5em) and (orientation: landscape)':
    {
      margin: '1.28rem auto',
    },
  },
  footerLink: {
    fontSize: '1.125rem',
    textDecoration: 'none',
    color: white,
    padding: '0',
    fontWeight: '500',
    '@media only screen and (min-device-width : 48em) and (max-device-width : 67.5em) and (orientation: portrait)':
    {
      fontSize: '1.35rem',
    },
    '@media only screen and (max-device-width : 48em) and (orientation: portrait)':
    {
      fontSize: '1.55rem',
    },
    '@media(max-width:47.938em)': { fontSize: '1.1rem' },
    '@media only screen and (max-device-width : 67.5em) and (orientation: landscape)':
    {
      fontSize: '1.1rem',
    },
  },
  footerLine: {
    width: '0.15rem',
    height: '1.125rem',
    margin: '0 2rem',
    '@media(max-width:50em)': {
      height: '1.2rem',
    },
    backgroundColor: white,
  },
  copyright: {
    color: white,
    fontWeight: 'normal',
    '@media only screen and (max-device-width : 67.5em) and (orientation: landscape)':
    {
      fontSize: '1.6rem',
    },
    '@media only screen and (min-device-width : 48em) and (max-device-width : 64em) and (orientation: portrait)':
    {
      fontSize: '1.9rem',
    },
    '@media only screen and (max-device-width : 48em) and (orientation: portrait)':
    {
      fontSize: '1.8rem',
    },
    '@media(max-width:47.938em)': {
      fontSize: '1.2rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      fontSize: '1.5rem',
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.footerContainer}>
      <div className={classes.footerTop}>
        <div className={classes.footerContent}>
          <div>
            <h4 className={classes.copyright}>Neusoft © 2023</h4>
          </div>
          <div className={classes.footerSocial}>
            <a
              href="https://www.neusoft.com/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.footerIconBoxLink}
            >
              <img className={classes.footerIconLink} src={globeIcon} alt="" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCkL4-MnIww7contOY8al9CA"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.footerIconBoxLink}
            >
              <img
                className={classes.footerIconLink}
                src={youtubeIcon}
                alt=""
              />
            </a>
            <a
              href="https://www.linkedin.com/company/neusoft-technology-solutions-gmbh/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.footerIconBoxLink}
            >
              <img
                className={classes.footerIconLink}
                src={linkedinIcon}
                alt=""
              />
            </a>
            <a
              href="https://www.xing.com/pages/neusofttechnologysolutionsgmbh"
              className={classes.footerIconBoxLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className={classes.footerIconLink} src={xingIcon} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className={classes.footerBottom}>
        <div className={classes.footerBox}>
          <Link className={classes.footerLink} to="/imprint">
            IMPRINT
          </Link>
          <div className={classes.footerLine} />
          <Link className={classes.footerLink} to="/data-protection">
            DATA PROTECTION
          </Link>
          <div className={classes.footerLine} />
          <Link className={classes.footerLink} to="/gdpr-disc">
            GDPR DISCLAIMER
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
