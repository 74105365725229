import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

import { Navigation } from 'swiper';
import 'swiper/swiper.scss';
import '../../global/swiperNavigation.scss';
import { neusoftBlue, white } from '../../colors';
import ReferenceCaseComponent from './ReferenceCaseComponent';
import FocusAreaComponent from './FocusAreaComponent';
import ContactComponent from './ContactComponent';

const useStyles = makeStyles(() => ({
  offeringSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: (props) => props.justifyContentSection,
    height: 'auto',
    backgroundColor: (props) => props.backgroundColor,
    '@media(max-width:67.5em)': {
      backgroundColor: (props) => props.backgroundColorTablet,
    },
    '@media(max-device-width:67.5em)': {
      backgroundColor: (props) => props.backgroundColorTablet,
    },
  },
  offeringSectionDisplayNone: {
    display: 'none',
  },
  focusAreaContainerDesktop: {
    height: '55rem',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    '@media(max-width:112.5em)': {
      height: '55rem',
    },
    '@media(max-width:67.5em)': {
      height: '58rem',
    },
    '@media (max-width:47.938em)': {
      height: '48rem',
    },
    '@media(max-device-width:67.5em)': {
      height: '67rem',
    },
    '@media (max-device-width:67.5em) and (orientation: landscape)': {
      marginTop: '1.7rem',
      height: '60rem',
    },
    '@media(max-device-width:40.625em)': {
      height: '43rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      height: '46rem',
    },
    '@media(max-width:87.5em)': {
      display: 'none',
    },
  },
  focusAreaContainerTablet: {
    height: '55rem',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    '@media(max-width:112.5em)': {
      height: '55rem',
    },
    '@media(max-width:67.5em)': {
      height: '60rem',
    },
    '@media (max-width:47.938em)': {
      height: '50rem',
    },
    '@media(max-device-width:67.5em)': {
      height: '69rem',
    },
    '@media (max-device-width:67.5em) and (orientation: landscape)': {
      marginTop: '1.7rem',
      height: '62rem',
    },
    '@media(max-device-width:40.625em)': {
      height: '48rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      height: '52rem',
    },
    '@media(min-width:87.5em)': {
      display: 'none',
    },
  },
  sectionTitle: {
    height: '5rem',
    margin: '4rem 0',
    marginBottom: '6rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& h3': {
      color: neusoftBlue,
      textAlign: 'center',
      '@media(max-device-width:67.5em)': {
        margin: '0',
        fontSize: '2.2rem',
      },
      '@media(max-width:47.938em)': {
        margin: '0',
        fontSize: '1.35rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.35rem',
      },
    },
    '@media(max-width:67.5em)': {
      marginBottom: '4rem',
    },
    '@media(max-device-width:67.5em)': {
      marginBottom: '4rem',
    },
    '@media(max-width:47.938em)': {
      marginTop: '3.6rem',
      marginBottom: '2rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      marginTop: '3rem',
      marginBottom: '2rem',
    },
  },
  sectionLine: {
    marginTop: '1.2rem',
    backgroundColor: neusoftBlue,
    width: '8rem',
    height: '0.25rem',
    zIndex: '1',
  },
  offeringSectionParagraph: {
    width: '75%',
    margin: '0 auto',
    '& h4': {
      fontWeight: 'normal',
      textAlign: 'center',
      '@media(max-width:67.5em)': {
        textAlign: 'left',
      },
      '@media(max-device-width:67.5em)': {
        textAlign: 'left',
        fontSize: '1.8rem',
      },
      '@media(max-width:47.938em)': {
        fontSize: '1.18rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.2rem',
      },
    },
    '@media(max-width:67.5em)': {
      width: '100%',
    },
  },
  offeringSectionContent: {
    width: (props) => props.offeringSectionWidthDesktop,
    height: 'auto',
    margin: '0 auto',
    marginBottom: '7rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media(max-width:112.5em)': {
      width: (props) => props.offeringSectionWidthSecond,
      marginBottom: (props) => props.marginBottom,
    },
    '@media(max-width:67.5em)': {
      width: (props) => props.offeringSectionWidthTablet,
      marginBottom: (props) => props.marginBottom,
    },
    '@media(max-device-width:67.5em)': {
      width: (props) => props.offeringSectionWidthTablet,
      marginBottom: (props) => props.marginBottom,
    },
    '@media only screen and (max-device-height : 43.75em) and (orientation: landscape)':
    {
      marginBottom: (props) => props.marginBottomMobile,
    },
    '@media(max-width:47.938em)': {
      width: (props) => props.offeringSectionWidthTablet,
      marginBottom: (props) => props.marginBottomMobile,
    },
    '@media(min-width:150em)': {
      width: (props) => props.offeringSectionWidthDesktopXL,
    },
  },
  offeringSectionContentLarge: {
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  referenceCasesSection: {
    width: '100%',
    height: 'auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(3,1fr)',
    gridTemplateRows: 'repeat(2,48%)',
    gridGap: '1rem 1rem',
    backgroundColor: white,
    '@media (max-width:67.5em)': {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: neusoftBlue,
      height: 'auto',
      paddingTop: '8.35rem',
      paddingBottom: '8.5rem',
    },
    '@media (max-device-width:67.5em)': {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: neusoftBlue,
      height: 'auto',
      paddingTop: '8.35rem',
      paddingBottom: '8.5rem',
    },
    '@media (min-device-width:48em) and (max-device-width:67.5em) and (orientation: landscape)': {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: neusoftBlue,
      height: 'auto',
      paddingTop: '4rem',
      paddingBottom: '4rem',
    },
    '@media (min-width:140em)': {
      width: '90%',
      gridGap: '1.5rem 1.5rem',
    },
    '@media (min-width:180em)': {
      width: '80%',
    },
    '@media (max-width:30em)': {
      paddingTop: '4rem',
      paddingBottom: '4.2rem',
    },
    '@media only screen and (max-device-height : 43.75em) and (orientation: landscape)':
    {
      paddingTop: '4rem',
      paddingBottom: '4.2rem',
    },
  },
}));

const OfferingSection = ({
  path,
  sectionTitle,
  firstSection,
  secondSection,
  thirdSection,
  fourthSection,
  paragraph,
  focusAreaContainerWidthDesktop,
  focusAreaContainerWidthTablet,
  focusAreaContainerWidthSecond,
  focusAreaContainerWidthDesktopXL,
  focusAreas,
  referenceCases,
  contactImage,
  contactTitle,
  contactSubtitle,
  contactText,
  linkedInLink,
  ...props
}) => {
  const classes = useStyles(props);

  return (
    <div className={fourthSection ? classes.offeringSectionDisplayNone : classes.offeringSection}>
      <div className={classes.sectionTitle}>
        <h3>{sectionTitle}</h3>
        <div className={classes.sectionLine} />
      </div>
      <div className={classes.offeringSectionContent}>
        {firstSection && (
          <div className={classes.offeringSectionParagraph}>
            <h4>{paragraph}</h4>
          </div>
        )}
        {secondSection && (
          <>
            <div className={classes.focusAreaContainerDesktop}>
              {focusAreas.length > '4' ? (
                <Swiper
                  modules={[Navigation]}
                  centeredSlides
                  slidesPerView="auto"
                  spaceBetween={18}
                  grabCursor
                  breakpoints={{
                    1400: { slidesPerView: '3.5', spaceBetween: 24 },
                    1600: { slidesPerView: '3.8', spaceBetween: 24 },
                    1800: { slidesPerView: '4.56', spaceBetween: 24 },
                    2200: { slidesPerView: '5.5', spaceBetween: 24 },
                  }}
                  loop
                  navigation
                >
                  {focusAreas.map((focusArea) => (
                    <SwiperSlide key={focusArea.focusAreaTitle}>
                      <FocusAreaComponent
                        focusAreaContainerWidth={
                          focusAreaContainerWidthDesktop
                        }
                        focusAreaImage={focusArea.focusAreaImage}
                        focusAreaTitle={focusArea.focusAreaTitle}
                        focusAreaContent={focusArea.focusAreaContent}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <>
                  {focusAreas.map((focusArea) => (
                    <FocusAreaComponent
                      key={focusArea.focusAreaTitle}
                      focusAreaContainerWidth={focusAreaContainerWidthDesktop}
                      focusAreaContainerWidthDesktopXL={focusAreaContainerWidthDesktopXL}
                      focusAreaImage={focusArea.focusAreaImage}
                      focusAreaTitle={focusArea.focusAreaTitle}
                      focusAreaContent={focusArea.focusAreaContent}
                    />
                  ))}
                </>
              )}
            </div>
            <div className={classes.focusAreaContainerTablet}>
              <Swiper
                slidesPerView="auto"
                centeredSlides
                spaceBetween={18}
                grabCursor
                loop
                breakpoints={{
                  0: { slidesPerView: '1.53', spaceBetween: 16 },
                  450: { slidesPerView: '1.5', spaceBetween: 16 },
                  550: { slidesPerView: '1.6', spaceBetween: 16 },
                  600: { slidesPerView: '1.8', spaceBetween: 20 },
                  768: { slidesPerView: '1.7', spaceBetween: 24 },
                  900: { slidesPerView: '1.7', spaceBetween: 24 },
                  1080: { slidesPerView: '3', spaceBetween: 24 },
                  1400: { slidesPerView: '3.2', spaceBetween: 24 },
                }}
              >
                {focusAreas.map((focusArea) => (
                  <SwiperSlide key={focusArea.focusAreaTitle}>
                    <FocusAreaComponent
                      focusAreaContainerWidth={
                        focusAreaContainerWidthTablet
                      }
                      focusAreaImage={focusArea.focusAreaImage}
                      focusAreaTitle={focusArea.focusAreaTitle}
                      focusAreaContent={focusArea.focusAreaContent}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </>
        )}
        {thirdSection && (
          <div className={classes.referenceCasesSection}>
            {referenceCases.map((referenceCase) => (
              <ReferenceCaseComponent
                key={referenceCase.referenceCaseImage}
                path={path}
                referenceCase={referenceCase.case}
                referenceCaseImage={referenceCase.referenceCaseImage}
                referenceCaseTitle={referenceCase.referenceCaseTitle}
                referenceCaseSubitle={referenceCase.referenceCaseSubitle}
                referenceCaseText={referenceCase.referenceCaseText}
              />
            ))}
          </div>
        )}
        {fourthSection && (
          <ContactComponent
            contactImage={contactImage}
            contactTitle={contactTitle}
            contactSubtitle={contactSubtitle}
            contactText={contactText}
            linkedInLink={linkedInLink}
          />
        )}
      </div>
    </div>
  );
};

export default OfferingSection;
