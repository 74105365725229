export const ABOUT_US_TITLE = 'About us';
export const ABOUT_US_PATHS = ['/who-we-are', '/values', '/global-footprint'];
export const ABOUT_US_ITEMS = [
  {
    text: 'Who we are',
    URL: '/who-we-are',
  },
  {
    text: 'Our values',
    URL: '/values',
  },
  {
    text: 'Global Footprint',
    URL: '/global-footprint',
  },
];
export const OFFERING_PATHS = [
  '/navigation',
  '/cockpit',
  '/connectivity',
  '/testing',
];
export const OFFERING_TITLE = 'Offering';
export const OFFERING_ITEMS = [
  {
    text: 'Navigation',
    URL: '/navigation',
  },
  {
    text: 'Cockpit',
    URL: '/cockpit',
  },
  {
    text: 'Connectivity',
    URL: '/connectivity',
  },
  {
    text: 'Testing',
    URL: '/testing',
  },
];

export const CAREERS_TITLE = 'Careers';
export const CAREERS_PATHS = ['/working-with-us', '/open-positions'];
export const CAREERS_ITEMS = [
  {
    text: 'WORKING WITH US',
    URL: '/working-with-us',
  },
  {
    text: 'Open positions',
    URL: '/open-positions',
  },
];
