/* eslint-disable operator-linebreak */
export const NAVIGATION_TITLE = 'Navigation';
export const NAVIGATION_SUBTITLE_FIRST = 'Experience';
export const NAVIGATION_SUBTITLE_SECOND = 'Focus areas';
export const NAVIGATION_SUBTITLE_THIRD = 'Reference cases';
export const NAVIGATION_SUBTITLE_FORTH =
  'Get in contact with one of our experts';

export const NAVIGATION_PARAGRAPH_FIRST =
  'Neusoft has a long history with over 30 years in navigation development and map compilation. In the last three decades we served more than 100 SOPs for markets all over the world and up to 450 databases per year. Our global teams partners with some of the world leading brands to bring modern navigation into the car.';

export const NAVIGATION_FOCUS_FIRST_TITLE = 'Architecture Design';
export const NAVIGATION_FOCUS_SECOND_TITLE = 'Offline Data';
export const NAVIGATION_FOCUS_THIRD_TITLE = 'Cloud Data';
export const NAVIGATION_FOCUS_FOURTH_TITLE = 'Cloud Services';
export const NAVIGATION_FOCUS_FIFTH_TITLE = 'Augmented Reality';
export const NAVIGATION_FOCUS_SIXTH_TITLE = 'Artificial Intelligence';
export const NAVIGATION_FOCUS_SEVENTH_TITLE = 'Testing';

export const NAVIGATION_FOCUS_FIRST_TEXT = [
  {
    sign: '•',
    text: 'Modularity/Scalability',
  },
  {
    sign: '•',
    text: 'Distributed deployment',
  },
  {
    sign: '•',
    text: 'Connected philosophy (Cloud-car connection)',
  },
  {
    sign: '•',
    text: 'Cross-platform implementation',
  },
];
export const NAVIGATION_FOCUS_SECOND_TEXT = [
  {
    sign: '•',
    text: 'Local search',
  },
  {
    sign: '•',
    text: 'Routing/Map viewing/Destination input',
  },
  {
    sign: '•',
    text: 'NDS DB',
  },
  {
    sign: '•',
    text: 'Performance optimization',
  },
  {
    sign: '•',
    text: 'Updates (full/partial/incremental)',
  },
];
export const NAVIGATION_FOCUS_THIRD_TEXT = [
  {
    sign: '•',
    text: 'Worldwide search',
  },
  {
    sign: '•',
    text: 'Data aggregation (e.g. POI, EV Stations)',
  },
  {
    sign: '•',
    text: 'Road events processing',
  },
  {
    sign: '•',
    text: 'Automated driving empowering (integration of several services)',
  },
  {
    sign: '•',
    text: 'Sync manager',
  },
];
export const NAVIGATION_FOCUS_FOURTH_TEXT = [
  {
    sign: '•',
    text: 'Data distribution',
  },
  {
    sign: '•',
    text: 'Main functioning services ( e.g. search, routing, map & SW updates)',
  },
  {
    sign: '•',
    text: 'Own world data base with own NDS data in the cloud (+open street maps)',
  },
  {
    sign: '•',
    text: 'BEV functionality',
  },
  {
    sign: '•',
    text: 'Online-(100%)/Hybrid navigation',
  },
];
export const NAVIGATION_FOCUS_FIFTH_TEXT = [
  {
    sign: '•',
    text: 'Real-time video/W-HUD AR navigation',
  },
  {
    sign: '•',
    text: 'Turn-by-turn guidance',
  },
  {
    sign: '•',
    text: 'ADAS warnings (front car/rear car/lane)',
  },
  {
    sign: '•',
    text: 'Lanes, vehicle, traffic sign recognition/Information',
  },
];
export const NAVIGATION_FOCUS_SIXTH_TEXT = [
  {
    sign: '•',
    text: 'Driving experience optimization',
  },
  {
    sign: '•',
    text: 'Decreasing driver distraction',
  },
  {
    sign: '•',
    text: 'User experience extension/personal user navigation',
  },
  {
    sign: '•',
    text: 'Predicting user needs',
  },
];
export const NAVIGATION_FOCUS_SEVENTH_TEXT = [
  {
    sign: '•',
    text: 'Development tool for map & navigation testing',
  },
  {
    sign: '•',
    text: 'Test automation framework (e.g. code analysis)',
  },
  {
    sign: '•',
    text: 'System testing (field/lab/automated/test drives)',
  },
  {
    sign: '•',
    text: 'Test coordination (from concept to implementation)',
  },
];

export const NAVIGATION_CASE_TITLE_FIRST = 'World Wide Navigation';
export const NAVIGATION_CASE_TITLE_SECOND = 'Augmented Reality';
export const NAVIGATION_CASE_TITLE_THIRD = 'Map Compilation';
export const NAVIGATION_CASE_TITLE_FOURTH = 'Certification for Chinese Market';
export const NAVIGATION_CASE_TITLE_FIFTH = 'Certification for Japanese Market';

export const NAVIGATION_CASE_PATH_FIRST = 'World-Wide-Navigation';
export const NAVIGATION_CASE_PATH_SECOND = 'Augmented-Reality';
export const NAVIGATION_CASE_PATH_THIRD = 'Map-Compilation';
export const NAVIGATION_CASE_PATH_FOURTH = 'Certification-Chinese-Market';
export const NAVIGATION_CASE_PATH_FIFTH = 'Certification-Japanese-Market';

export const REFERENCE_CASES_PATHS = ['World-Wide-Navigation', 'Augmented-Reality',
  'Map-Compilation', 'Certification-Chinese-Market',
  'Certification-Japanese-Market', 'eCockpit-System',
  'Cockpit-System', 'IVI:Android-Framework', 'PoC:Health-Board-platform',
  '5G-T-Box', 'RTM-4G', '4G-T-Box', 'Cloud-Platform', 'EV-Mobile-Application',
  'Road&System-Tests', 'Test-Drives+Data-Recording', 'Road&Bench-Tests',
  'Unit-Module-Tests', 'CAN-simulation&CAN-Bus-analysis', 'KPI-Tests'];

export const NAVIGATION_CASE_SUBTITLE_FIRST = 'Italian OEM';
export const NAVIGATION_CASE_SUBTITLE_SECOND = 'Chinese OEM';
export const NAVIGATION_CASE_SUBTITLE_THIRD = 'German OEM group';
export const NAVIGATION_CASE_SUBTITLE_FOURTH =
  'German premium OEM';
export const NAVIGATION_CASE_SUBTITLE_FIFTH =
  'British OEM';

export const NAVIGATION_CASE_TEXT_FIRST =
  'A single software for three different vehicle models with dedicated hardware configurations, that covers also critical markets like Japan and China.';
export const NAVIGATION_CASE_TEXT_SECOND =
  'AR navigation and AR HUD solution';
export const NAVIGATION_CASE_TEXT_THIRD =
  'Successful delivery of navigation & map maintenance globally';
export const NAVIGATION_CASE_TEXT_FOURTH =
  'Solution for Chinese market, covering map compilation, testing, censoring and certification';
export const NAVIGATION_CASE_TEXT_FIFTH =
  'Implementation of VICS and ETC 2.0 certification for the Japanese navigation One Core';

export const POPUP_CASE_TITLE = 'Solution';
export const POPUP_CASE_FIRST_BOX_SUBTITLE = 'Target Market';
export const POPUP_CASE_SECOND_BOX_SUBTITLE = 'Business Model';
export const POPUP_CASE_THIRD_BOX_SUBTITLE = 'SOP';
export const POPUP_CASE_FOURTH_BOX_SUBTITLE = 'Tools and Technologies';
export const POPUP_CASE_FIFTH_BOX_SUBTITLE = 'Value';

export const NAVIGATION_POPUP_FIRST_CASE_TEXT = '<p>19 Engineers located in Germany, China and Japan built a worldwide navigation based on One Core, covering also critical markets like Japan and China.</p>';
export const NAVIGATION_POPUP_FIRST_CASE_FIRST_BOX_TEXT = 'Global (Japan, China, EU, North America, Russia, Australia, Latin America, Southeast Asia, Turkey, Middle East, India, South Africa)';
export const NAVIGATION_POPUP_FIRST_CASE_SECOND_BOX_TEXT = '';
export const NAVIGATION_POPUP_FIRST_CASE_THIRD_BOX_TEXT = '2015.07 – 2018.06';
export const NAVIGATION_POPUP_FIRST_CASE_FOURTH_BOX_TEXT = 'One Core navigation, Tempo Giusto development and test suite NDS database, NDS test suite, Tempo Giusto; Hardware: Jacinto 6, 2GB RAM Memory: 256MB Flash, 46G SD Card; Three displays: Cluster, Center and Passenger';
export const NAVIGATION_POPUP_FIRST_CASE_FIFTH_BOX_TEXT = 'Single software for three different vehicle models with dedicated hardware configurations';

export const NAVIGATION_POPUP_SECOND_CASE_TEXT = '<p>Neusoft provides their own augmented reality solution for in dash and head up displays. Basic guidance, speed limits, lane recognition and other navigation relevant information.</p>';
export const NAVIGATION_POPUP_SECOND_CASE_FIRST_BOX_TEXT = 'China';
export const NAVIGATION_POPUP_SECOND_CASE_SECOND_BOX_TEXT = 'Business Model';
export const NAVIGATION_POPUP_SECOND_CASE_THIRD_BOX_TEXT = '2022.05';
export const NAVIGATION_POPUP_SECOND_CASE_FOURTH_BOX_TEXT = 'Platform: Android Q <br/> Electronic horizon, ADASIS V2, image recognition, routing, high accurate positioning';
export const NAVIGATION_POPUP_SECOND_CASE_FIFTH_BOX_TEXT = 'Augmented reality solution that works with Neusoft’s own or an external navigation and NDS DB';

export const NAVIGATION_POPUP_THIRD_CASE_TEXT = '<p>Neusoft engineers successfully deliver navigation SW and map compilation services throughout the vehicle life cycle for a German OEM Group for various database versions.</p>';
export const NAVIGATION_POPUP_THIRD_CASE_FIRST_BOX_TEXT = 'Global (Europe, N-America, Japan,  China, Rest of World)';
export const NAVIGATION_POPUP_THIRD_CASE_SECOND_BOX_TEXT = 'Business Model';
export const NAVIGATION_POPUP_THIRD_CASE_THIRD_BOX_TEXT = 'Starting 2002 - on-going';
export const NAVIGATION_POPUP_THIRD_CASE_FOURTH_BOX_TEXT = 'Database compilation:<br/>- NDS database<br/>- proprietary database <br/>NDS test suite, Tempo Giusto, database test';
export const NAVIGATION_POPUP_THIRD_CASE_FIFTH_BOX_TEXT = 'Support of multiple brands and car models inside one OEM Group';

export const NAVIGATION_POPUP_FOURTH_CASE_TEXT = '<p>Multiple engineers in Germany and China built a navigation solution for a German Premium OEM for the Chinese market, covering map compilation, testing, censoring and certification. The German team (5 FTE) steered and supervised the development process as well as the development team in China (20 FTE) and acted as the European communication channel to the customer.</p><span>The engineers solved several challenges, including </span><ul><li>acceptance/validation of the compiled map by Chinese authorities </li><li>access and integration of the transformation shift algorithm</li></ul>';
export const NAVIGATION_POPUP_FOURTH_CASE_FIRST_BOX_TEXT = 'China';
export const NAVIGATION_POPUP_FOURTH_CASE_SECOND_BOX_TEXT = 'Business Model';
export const NAVIGATION_POPUP_FOURTH_CASE_THIRD_BOX_TEXT = '2014 - semiannual map updates until 2023';
export const NAVIGATION_POPUP_FOURTH_CASE_FOURTH_BOX_TEXT = 'Neusoft NDS and proprietary compilation, Tempo Giusto development and test suite';
export const NAVIGATION_POPUP_FOURTH_CASE_FIFTH_BOX_TEXT = 'Navigation development and map update compilation compliant with Chinese regulations';

export const NAVIGATION_POPUP_FIFTH_CASE_TEXT = '<p>Multiple engineers located in Germany, China and Japan worked on the implementation of VICS and ETC 2.0 certification for the Japanese navigation One Core.  The German team steered and supervised the development process as well as the offshore development/implementation team and acted as the European communication channel to the customer.</p><span>The engineers solved several challenges, including </span><ul><li>understand the specification and implement it during the development process</li><li>managing the communication and process with Japanese certification authorities</li><li>pre- and approvals (CD-ROM) to get the certifications for road testing</li><li>performing road tests on an official test track over several days</li><li>pre-analysis and visualization of VICS uplink data before submission for certification</li></ul> ';
export const NAVIGATION_POPUP_FIFTH_CASE_FIRST_BOX_TEXT = 'Japan';
export const NAVIGATION_POPUP_FIFTH_CASE_SECOND_BOX_TEXT = 'Business Model';
export const NAVIGATION_POPUP_FIFTH_CASE_THIRD_BOX_TEXT = '2020.10';
export const NAVIGATION_POPUP_FIFTH_CASE_FOURTH_BOX_TEXT = 'Platform: Qualcomm820, QNX, 8GB RAM <br/>Up to four displays, two different HMIs<br/>Cloud Services using Amazon Web Services, OTA NDS DB update, Neusoft NDS compilation<br/>';
export const NAVIGATION_POPUP_FIFTH_CASE_FIFTH_BOX_TEXT = 'Successful VICS integration and ETC 2.0 certification compliant to Japanese regulations';

export const NAVIGATION_EMPLOYEE_NAME = 'Jochen Weihgold';
export const NAVIGATION_EMPLOYEE_TITLE = 'Global Head of Navigation';
export const NAVIGATION_EMPLOYEE_JOB_DESCRIPTION =
  'More than 15 years of experience in embedded software, project management and navigation in a global environment';
export const NAVIGATION_EMPLOYEE_LINKEDIN_LINK = 'https://www.linkedin.com/in/jochen-weihgold/';
