/* eslint-disable object-curly-newline */
import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { white } from '../../colors';
import expandIcon from '../../assets/images/icons/offerings/expand-icon.svg';

const useStyles = makeStyles(() => ({
  referenceCaseContainer: {
    position: 'relative',
    height: '100%',
    display: 'none',
    flexDirection: 'column',
    boxShadow: '0px 10px 20px #00002ECC',
    '@media(min-device-width:67.5em) and (min-width:67.5em)': {
      display: 'grid',
      gridTemplateRows: '45% 4rem 2.8rem auto',
      gridTemplateColumns: '1',
    },
    '@media(min-width:67.5em) and (max-width:80em)': {
      gridTemplateRows: '36% 3rem 2.4rem auto',
    },
    '@media(min-width:80em) and (max-width:100em)': {
      gridTemplateRows: '40.5% 3rem 2.4rem auto',
    },
  },
  referenceCaseImage: {
    gridRowStart: '1',
    gridRowEnd: '3',
    gridColumnStart: '1',
    width: '100%',
    '@media (max-width:120em)': {
      height: 'auto',
    },
  },
  image: { width: '100%', height: '100%' },
  referenceCaseContentContainer: {
    height: '100%',
    gridRowStart: '4',
    gridRowEnd: '5',
    gridColumnStart: '1',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    '& p': {
      height: '50%',
      textAlign: 'center',
      width: '75%',
      margin: '0 auto',
      marginTop: '2rem',
      marginBottom: '3.5rem',
      '@media(min-width:67.5em) and (max-width:80em)': {
        fontSize: '1.1rem',
        width: '85%',
        marginTop: '1rem',
      },
    },
  },
  referenceCaseContent: {
    marginTop: '0.7rem',
    height: 'auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& h3': {
      textAlign: 'center',
      '@media(min-width:67.5em) and (max-width:80em)': {
        fontSize: '1.4rem',
      },
      '@media(min-width:80em) and (max-width:100em)': {
        fontSize: '1.6rem',
      },
    },
  },
  subtitle: {
    fontWeight: 'normal',
    fontStyle: 'italic',
  },
  expandReferenceCase: {
    marginRight: '1.8rem',
    gridRowStart: '2',
    gridRowEnd: '4',
    gridColumnStart: '1',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  expandReferenceCaseIcon: {
    width: '100%',
    height: '100%',
  },
  referenceCaseButton: {
    width: '90%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1rem auto',
    color: white,
    '& h2': {
      textDecoration: 'underline',
      fontWeight: 'normal',
      fontStyle: 'italic',
      '@media (max-device-width:67.5em)': {
        fontSize: '2.95rem',
      },
      '@media (max-width:47.938em)': {
        fontSize: '1.75rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.75rem',
      },
    },
  },
  referenceCaseBullets: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1rem',
    '& div': {
      height: '0.7rem',
      width: '0.7rem',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      '@media (max-device-width:67.5em)': {
        fontSize: '1.4rem',
        height: '1rem',
        width: '0.7rem',
      },
      '@media (max-width:47.938em)': {
        fontSize: '0.8rem',
        height: '0.7rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '0.8rem',
        height: '0.7rem',
      },
    },
  },
  referenceLink: {
    display: 'none',
    '@media(max-width:67.5em)': {
      display: 'block',
    },
    '@media(max-device-width:67.5em)': {
      display: 'block',
    },
  },
}));

const ReferenceCaseComponent = ({
  desktopLayout,
  path,
  referenceCase,
  referenceCaseImage,
  referenceCaseTitle,
  referenceCaseSubitle,
  referenceCaseText,
  ...props
}) => {
  const classes = useStyles(props);
  const showReferenceCase = () => {
    document.body.style.overflow = 'hidden';
  };

  return (
    <>
      <div className={classes.referenceCaseContainer}>
        <div className={classes.referenceCaseImage}>
          <img className={classes.image} src={referenceCaseImage} alt="" />
        </div>
        <div className={classes.expandReferenceCase}>
          <Link to={`${path}/${referenceCase}`} onClick={showReferenceCase}>
            <img className={classes.expandReferenceCaseIcon} src={expandIcon} alt="" />
          </Link>
        </div>
        <div className={classes.referenceCaseContentContainer}>
          <div className={classes.referenceCaseContent}>
            <h3>{referenceCaseTitle}</h3>
            <h3 className={classes.subtitle}>{referenceCaseSubitle}</h3>
          </div>
          <p>{referenceCaseText}</p>
        </div>
      </div>
      <Link className={classes.referenceLink} to={`${path}/${referenceCase}`} onClick={showReferenceCase}>
        <div className={classes.referenceCaseButton}>
          <h2>{referenceCaseTitle}</h2>
          <div className={classes.referenceCaseBullets}>
            <div>•</div>
            <div>•</div>
            <div>•</div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default ReferenceCaseComponent;
