import euroMoneyIcon from '../../assets/images/icons/careers/working-with-us/icons8-euro-money-100.svg';
import eventAcceptedIcon from '../../assets/images/icons/careers/working-with-us/icons8-event-accepted-100.svg';
import foodBarIcon from '../../assets/images/icons/careers/working-with-us/icons8-food-bar-100.svg';
import groupTaskIcon from '../../assets/images/icons/careers/working-with-us/icons8-group-task-100.svg';
import teamWorkIcon from '../../assets/images/icons/careers/working-with-us/icons8-handshake-heart-100.svg';
import guaranteeIcon from '../../assets/images/icons/careers/working-with-us/icons8-guarantee-100.svg';
import handHoldingHeart from '../../assets/images/icons/careers/working-with-us/icons8-hand-holding-heart-100.svg';
import innovation from '../../assets/images/icons/careers/working-with-us/icons8-innovation-100.svg';
import loveTime from '../../assets/images/icons/careers/working-with-us/icons8-love-time-100.svg';
import trainingIcon from '../../assets/images/icons/careers/working-with-us/icons8-training-100.svg';
import umbrella from '../../assets/images/icons/careers/working-with-us/icons8-umbrella-100.svg';
import videoConference from '../../assets/images/icons/careers/working-with-us/icons8-video-conference-100.svg';

export const WORKING_WITH_US_TITLE = 'Working With Us';
export const WORKING_WITH_US_TEXT = 'Our employees make a great contribution to our success. We value our employees and offer them a highly competitive salary and many attractive social and family-friendly benefits. We have trust-based working time and a hybrid working model. Additionally, we also offer family leave and part-time opportunities.';
export const WORKING_WITH_US_SUBTEXT = 'Are you looking for a new challenge and would like to become part of our constantly growing, great, diverse and dynamic team? Then click on the button and check out the current open positions.';
export const WORKING_WITH_US_FIRST_LABEL = 'What we offer';
export const WORKING_WITH_US_SECOND_LABEL = 'Working with us';
export const WORKING_WITH_US_BUTTON = 'VIEW OPEN POSITIONS';
export const WHAT_WE_OFFER_ITEMS = [{
  text: 'Innovation',
  image: innovation,
},
{
  text: 'Good working atmosphere',
  image: groupTaskIcon,
},
{
  text: 'Team work',
  image: teamWorkIcon,
},
{
  text: 'Company health management',
  image: handHoldingHeart,
},
{
  text: 'Flexible trust-based work',
  image: loveTime,
},
{
  text: 'Hybrid work possible',
  image: videoConference,
},
{
  text: 'Subsidized pension plan',
  image: umbrella,
},
{
  text: 'Certified family friendly company',
  image: guaranteeIcon,
},
{
  text: 'Drinks, fruit & restaurant checks',
  image: foodBarIcon,
},
{
  text: 'Training & opportunities',
  image: trainingIcon,
},
{
  text: 'Competitive salary',
  image: euroMoneyIcon,
},
{
  text: 'Corporate events',
  image: eventAcceptedIcon,
}];
export const OPEN_POSITIONS_SUBTITLE = 'Open Positions';
export const OPEN_POSITIONS_TITLE = 'Open positions';
export const OPEN_POSITIONS_TEXT = 'Are you interested in a job at Neusoft Technology Solutions? Find the right job for you, right here in the job postings. Haven’t found anything suitable? Then send us an initiative application at <span>careers@de.neusoft.com</span> - maybe we will find another suitable position for you or we can add you to our talent pool for future job postings.';
