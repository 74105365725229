import React from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import UseScrollToTop from '../../hooks/UseScrollToTop';
import HeroSection from '../../components/HomeSection/HeroSection';
import OfferingSection from '../../components/Offering/OfferingSection';
import ReferenceCasePopUp from '../../components/Offering/ReferenceCasePopUp';
import RedirectToTop from '../../components/Footer/RedirectToTop';

const useStyles = makeStyles(() => ({
  offeringContainer: {
    overflow: 'hidden',
    minHeight: '100vh',
    width: '100%',
    position: 'relative',
  },
}));

const Offering = ({
  deviceSize,
  path,
  subtitle,
  video,
  content4k,
  content2k,
  HDcontent,
  mobileContent,
  tabletContent,
  items,
  ...props
}) => {
  const classes = useStyles(props);
  const location = useLocation();
  const pathCase = location.pathname.split('/')[2];

  return (
    <div className={classes.offeringContainer}>
      <HeroSection
        deviceSize={deviceSize}
        positionSection="relative"
        subtitle={subtitle}
        video={video}
        content4k={content4k}
        content2k={content2k}
        HDcontent={HDcontent}
        mobileContent={mobileContent}
        tabletContent={tabletContent}
        justifyContent="flex-start"
        margin="2.5rem"
      />
      {pathCase && (
        <ReferenceCasePopUp
          pathCase={pathCase}
        />
      )}
      <>
        {items.map((section) => (
          <OfferingSection
            key={section.sectionTitle}
            path={path}
            sectionTitle={section.sectionTitle}
            firstSection={section.firstSection}
            secondSection={section.secondSection}
            thirdSection={section.thirdSection}
            fourthSection={section.fourthSection}
            backgroundColor={section.backgroundColor}
            backgroundColorTablet={section.backgroundColorTablet}
            height={section.height}
            offeringSectionWidthDesktop={section.offeringSectionWidthDesktop}
            offeringSectionWidthTablet={section.offeringSectionWidthTablet}
            offeringSectionWidthDesktopXL={section.offeringSectionWidthDesktopXL}
            focusAreaContainerWidthDesktopXL={section.focusAreaContainerWidthDesktopXL}
            offeringSectionWidthSecond={section.offeringSectionWidthSecond}
            focusAreaContainerWidthDesktop={
              section.focusAreaContainerWidthDesktop
            }
            focusAreaContainerWidthTablet={
              section.focusAreaContainerWidthTablet
            }
            justifyContentSection={section.justifyContentSection}
            marginBottom={section.marginBottom}
            marginBottomMobile={section.marginBottomMobile}
            gridTemplateRows={section.gridTemplateRows}
            paragraph={section.paragraph}
            focusAreas={section.focusAreas}
            referenceCases={section.referenceCases}
            contactImage={section.contactImage}
            contactTitle={section.contactTitle}
            contactSubtitle={section.contactSubtitle}
            contactText={section.contactText}
            linkedInLink={section.linkedInLink}
          />
        ))}
      </>
      {!pathCase && (<RedirectToTop />)}
      <UseScrollToTop />
    </div>
  );
};

export default Offering;
