import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import axios from 'axios';
import smoothscroll from 'smoothscroll-polyfill';
import useWindowSize from './hooks/useWindowSize';
import useDeviceSize from './hooks/useDeviceSize';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import AboutUs from './pages/About_Us';
import Offering from './pages/Offering';
import Careers from './pages/Careers';
import Solutions from './pages/Solutions';
import Contacts from './pages/Contacts';
import Imprint from './pages/Imprint';
import DataProtection from './pages/DataProtection';
import GDPRDisclaimer from './pages/GDPRDisclaimer';
import PageNotFound from './pages/PageNotFound';
import {
  navigation,
  testing,
  connectivity,
  cockpit,
} from './components/Offering/offeringContent';
import {
  SOLUTIONS_TITLE,
  SOLUTIONS_OVERVIEW,
} from './components/Solutions/solutionsItems';
import {
  WORKING_WITH_US_TITLE, WORKING_WITH_US_TEXT, WORKING_WITH_US_SUBTEXT,
  WORKING_WITH_US_FIRST_LABEL, WORKING_WITH_US_SECOND_LABEL,
  WORKING_WITH_US_BUTTON, WHAT_WE_OFFER_ITEMS,
  OPEN_POSITIONS_SUBTITLE,
  OPEN_POSITIONS_TITLE,
  OPEN_POSITIONS_TEXT,
} from './components/Careers/careersItems';
import aboutUsPages from './components/AboutUs/aboutUsContent';
import { NAVIGATION_TITLE } from './components/Offering/Navigation/navigationItems';
import { TESTING_TITLE } from './components/Offering/Testing/testingItems';
import { CONNECTIVITY_TITLE } from './components/Offering/Connectivity/connectivityItems';
import { COCKPIT_TITLE } from './components/Offering/Cockpit/cockpitItems';
import navigationHD from './assets/images/offerings/navigation/navigation-Header-HD.jpg';
import navigationTablet from './assets/images/offerings/navigation/navigation-Header-Tablet.jpg';
import navigationMobile from './assets/images/offerings/navigation/navigation-Header-Mobile.jpg';
import navigation2k from './assets/images/offerings/navigation/navigation-Header-2k.jpg';
import navigation4k from './assets/images/offerings/navigation/navigation-Header-4k.jpg';
import cockpitHD from './assets/images/offerings/cockpit/cockpit-Header-HD.jpg';
import cockpitTablet from './assets/images/offerings/cockpit/cockpit-Header-Tablet.jpg';
import cockpitMobile from './assets/images/offerings/cockpit/cockpit-Header-Mobile.jpg';
import cockpit2k from './assets/images/offerings/cockpit/cockpit-Header-2k.jpg';
import cockpit4k from './assets/images/offerings/cockpit/cockpit-Header-4k.jpg';
import connectivityHD from './assets/images/offerings/connectivity/connectivity-full-HD.jpg';
import connectivityTablet from './assets/images/offerings/connectivity/connectivity-tablet.jpg';
import connectivityMobile from './assets/images/offerings/connectivity/connectivity-mobile.jpg';
import connectivity2k from './assets/images/offerings/connectivity/connectivity-Header-2k.jpg';
import connectivity4k from './assets/images/offerings/connectivity/connectivity-Header-4k.jpg';
import testingHD from './assets/images/offerings/testing/testing-full-HD.jpg';
import testingTablet from './assets/images/offerings/testing/testing-tablet.jpg';
import testingMobile from './assets/images/offerings/testing/testing-mobile.jpg';
import testing2k from './assets/images/offerings/testing/testing-2k.jpg';
import testing4k from './assets/images/offerings/testing/testing-4k.jpg';
import workingWithUsHD from './assets/images/careers/working-with-us/working-with-us-full-HD.jpg';
import workingWithUsTablet from './assets/images/careers/working-with-us/working-with-us-tablet.jpg';
import workingWithUsMobile from './assets/images/careers/working-with-us/working-with-us-mobile.jpg';
import workingWithUs2k from './assets/images/careers/working-with-us/working-with-us-2k.jpg';
import workingWithUs4k from './assets/images/careers/working-with-us/working-with-us-4k.jpg';
import workingWithUsImage from './assets/images/careers/working-with-us/working-with-us-image.jpg';
import openPositionsHD from './assets/images/careers/open-positions/open-positions-full-HD.jpg';
import openPositionsTablet from './assets/images/careers/open-positions/open-positions-tablet.jpg';
import openPositionsMobile from './assets/images/careers/open-positions/open-positions-mobile.jpg';
import openPositions2k from './assets/images/careers/open-positions/open-positions-2k.jpg';
import openPositions4k from './assets/images/careers/open-positions/open-positions-4k.jpg';
import solutionsHD from './assets/images/solutions/main-page/solutions-HD.jpg';
import solutionsTablet from './assets/images/solutions/main-page/solutions-tablet.jpg';
import solutionsMobile from './assets/images/solutions/main-page/solutions-mobile.jpg';
import solutions2k from './assets/images/solutions/main-page/solutions-2k.jpg';
import solutions4k from './assets/images/solutions/main-page/solutions-4k.jpg';

const useStyles = makeStyles(() => ({
  app: {
    height: '100%',
    width: '100%',
  },
}));

const App = () => {
  const classes = useStyles();
  const windowSize = useWindowSize();
  const deviceSize = useDeviceSize();
  const [headerRef, setHeaderRef] = useState();
  const [positions, setPositions] = useState([]);
  const [positionId, setPositionId] = useState(null);
  smoothscroll.polyfill();
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL}/api/jobs`).then((response) => {
      setPositions(response.data.Items);
    });
  }, []);

  return (
    <div className={classes.app}>
      <Header
        deviceSize={deviceSize}
        setHeaderRef={setHeaderRef}
      />
      <Switch>
        <Route path="/" exact>
          <Home
            deviceSize={deviceSize}
            headerRef={headerRef}
          />
        </Route>
        {aboutUsPages.map((aboutUsPage) => (
          <Route path={aboutUsPage.path} key={aboutUsPage.path} exact>
            <AboutUs
              deviceSize={deviceSize}
              subtitle={aboutUsPage.subtitle}
              video={aboutUsPage.video}
              content4k={aboutUsPage.content4k}
              content2k={aboutUsPage.content2k}
              HDcontent={aboutUsPage.HDContent}
              mobileContent={aboutUsPage.mobileContent}
              tabletContent={aboutUsPage.tabletContent}
              bannerImageHD={aboutUsPage.bannerImageHD}
              bannerImageMobile={aboutUsPage.bannerImageMobile}
              bannerImageTablet={aboutUsPage.bannerImageTablet}
              descriptionTitle={aboutUsPage.descriptionTitle}
              secondDescriptionTitle={aboutUsPage.secondDescriptionTitle}
              secondDescriptionTitleMobile1={
                aboutUsPage.secondDescriptionTitleMobile1
              }
              secondDescriptionTitleMobile2={
                aboutUsPage.secondDescriptionTitleMobile2
              }
              descriptionImage={aboutUsPage.descriptionImage}
              descriptionImageWeb={aboutUsPage.descriptionImageHD}
              descriptionImageMobile={aboutUsPage.descriptionImageMobile}
              descriptionImageTablet={aboutUsPage.descriptionImageTablet}
              secondImage={aboutUsPage.secondImage}
              secondImageWeb={aboutUsPage.secondImageHD}
              secondImageMobile={aboutUsPage.secondImageMobile}
              secondImageTablet={aboutUsPage.secondImageTablet}
              firstBoxAboutSecondDisplay={
                aboutUsPage.firstBoxAboutSecondDisplay
              }
              secondBoxAboutSecondDisplay={
                aboutUsPage.secondBoxAboutSecondDisplay
              }
              titleWidth={aboutUsPage.titleWidth}
              subparagraphMarginTop={aboutUsPage.subparagraphMarginTop}
              paragraph={aboutUsPage.paragraph}
              secondParagraph={aboutUsPage.secondParagraph}
              thirdParagraph={aboutUsPage.thirdParagraph}
              fourthParagraph={aboutUsPage.fourthParagraph}
              newParagraph={aboutUsPage.newParagraph}
              firstListTitle={aboutUsPage.firstListTitle}
              secondListTitle={aboutUsPage.secondListTitle}
              thirdListTitle={aboutUsPage.thirdListTitle}
              minHeightBanner={aboutUsPage.minHeightBanner}
              heightTablet={aboutUsPage.heightTablet}
              heightBannerLarge={aboutUsPage.heightBannerLarge}
              justifyContentFirst={aboutUsPage.justifyContentFirst}
              justifyContentSecond={aboutUsPage.justifyContentSecond}
              marginTopBoxFirst={aboutUsPage.marginTopBoxFirst}
              marginTopBoxSecond={aboutUsPage.marginTopBoxSecond}
              marginTopFirstSection={aboutUsPage.marginTopFirstSection}
              marginBottomFirstSection={aboutUsPage.marginBottomFirstSection}
              marginTopSecondSection={aboutUsPage.marginTopSecondSection}
              marginBottomSecondSection={aboutUsPage.marginBottomSecondSection}
              marginTopFirstSectionTablet={aboutUsPage.marginTopFirstSectionTablet}
              marginBottomFirstSectionTablet={aboutUsPage.marginBottomFirstSectionTablet}
              marginTopSecondSectionTablet={aboutUsPage.marginTopSecondSectionTablet}
              marginBottomSecondSectionTablet={aboutUsPage.marginBottomSecondSectionTablet}
              bannerColor={aboutUsPage.bannerColor}
              marginTop2k={aboutUsPage.marginTop2k}
              marginBottom2k={aboutUsPage.marginBottom2k}
              gridInitial={aboutUsPage.gridInitial}
              gridFirst={aboutUsPage.gridFirst}
              gridSecond={aboutUsPage.gridSecond}
            />
          </Route>
        ))}
        <Route path={['/navigation', '/navigation/:referenceCase']} exact>
          <Offering
            deviceSize={deviceSize}
            path="/navigation"
            subtitle={NAVIGATION_TITLE}
            video=""
            content4k={navigation4k}
            content2k={navigation2k}
            HDcontent={navigationHD}
            mobileContent={navigationMobile}
            tabletContent={navigationTablet}
            items={navigation}
          />
        </Route>
        <Route path={['/cockpit', '/cockpit/:referenceCase']} exact>
          <Offering
            deviceSize={deviceSize}
            path="/cockpit"
            subtitle={COCKPIT_TITLE}
            video=""
            content4k={cockpit4k}
            content2k={cockpit2k}
            HDcontent={cockpitHD}
            mobileContent={cockpitMobile}
            tabletContent={cockpitTablet}
            items={cockpit}
          />
        </Route>
        <Route path={['/connectivity', '/connectivity/:referenceCase']} exact>
          <Offering
            deviceSize={deviceSize}
            path="/connectivity"
            subtitle={CONNECTIVITY_TITLE}
            video=""
            content4k={connectivity4k}
            content2k={connectivity2k}
            HDcontent={connectivityHD}
            mobileContent={connectivityMobile}
            tabletContent={connectivityTablet}
            items={connectivity}
          />
        </Route>
        <Route path={['/testing', '/testing/:referenceCase']} exact>
          <Offering
            deviceSize={deviceSize}
            path="/testing"
            subtitle={TESTING_TITLE}
            video=""
            content4k={testing4k}
            content2k={testing2k}
            HDcontent={testingHD}
            mobileContent={testingMobile}
            tabletContent={testingTablet}
            items={testing}
          />
        </Route>
        <Route path={['/solutions', '/solutions/:solutionName']} exact>
          <Solutions
            deviceSize={deviceSize}
            windowSize={windowSize}
            subtitle={SOLUTIONS_TITLE}
            video=""
            content4k={solutions4k}
            content2k={solutions2k}
            HDcontent={solutionsHD}
            mobileContent={solutionsMobile}
            tabletContent={solutionsTablet}
            items=""
            bannerTitle="Solutions"
            titleSection={WORKING_WITH_US_TITLE}
            label="Overview"
            titleParagraph={SOLUTIONS_OVERVIEW}
            marginTop="4rem"
            marginBottom="2.5rem"
          />
        </Route>
        <Route path="/working-with-us" exact>
          <Careers
            deviceSize={deviceSize}
            windowSize={windowSize}
            subtitle={WORKING_WITH_US_TITLE}
            video=""
            content4k={workingWithUs4k}
            content2k={workingWithUs2k}
            HDcontent={workingWithUsHD}
            mobileContent={workingWithUsMobile}
            tabletContent={workingWithUsTablet}
            items={WHAT_WE_OFFER_ITEMS}
            titleSection={WORKING_WITH_US_TITLE}
            firstParagraphText={WORKING_WITH_US_TEXT}
            secondParagraphText={WORKING_WITH_US_SUBTEXT}
            firstLabel={WORKING_WITH_US_FIRST_LABEL}
            titleParagraph=""
            secondLabel={WORKING_WITH_US_SECOND_LABEL}
            button={WORKING_WITH_US_BUTTON}
            image={workingWithUsImage}
            marginTop="4rem"
            marginBottom="2.5rem"
          />
        </Route>
        <Route path={['/open-positions', '/open-positions/:positionId/*']} exact>
          <Careers
            deviceSize={deviceSize}
            subtitle={OPEN_POSITIONS_SUBTITLE}
            video=""
            content4k={openPositions4k}
            content2k={openPositions2k}
            HDcontent={openPositionsHD}
            mobileContent={openPositionsMobile}
            tabletContent={openPositionsTablet}
            items=""
            titleSection={WORKING_WITH_US_TITLE}
            firstLabel={OPEN_POSITIONS_TITLE}
            titleParagraph={OPEN_POSITIONS_TEXT}
            button=""
            image=""
            marginTop="7rem"
            marginBottom="3rem"
            positions={positions}
            positionId={positionId}
            setPositionId={setPositionId}
          />
        </Route>
        <Route path="/contacts" exact>
          <Contacts
            deviceSize={deviceSize}
            subtitle="Contacts"
            video=""
            contactPage="true"
          />
        </Route>
        <Route path="/imprint" exact>
          <Imprint deviceSize={deviceSize} />
        </Route>
        <Route path="/data-protection" exact>
          <DataProtection deviceSize={deviceSize} />
        </Route>
        <Route path="/gdpr-disc" exact>
          <GDPRDisclaimer
            deviceSize={deviceSize}
          />
        </Route>
        <Route path="/*" exact>
          <PageNotFound />
        </Route>
      </Switch>
      <Footer />
    </div>
  );
};

export default App;
