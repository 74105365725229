/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { neusoftBlue, bannerColor } from '../../colors';
import {
  GLOBAL_FOOTPRINT_LOCATION,
  GLOBAL_FOOTPRINT_HISTORY,
  GLOBAL_FOOTPRINT_TECHNOLOGY,
} from './aboutUsItems';
import ListComponent from './ListComponent';

const useStyles = makeStyles(() => ({
  containerAbout: {
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  aboutUsContent: {
    width: '100%',
    height: 'auto',
    display: 'grid',
    gridTemplateRows: '1',
    gridTemplateColumns: '45% 5% 50%',
    '@media(max-width:81.25em)': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      flexDirection: 'column',
    },
    '& h3': {
      color: neusoftBlue,
      '@media(max-width:47em)': { fontSize: '1.5rem' },
      '@media(min-width:48em) and (max-width:67.5em)': { fontSize: '2.2rem' },
      '@media(min-device-width:48em) and (max-device-width:67.5em)': { fontSize: '2.2rem' },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.5rem',
      },
    },
    '& p': {
      fontSize: '1.625rem',
      '@media only screen and (max-width: 81.25em)': {
        fontSize: '1.5rem',
      },
      '@media(max-width:47.938em)': { fontSize: '1.3rem', marginBottom: '1rem' },
      '@media only screen and (min-device-width : 48em) and (max-device-width : 67.5em)':
      {
        fontSize: '1.8rem',
        marginTop: '1rem',
        marginBottom: '1rem',
      },
      '@media only screen and (max-height : 40em) and (orientation: landscape)':
      {
        fontSize: '1.2rem',
      },
      '@media only screen and (max-device-height : 47em) and (min-device-width :67.5em) and (orientation: landscape)':
      {
        fontSize: '1.1rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.3rem',
        marginBottom: '3rem',
      },

    },
  },
  boxAboutFirst: {
    height: '100%',
    gridColumnStart: '1',
    gridColumnEnd: (props) => props.gridInitial,
    gridRowStart: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media(max-width:81.25em)': {
      width: '100%',
      margin: 'auto',
    },
    '@media (min-width:64em) and (max-width:87.5em) and (min-height:75em)': {
      width: '100%',
      margin: 'auto',
      gridColumnEnd: (props) => props.grid,
    },
  },
  boxAboutFirstContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '74%',
    margin: '0 auto',
    marginTop: (props) => props.marginTop,
    marginBottom: (props) => props.marginBottom,
    '& h3': {
      '@media(max-width:81.25em)': {
        width: '90%',
        margin: '0 auto',
        marginBottom: '2rem',
      },
      '@media(max-width:47em)': {
        marginBottom: '1rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        marginBottom: '1rem',
      },
      '@media (min-width:64em) and (max-width:87.5em) and (min-height:75em)': {
        width: '90%',
        margin: '0 auto',
        marginBottom: '2rem',
      },
    },
    '& img': {
      width: '100%',
    },
    '@media(max-width:81.25em)': {
      height: '100%',
      width: '100%',
      justifyContent: (props) => props.justifyContent,
      marginTop: (props) => props.marginTopTablet,
      marginBottom: (props) => props.marginBottomTablet,
    },
    '@media (min-width:64em) and (max-width:87.5em) and (min-height:75em)': {
      height: '100%',
      width: '100%',
      justifyContent: 'flex-start',
      marginBottom: (props) => props.marginBottomTablet,
      marginTop: (props) => props.marginTopTablet,
    },
    '@media(min-width:150em)': {
      marginTop: (props) => props.marginTop2k,
      marginBottom: (props) => props.marginBottom2k,
    },
  },
  boxAboutFirstContentTitle: { textAlign: 'center' },
  imageDescription: {
    width: '100%',
    display: 'block',
    height: '100%',
    '@media(max-width:81.25em)': {
      height: 'auto',
    },
    '@media (min-width:64em) and (max-width:87.5em) and (min-height:75em)': {
      height: 'auto',
    },
  },
  boxAboutFirstContentContainer: {
    width: '100%',
    marginBottom: '7rem',
    '& img': {
      width: '100%',
      marginTop: '5rem',
      '@media(max-width:47.938em)': {
        marginTop: '2rem',
      },
      '@media(max-device-width:20em)': {
        margin: '0',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        margin: '0',
      },
    },
    '@media(max-width:81.25em)': {
      marginBottom: '2rem',
    },
    '@media(max-device-width:20em)': {
      margin: '0',
    },
  },
  boxAboutSecond: {
    zIndex: '1',
    gridColumnStart: '3',
    gridColumnEnd: '4',
    gridRowStart: '1',
    height: '100%',
    backgroundColor: bannerColor,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media(max-width:81.25em)': {
      display: (props) => props.boxAboutSecondDisplay,
      width: '100%',
      margin: '0',
    },
    '@media (min-width:64em) and (max-width:87.5em) and (min-height:75em)': {
      display: (props) => props.boxAboutSecondDisplay,
      width: '100%',
      margin: '0',
    },
  },
  boxAboutSecondContent: {
    width: '74%',
    margin: '0 auto',
    '@media(max-width:81.25em)': {
      width: '100%',
      marginTop: (props) => props.marginTopTablet,
      marginBottom: (props) => props.marginBottomTablet,
    },
    '@media (min-width:64em) and (max-width:87.5em) and (min-height:75em)': {
      width: '100%',
    },
  },
  imageDescriptionWrapper: {
    width: '100%',
    gridColumnStart: '2',
    gridColumnEnd: '4',
    gridRowStart: '1',
    height: 'auto',
    display: 'none',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '@media(min-width:81.25em) and (max-height:75em)': {
      display: 'flex',
    },
    '@media(min-width:87.5em) and (min-height:75em)': {
      display: 'flex',
    },
  },
  imageDescriptionContainer: {
    right: '0',
    width: '100%',
    height: 'auto',
    zIndex: '1',
    overflow: 'hidden',
    boxShadow: '0px 10px 20px #000000D1',
    '@media(max-width:81.25em)': {
      width: '100%',
      boxShadow: 'none',
    },
    '@media (min-width:64em) and (max-width:87.5em) and (min-height:75em)': {
      width: '100%',
      boxShadow: 'none',
    },
  },
  paragraph: {
    marginTop: '2rem',
    lineHeight: '1.7',
    '@media(max-width:81.25em)': {
      width: '90%',
      margin: '0 auto',
    },
    '@media (min-width:64em) and (max-width:87.5em) and (min-height:75em)': {
      width: '90%',
      margin: '0 auto',
    },
  },
  subparagraph: {
    marginTop: (props) => props.subparagraphMarginTop,
    lineHeight: '1.7',
    '@media(max-width:81.25em)': {
      width: '90%',
      margin: '0 auto',
    },
    '@media (min-width:64em) and (max-width:87.5em) and (min-height:75em)': {
      width: '90%',
      margin: '0 auto',
    },
  },
  listContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    '@media(max-width:81.25em)': {
      width: '90%',
      margin: '0 auto',
    },
    '@media(max-width:47.938em)': {
      marginBottom: '2rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      marginBottom: '2rem',
    },
    '@media (min-width:64em) and (max-width:87.5em) and (min-height:75em)': {
      width: '90%',
      margin: '0 auto',
    },
  },
  boxAboutFirstHolder: {
    marginTop: (props) => props.marginTopBox,
    width: '100%',
  },
  boxSecond: {
    display: 'none',
    '@media(max-width:87.25em) and (min-height:75em)': {
      display: 'flex',
    },
    '@media(max-width:81.25em)': {
      display: 'flex',
    },
  },
  boxFirst: {
    display: 'none',
    '@media(min-width:81.25em) and (max-height:75em)': {
      display: 'flex',
    },
    '@media(min-width:87.5em) and (min-height:75em)': {
      display: 'flex',
    },
  },
}));

const DescriptionComponent = ({
  deviceSize,
  title,
  secondDescriptionTitleMobile1,
  secondDescriptionTitleMobile2,
  sectionId,
  paragraph,
  secondParagraph,
  newParagraph,
  descriptionImageWeb,
  descriptionImageMobile,
  descriptionImageTablet,
  secondImageWeb,
  secondImageMobile,
  secondImageTablet,
  firstListTitle,
  secondListTitle,
  thirdListTitle,
  ...props
}) => {
  const classes = useStyles(props);
  const getDescriptionImageByDeviceSize = () => {
    if (deviceSize.width <= '650') {
      return descriptionImageMobile;
    }
    if (deviceSize.width > '650' && deviceSize.width <= '1080') {
      return descriptionImageTablet;
    }
    return descriptionImageWeb;
  };
  const getSecondImageByDeviceSize = () => {
    if (deviceSize.width <= '650') {
      return secondImageMobile;
    }
    if (deviceSize.width > '650' && deviceSize.width <= '1080') {
      return secondImageTablet;
    }
    return secondImageWeb;
  };

  return (
    <div className={classes.containerAbout} id={sectionId}>
      <div className={classes.wrapper}>
        <div className={classes.firstBoxWrap} />
        <div className={classes.secondBoxWrap} />
      </div>
      <div className={classes.aboutUsContent}>
        <div className={classes.boxAboutFirst}>
          <div className={classes.boxFirst}>
            <div className={classes.boxAboutFirstContent}>
              {title && <h3>{title}</h3>}
              {descriptionImageWeb ? (
                <>
                  <div className={classes.subcontainer}>
                    <p className={classes.paragraph}>{paragraph}</p>
                    {secondParagraph && (
                      <p className={classes.subparagraph}>
                        {secondParagraph}
                      </p>
                    )}
                  </div>
                </>
              ) : (
                <>
                  {firstListTitle ? (
                    <></>
                  ) : (
                    <div className={classes.subcontainer}>
                      <p className={classes.paragraph}>{paragraph}</p>
                    </div>
                  )}
                </>
              )}
              {firstListTitle && (
                <>
                  <div className={classes.listContainer}>
                    <ListComponent
                      listTitle={firstListTitle}
                      list={GLOBAL_FOOTPRINT_LOCATION}
                    />
                    <ListComponent
                      listTitle={secondListTitle}
                      list={GLOBAL_FOOTPRINT_HISTORY}
                    />
                    <ListComponent
                      listTitle={thirdListTitle}
                      list={GLOBAL_FOOTPRINT_TECHNOLOGY}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={classes.boxSecond}>
            <div className={classes.boxAboutFirstContent}>
              <div className={classes.boxAboutFirstHolder}>
                {secondDescriptionTitleMobile1 ? (
                  <>
                    {deviceSize.width <= '650' && (
                      <h3 className={classes.boxAboutFirstContentTitle}>
                        {secondDescriptionTitleMobile1}
                        <br />
                        {secondDescriptionTitleMobile2}
                      </h3>
                    )}
                    {deviceSize.width > '650' && <h3>{title}</h3>}
                  </>
                ) : (
                  <h3>{title}</h3>
                )}
                {descriptionImageWeb ? (
                  <>
                    <div className={classes.boxAboutFirstContentContainer}>
                      {paragraph && (
                        <p className={classes.paragraph}>{paragraph}</p>
                      )}
                      <img
                        className={classes.imageDescription}
                        src={getDescriptionImageByDeviceSize()}
                        alt=""
                      />
                    </div>
                    {secondParagraph && (
                      <p className={classes.subparagraph}>{secondParagraph}</p>
                    )}
                  </>
                ) : (
                  <>
                    {firstListTitle ? (
                      <></>
                    ) : (
                      <div className={classes.subcontainer}>
                        <p className={classes.paragraph}>{paragraph}</p>
                      </div>
                    )}
                  </>
                )}
                {firstListTitle && (
                  <>
                    <div className={classes.listContainer}>
                      <ListComponent
                        listTitle={firstListTitle}
                        list={GLOBAL_FOOTPRINT_LOCATION}
                      />
                      <ListComponent
                        listTitle={secondListTitle}
                        list={GLOBAL_FOOTPRINT_HISTORY}
                      />
                      <ListComponent
                        listTitle={thirdListTitle}
                        list={GLOBAL_FOOTPRINT_TECHNOLOGY}
                      />
                    </div>
                  </>
                )}
              </div>
              {firstListTitle && (
                <img
                  className={classes.imageDescription}
                  src={getSecondImageByDeviceSize()}
                  alt=""
                />
              )}
            </div>
          </div>
        </div>
        {descriptionImageWeb || secondImageWeb ? (
          <>
            <div className={classes.boxAboutSecond} />
            <div className={classes.imageDescriptionWrapper}>
              <div className={classes.imageDescriptionContainer}>
                {descriptionImageWeb ? (
                  <img
                    className={classes.imageDescription}
                    src={getDescriptionImageByDeviceSize()}
                    alt=""
                  />
                ) : (
                  <img
                    className={classes.imageDescription}
                    src={getSecondImageByDeviceSize()}
                    alt=""
                  />
                )}
              </div>
            </div>
          </>
        ) : (
          <div className={classes.boxAboutSecond}>
            <>
              {secondParagraph && (
                <div className={classes.boxAboutSecondContent}>
                  <p className={classes.subparagraph}>
                    {secondParagraph} <br />
                    {newParagraph}
                  </p>
                </div>
              )}
            </>
          </div>
        )}
      </div>
    </div>
  );
};

export default DescriptionComponent;
