import React, {
  useState, useLayoutEffect,
} from 'react';
import { makeStyles } from '@material-ui/core';
import redirectToTopBtn from '../../assets/images/icons/home/redirect-to-top-icon.svg';

const useStyles = makeStyles(() => ({
  redirectToTopBtn: {
    width: 'auto',
    height: 'auto',
    zIndex: '3',
    bottom: '0',
    right: '2%',
    border: 'none',
    background: 'none',
    position: 'absolute',
    '& img': {
      cursor: 'pointer',
      '@media (max-width:85em)': {
        width: '7rem',
        height: '7rem',
      },
      '@media (max-width:30em)': {
        width: '6rem',
        height: '6rem',
      },
    },
  },
  redirectToTopFixedBtn: {
    width: 'auto',
    height: 'auto',
    zIndex: '3',
    bottom: '0',
    right: '2%',
    border: 'none',
    background: 'none',
    position: (props) => (props.isPositionFixed ? 'fixed' : 'absolute'),
    '& img': {
      cursor: 'pointer',
      '@media (max-width:85em)': {
        width: '7rem',
        height: '7rem',
      },
      '@media (max-width:30em)': {
        width: '6rem',
        height: '6rem',
      },
    },
  },
  displayNone: {
    display: 'none',
  },
}));

const RedirectToTop = ({ isGDPRPage }) => {
  const [isPositionFixed, setIsPositionFixed] = useState(false);
  const classes = useStyles({ isPositionFixed });
  const scrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };
  const onScroll = () => {
    const windowHeight = window.innerHeight;
    const scrollValue = window.scrollY;
    const redirectBottomElement = document.getElementById('scroll-to-top-bttn');
    const maxScroll = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const marginBottomValue = windowHeight - redirectBottomElement.getBoundingClientRect().top;
    if ((scrollValue > windowHeight / 3) && (scrollValue < maxScroll - marginBottomValue)) {
      setIsPositionFixed(true);
    } else {
      setIsPositionFixed(false);
    }
  };

  useLayoutEffect(() => {
    if (isGDPRPage) {
      window.addEventListener('scroll', onScroll);
    }
    return () => window.removeEventListener('scroll', onScroll);
  }, []);
  return (
    <>
      <button type="button" id="scroll-to-top-bttn" style={{ visibility: isPositionFixed ? 'hidden' : 'visible' }} onClick={scrollToTop} className={classes.redirectToTopBtn}>
        <img src={redirectToTopBtn} alt="" />
      </button>
      {isGDPRPage && (
      <button type="button" onClick={scrollToTop} className={isPositionFixed ? classes.redirectToTopFixedBtn : classes.displayNone}>
        <img src={redirectToTopBtn} alt="" />
      </button>
      )}
    </>
  );
};

export default RedirectToTop;
