/* eslint-disable operator-linebreak */
export const TESTING_TITLE = 'Testing';
export const TESTING_SUBTITLE_FIRST = 'Experience';
export const TESTING_SUBTITLE_SECOND = 'Focus areas';
export const TESTING_SUBTITLE_THIRD = 'Reference cases';
export const TESTING_SUBTITLE_FORTH = 'Get in contact with one of our experts';

export const TESTING_PARAGRAPH_FIRST =
  'Neusoft has extensive experience in automotive testing through having completed over 130 customer projects and driven more than 5 million test kilometers. Our expertise is available as a service. This way you will benefit from our many years of experience. Our support ranges from consulting and on-site support to responsibility for complete work packages.';

export const TESTING_FOCUS_FIRST_TITLE = 'Navigation';
export const TESTING_FOCUS_SECOND_TITLE = 'Cockpit';
export const TESTING_FOCUS_THIRD_TITLE = 'Connectivity';
export const TESTING_FOCUS_FOURTH_TITLE = 'Methods&Tools';

export const TESTING_FOCUS_FIRST_TEXT = [
  {
    sign: '•',
    text: 'Test coordination with focus on quality assurance (from concept to implementation)',
  },
  {
    sign: '•',
    text: 'Head unit – navigation SW (HMI, business layer, navigation platform, NDS data base)',
  },
  {
    sign: '•',
    text: 'Global test drive (ISTQB certified teams and similar in DE, RO, CN & JP)',
  },
];
export const TESTING_FOCUS_SECOND_TEXT = [
  {
    sign: '•',
    text: 'IVI, HMI & IC (function testing of infotainment system e.g. radio, multimedia, system settings, navigation, ADAS, connectivity, application)',
  },
];
export const TESTING_FOCUS_THIRD_TEXT = [
  {
    sign: '•',
    text: 'Telematics system (head unit, TCU, application, backend system)',
  },
  {
    sign: '•',
    text: 'V2X, cloud, application',
  },
];
export const TESTING_FOCUS_FOURTH_TEXT = [
  {
    sign: '•',
    text: 'Several test methodologies (module/unit, integration, SW- tests etc.)',
  },
  {
    sign: '•',
    text: 'Individual test development',
  },
  {
    sign: '•',
    text: 'Individual test reporting',
  },
  {
    sign: '•',
    text: 'Tools for continuous integration, defect management, test management, source code quality, reporting, test framework, CAN',
  },
];

export const TESTING_CASE_TITLE_FIRST = 'Road & System Tests';
export const TESTING_CASE_TITLE_SECOND = 'Test Drives & Data Recording';
export const TESTING_CASE_TITLE_THIRD = 'System Tests';
export const TESTING_CASE_TITLE_FOURTH = 'Unit/Module Tests';
export const TESTING_CASE_TITLE_FIFTH = 'CAN simulation & CAN-Bus analysis';
export const TESTING_CASE_TITLE_SIXTH = 'KPI Tests';

export const TESTING_CASE_PATH_FIRST = 'Road&System-Tests';
export const TESTING_CASE_PATH_SECOND = 'Test-Drives&Data-Recording';
export const TESTING_CASE_PATH_THIRD = 'System-Tests';
export const TESTING_CASE_PATH_FOURTH = 'Unit-Module-Tests';
export const TESTING_CASE_PATH_FIFTH = 'CAN-simulation&CAN-Bus-analysis';
export const TESTING_CASE_PATH_SIXTH = 'KPI-Tests';

export const TESTING_CASE_SUBTITLE_FIRST = 'Chinese OEM';
export const TESTING_CASE_SUBTITLE_SECOND = 'Danish municipality';
export const TESTING_CASE_SUBTITLE_THIRD = 'German premium OEM';
export const TESTING_CASE_SUBTITLE_FOURTH = 'Multiple German OEM’s';
export const TESTING_CASE_SUBTITLE_FIFTH = 'Multiple German OEM’s';
export const TESTING_CASE_SUBTITLE_SIXTH = 'Italian OEM';

export const TESTING_POPUP_FIRST_CASE_TEXT = '<p>German & Chinese engineers performed road tests, in-vehicle system and bench tests for navigation, IVI (incl. Head Unit flashing) and Radio/Tuner (FM, RDS, DAB) for a Chinese OEM in multiple European countries.</p><p><span>Test drives</span>: 37.200+ kilometers</p>';
export const TESTING_POPUP_FIRST_CASE_FIRST_BOX_TEXT = 'Europe (Germany, Switzerland, Italy, Austria, Spain, France, Portugal, Great Britain, Netherlands, Hungary, Romania, Bulgaria, Denmark, Norway, Sweden, Finland, Belgium)';
export const TESTING_POPUP_FIRST_CASE_SECOND_BOX_TEXT = '';
export const TESTING_POPUP_FIRST_CASE_THIRD_BOX_TEXT = '2022';
export const TESTING_POPUP_FIRST_CASE_FOURTH_BOX_TEXT = 'Testbenches, test cars, automatic test framework, flash tools';
export const TESTING_POPUP_FIRST_CASE_FIFTH_BOX_TEXT = 'Leverage the synergies of Neusoft as Chinese company and prepare the Chinese OEM for their European market entry';

export const TESTING_POPUP_SECOND_CASE_TEXT = '<p>Testers located in Germany collected data during test drives in a municipality in Denmark.</p><ul><li><span>Test drives:</span> 2.000+ kilometers</li><li><span>Test duration:</span> 3 weeks data recordings (100+ hours of video material)</li></ul>';
export const TESTING_POPUP_SECOND_CASE_FIRST_BOX_TEXT = 'Denmark';
export const TESTING_POPUP_SECOND_CASE_SECOND_BOX_TEXT = '';
export const TESTING_POPUP_SECOND_CASE_THIRD_BOX_TEXT = '2021';
export const TESTING_POPUP_SECOND_CASE_FOURTH_BOX_TEXT = 'Mounted Camera, High precision GPS, SQLite Database, Sensorbox';
export const TESTING_POPUP_SECOND_CASE_FIFTH_BOX_TEXT = 'Digitalization of Infrastructure  by creating a data base containing all street signs and their locations to enable Municipality to maintain them';

export const TESTING_POPUP_THIRD_CASE_TEXT = '<p>18 Testers located in China, tested for a German premium OEM a navigation solution for the Chinese market.</p><ul><li><span>Test drives:</span> 50.000+ kilometers / 6+ cities</li></ul>';
export const TESTING_POPUP_THIRD_CASE_FIRST_BOX_TEXT = 'China';
export const TESTING_POPUP_THIRD_CASE_SECOND_BOX_TEXT = '';
export const TESTING_POPUP_THIRD_CASE_THIRD_BOX_TEXT = '2014';
export const TESTING_POPUP_THIRD_CASE_FOURTH_BOX_TEXT = 'German Tier 1 GDF tooling, Data Logger, Map Viewer, Manuel and automatic test tooling (self developed)';
export const TESTING_POPUP_THIRD_CASE_FIFTH_BOX_TEXT = 'Provide expertise to German premium OEM for the Chinese market including local map censoring and certification';

export const TESTING_POPUP_FOURTH_CASE_TEXT = '<p>Engineers located in Germany and Romania wrote unit tests for a database complier used in several projects for German OEMs.</p><span>Test coverage:</span><ul><li>Writing unit tests</li><li>Writing module tests</li><li>Verification and reporting of test results</li><li>Maintenance of unit tests</li></ul>';
export const TESTING_POPUP_FOURTH_CASE_FIRST_BOX_TEXT = 'Global';
export const TESTING_POPUP_FOURTH_CASE_SECOND_BOX_TEXT = '';
export const TESTING_POPUP_FOURTH_CASE_THIRD_BOX_TEXT = '2010 - on-going';
export const TESTING_POPUP_FOURTH_CASE_FOURTH_BOX_TEXT = 'Tempo Giusto (self developed), TestNG, SQLite, PostgreSQL, Databaseinspector  (standard NDS Tool), Java';
export const TESTING_POPUP_FOURTH_CASE_FIFTH_BOX_TEXT = 'Securing the quality of the compiler used for world wide navigation database compilation';

export const TESTING_POPUP_FIFTH_CASE_TEXT = '<p>Engineers located in Germany tested CAN signals to verify correct CAN message handling by navigation for two different German OEM projects.</p><br/><span>Global test coverage:</span><ul><li>CAN simulation on/off ignition</li><li>CAN Simulation of wheel impulse</li><li>Conversion of analog vehicle signals to CAN massages</li><li>CAN-Bus analysis with CANoe</li><li>CAN-Bus analysis with PCAN</li></ul>';
export const TESTING_POPUP_FIFTH_CASE_FIRST_BOX_TEXT = 'Global';
export const TESTING_POPUP_FIFTH_CASE_SECOND_BOX_TEXT = '';
export const TESTING_POPUP_FIFTH_CASE_THIRD_BOX_TEXT = '2008';
export const TESTING_POPUP_FIFTH_CASE_FOURTH_BOX_TEXT = 'CANoe, PCAN, C-Progammed 8051 ATMEL, Controller (self developed), Eagle Cad Soft (PCB Layout design Tool)';
export const TESTING_POPUP_FIFTH_CASE_FIFTH_BOX_TEXT = 'Enable can bus analysing and stimulation in the car and at the test bench';

export const TESTING_POPUP_SIXTH_CASE_TEXT = '<p>20 Testers located in Germany, China and Japan tested a worldwide navigation based on One Core, covering also challenging markets like Japan and China for an Italian OEM. In addition, Neusoft provided key performance index tests to monitor critical resource usage and timing limits.</p><span>Test coverage:</span><ul><li>Memory & CPU usage </li><li>Start-up- / Boot-up time</li><li>Reset statistics (km or time)</li><li>Route time calculation</li><li>Map viewer: frame/sec</li></ul>';
export const TESTING_POPUP_SIXTH_CASE_FIRST_BOX_TEXT = 'Global';
export const TESTING_POPUP_SIXTH_CASE_SECOND_BOX_TEXT = '';
export const TESTING_POPUP_SIXTH_CASE_THIRD_BOX_TEXT = '2016';
export const TESTING_POPUP_SIXTH_CASE_FOURTH_BOX_TEXT = 'OCTA SW Test framework (self developed), Beagle DB Test framework (self developed), Tempo Giusto simulation and inspection (self developed)';
export const TESTING_POPUP_SIXTH_CASE_FIFTH_BOX_TEXT = 'Provide key performance index tests to monitor critical resource usage and timing limits';

export const TESTING_CASE_TEXT_FIRST =
  'Road tests, in-vehicle system and bench tests for navigation, IVI (incl. Head Unit flashing) and Radio/Tuner (FM, RDS, DAB) in multiple European countries';
export const TESTING_CASE_TEXT_SECOND =
  'Data collection during test drives in a municipality in Denmark for .. (TBP)';
export const TESTING_CASE_TEXT_THIRD =
  'Tested of navigation solution for the Chinese market';
export const TESTING_CASE_TEXT_FOURTH =
  'Unit tests for a navigation database complier used in several projects for German OEMs';
export const TESTING_CASE_TEXT_FIFTH =
  'Tests of CAN signals to verify correct CAN message handling by navigation for two different German OEM projects';
export const TESTING_CASE_TEXT_SIXTH =
  'Tests of a worldwide navigation based on One Core, covering also challenging markets like Japan and China.';

export const TESTING_EMPLOYEE_NAME = 'Sören Hüttemann';
export const TESTING_EMPLOYEE_TITLE = 'Head of Quality';
export const TESTING_EMPLOYEE_JOB_DESCRIPTION =
  'More than 15 years of experience in challenging international environments, certified International Business Manager, PMP, Process and Quality Expert, A-SPICE assessor, ISO 9001, ISO 14001';
export const NAVIGATION_EMPLOYEE_LINKEDIN_LINK = 'https://www.linkedin.com/in/soeren-huettemann/';
