import { makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import careersImageHD from '../../assets/images/imprint-data-policy/imprint-data-policy-full-HD.jpg';
import careersImage2k from '../../assets/images/imprint-data-policy/imprint-data-policy-2k.jpg';
import careersImage4k from '../../assets/images/imprint-data-policy/imprint-data-policy-4k.jpg';
import careersImageTablet from '../../assets/images/imprint-data-policy/imprint-data-policy-tablet.jpg';
import careersImageMobile from '../../assets/images/imprint-data-policy/imprint-data-policy-mobile.jpg';
import {
  neusoftBlue,
  textColor,
  white,
  bannerColor,
} from '../../colors';

const useStyles = makeStyles(() => ({
  positionTop: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '35vh 30vh 1fr',
    zIndex: '3',
    width: '100%',
    backgroundColor: bannerColor,
    marginBottom: '0',
    overflow: 'hidden',
    minHeight: '100vh',
    '@media(max-width: 100em)': {
      gridTemplateRows: '40vh 30vh 1fr',
    },
    '@media (max-width: 40.625em)': {
      gridTemplateRows: '35vh 30vh 1fr',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      gridTemplateRows: '48vh 22vh 1fr',
    },
    '@media(max-device-width:48em) and (orientation: landscape)': {
      gridTemplateRows: '50vh 20vh 1fr',
    },
  },
  imageContainer: {
    gridColumnStart: '1',
    gridRowStart: '1',
    gridRowEnd: '3',
    height: '100vh',
    width: '100%',
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      position: 'absolute',
      height: '100%',
      objectFit: 'cover',
      filter: 'blur(10px)',
      transform: 'scale(1.10)',
    },
  },
  imageTopContainer: {
    height: '100%',
    width: '100%',
    background: '#00CCFF 0% 0% no-repeat',
    mixBlendMode: 'multiply',
  },
  titleBox: {
    gridColumnStart: '1',
    gridRowStart: '1',
    gridRowEnd: '2',
    position: 'relative',
    width: '100%',
    height: '62%',
    alignSelf: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& h1': {
      color: white,
      textAlign: 'center',
      '@media(max-device-width:40.625em)': {
        fontSize: '2.5rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '2rem',
      },
    },
    '@media(max-width:67.5em)': {
      width: '70%',
      margin: '0 auto',
    },
    '@media(max-device-width:67.5em) and (orientation: landscape)': {
      width: '70%',
      margin: '0 auto',
      height: '45%',
    },
    '@media(max-device-width:67.5em) and (orientation: portrait)': {
      width: '70%',
      margin: '0 auto',
      height: '60%',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      height: '55%',
    },
  },
  subtitle: { fontWeight: 'normal', marginTop: '2rem' },
  descriptionBox: {
    overflow: 'hidden',
    paddingTop: '4rem',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 10px 20px #00002ECC',
    position: 'relative',
    gridColumnStart: '1',
    gridRowStart: '2',
    gridRowEnd: '4',
    width: '87%',
    height: 'auto',
    left: '6.5%',
    marginBottom: '4rem',
    '@media(max-width:67.5em)': {
      width: '95%',
      left: '2.5%',
    },
    '@media(max-device-width:67.5em)': {
      width: '95%',
      left: '2.5%',
    },
  },
  descriptionContent: {
    position: 'relative',
    width: '85%',
    height: 'auto',
    margin: '0 auto',
    marginBottom: '4.5rem',
    '& h4': {
      color: neusoftBlue,
      marginTop: '4rem',
      marginBottom: '3rem',
      '@media(max-width:47.938em)': {
        fontSize: '1.35rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.35rem',
      },
    },
    listStylePosition: 'outside',
  },
  content: {
    fontFamily: 'EXO, sans-serif',
    '& h1,h2,h3,h4,h5': {
      color: textColor,
      marginTop: '1rem',
      marginBottom: '2rem',
      fontFamily: 'EXO, sans-serif',
      lineHeight: '1.8',
      '@media(min-width:67.5em)': {
        fontSize: '1.625rem',
      },
      '@media(max-width:47.938em)': {
        fontSize: '1.3rem',
      },
      '@media (min-device-width: 48em) and (max-device-width: 67.5em)': {
        fontSize: '1.75rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.3rem',
      },
    },
    '& p': {
      color: textColor,
      marginTop: '1rem',
      marginBottom: '1rem',
      fontFamily: 'EXO, sans-serif',
      lineHeight: '1.8',
      '@media(min-width:67.5em)': {
        fontSize: '1.625rem',
      },
      '@media (min-device-width: 48em) and (max-device-width: 67.5em)': {
        fontSize: '1.75rem',
      },
      '@media(max-device-width:48em)': {
        fontSize: '1.3rem',
      },
      '@media(max-device-width:40.625em)': {
        fontSize: '1.3rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.3rem',
      },
    },
    '& a': {
      display: 'block',
      width: '100%',
      wordBreak: 'break-word',
    },
    '& ul': {
      paddingInlineStart: '0',
      marginLeft: '1.5rem',
      textIndent: '0',
      fontFamily: 'EXO, sans-serif',
      '& li': {
        paddingLeft: '3.5rem',
        margin: '0.5rem 0',
        fontSize: '1.625rem',
        '@media(min-width:67.5em)': {
          fontSize: '1.625rem',
          paddingLeft: '0.5rem',
        },
        '@media (min-device-width: 48em) and (max-device-width: 67.5em)': {
          fontSize: '1.75rem',
        },
        '@media(max-device-width:48em)': {
          fontSize: '1.3rem',
        },
        '@media(max-device-width:40.625em)': {
          fontSize: '1.35rem',
        },
        '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
        {
          fontSize: '1.35rem',
        },
        '@media(max-device-width:67.5em)': {
          paddingLeft: '0.5rem',
        },
        '&::marker': {
          fontSize: '1.3rem',
          '@media(max-device-width:67.5em)': {
            fontSize: '1.3rem',
          },
          '@media(max-device-width:40.625em)': {
            fontSize: '1.35rem',
          },
          '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
          {
            fontSize: '1.35rem',
          },
        },
      },
    },
    '& ol': {
      marginLeft: '1.5rem',
      textIndent: '0',
      fontFamily: 'EXO, sans-serif',
      '& li': {
        paddingLeft: '3.5rem',
        '&::marker': {
          fontSize: '1rem',
          '@media(max-width:67.5em)': {
            fontSize: '1.8rem',
          },
          '@media(max-device-width:67.5em)': {
            fontSize: '1.8rem',
          },
          '@media(max-width:47.938em)': {
            fontSize: '1.35rem',
          },
          '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
          {
            fontSize: '1.35rem',
          },
        },
        '@media(min-width:67.5em)': {
          fontSize: '1.625rem',
          paddingLeft: '0.5rem',
        },
        '@media (min-device-width: 48em) and (max-device-width: 67.5em)': {
          fontSize: '1.75rem',
        },
        '@media(max-device-width:48em)': {
          fontSize: '2.2rem',
        },
        '@media(max-device-width:40.625em)': {
          fontSize: '1.35rem',
        },
        '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
        {
          fontSize: '1.35rem',
        },
        '@media(max-device-width:67.5em)': {
          paddingLeft: '0.5rem',
        },
      },
    },
  },
  blur: {
    filter: 'blur(7px)',
  },
  languagesContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflow: 'scroll',
    width: '100%',
    margin: '0 auto',
    cursor: 'grab',
    position: 'relative',
    marginBottom: '0.7rem',
    padding: '0 4rem',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
  },
  anchorBox: {
    position: 'relative',
    height: 'auto',
    margin: '0 auto',
    width: 'auto',
    flex: 'none',
  },
  anchorButton: {
    backgroundColor: 'transparent',
    height: 'auto',
    width: 'auto',
    border: 'none',
    cursor: 'pointer',
    margin: '0 1.8rem',
    padding: '1rem 0',
    '& h4': {
      margin: '0 auto',
      color: neusoftBlue,
      fontWeight: 'normal',
      '@media(max-width:47.938em)': {
        fontSize: '1.35rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.35rem',
      },
    },
  },
  languagesOuterBox: {
    position: 'relative',
  },
  languagesShadowBox: {
    position: 'absolute',
    height: '100%',
    backgroundColor: white,
  },
  leftShadow: {
    boxShadow: '10px 0px 39px 50px #fff',
    left: '0',
  },
  rightShadow: {
    boxShadow: '-10px 0px 39px 50px #fff',
    right: '0',
  },
  activeLanguage: {
    '& h4': {
      fontWeight: 'bold',
    },
  },
  linkMenuLine: {
    width: '6rem',
    height: '0.188rem',
    margin: '0 auto',
    marginTop: '0.3rem',
    position: 'absolute',
    backgroundColor: neusoftBlue,
    '@media(max-width:67.5em)': {
      minHeight: '0.2rem',
      margin: '0',
      marginTop: '0.4rem',
    },
    '@media(max-device-width:67.5em)': {
      minHeight: '0.2rem',
      margin: '0',
      marginTop: '0.4rem',
    },
  },
  activeMenu: {
    opacity: '1',
    visibility: 'visible',
    transition: 'all 0.5s ease',
    height: 'auto',
    zIndex: '2',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: white,
    width: '100%',
    position: 'relative',
  },
  unactiveMenu: {
    opacity: '0',
    visibility: 'visible',
    transition: 'all 0.5s ease',
    height: 'auto',
    zIndex: '2',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: white,
    width: '100%',
    position: 'relative',
  },
}));

const TextBox = ({
  title,
  subtitle,
  sections,
  deviceSize,
  isGDPRPage,
  ...props
}) => {
  const classes = useStyles(props);
  const getImageByDeviceSize = () => {
    if (deviceSize.width <= '650') {
      return careersImageMobile;
    }
    if (deviceSize.width > '650' && deviceSize.width <= '1080') {
      return careersImageTablet;
    }
    if (deviceSize.width >= '2000') {
      return careersImage2k;
    }
    if (deviceSize.width >= '4000') {
      return careersImage4k;
    }
    return careersImageHD;
  };
  const sliderRef = useRef(null);
  const [activeSection, setActiveSection] = useState(null);
  const [position, setPosition] = useState({
    isScrolling: false,
    left: 0,
    x: 0,
  });

  const mouseMoveHandler = (e) => {
    if (position.isScrolling) {
      const dx = e.clientX - position.x;
      sliderRef.current.scrollLeft = position.left - dx;
    }
  };

  const mouseUpHandler = () => {
    sliderRef.current.style.cursor = 'grab';
    sliderRef.current.style.removeProperty('user-select');
    setPosition({
      ...position,
      isScrolling: false,
    });
  };

  const mouseDownHandler = (e) => {
    sliderRef.current.style.cursor = 'grabbing';
    sliderRef.current.style.userSelect = 'none';
    setPosition({
      ...position,
      isScrolling: true,
      left: sliderRef.current.scrollLeft,
      x: e.clientX,
    });
  };

  const updateSelectedLanguage = (language) => {
    const activeSectionByLanguage = sections.find((section) => section.title === language);
    setActiveSection(activeSectionByLanguage);
  };

  useEffect(() => {
    if (isGDPRPage) {
      setActiveSection(sections[0]);
    }
    return () => {
      setActiveSection(null);
    };
  }, []);
  return (
    <div className={classes.positionTop}>
      <div className={classes.imageContainer}>
        <div className={classes.image}>
          <>
            <img src={getImageByDeviceSize()} alt="" />
          </>
          <div className={classes.imageTopContainer} />
        </div>
      </div>
      <div className={`${classes.titleBox}`}>
        <h1>{title}</h1>
        <h1 className={classes.subtitle}>{subtitle}</h1>
      </div>
      <div className={classes.descriptionBox}>
        {activeSection && (
          <div className={classes.languagesOuterBox}>
            <div
              className={classes.languagesContainer}
              ref={sliderRef}
              onMouseDown={mouseDownHandler}
              onMouseUp={mouseUpHandler}
              onMouseMove={mouseMoveHandler}
              aria-hidden="true"
              role="button"
            >
              {sections.map((section) => (
                <div className={`${classes.anchorBox}`}>
                  <button
                    type="button"
                    className={`${classes.anchorButton} ${activeSection.title === section.title ? classes.activeLanguage : ''}`}
                    onClick={() => updateSelectedLanguage(section.title)}
                  >
                    <h4>{section.title}</h4>
                    <div className={activeSection.title === section.title ? classes.activeMenu
                      : classes.unactiveMenu}
                    >
                      <div className={classes.linkMenuLine} />
                    </div>
                  </button>
                </div>
              ))}
            </div>
            <div className={`${classes.languagesShadowBox} ${classes.rightShadow}`} />
            <div className={`${classes.languagesShadowBox} ${classes.leftShadow}`} />
          </div>
        )}
        {activeSection && (
        <div className={`${classes.descriptionContent}`}>
          <div className={classes.content}>
            {activeSection?.content}
          </div>
        </div>
        )}
        {!activeSection && sections.map((section) => (
          <div className={`${classes.descriptionContent}`}>
            {section?.title ? <h4>{section.title}</h4> : <></>}
            <div className={classes.content}>
              {section?.content}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TextBox;
